import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Breadcrumbs from '../../lib/breadcrumbs';
import { Tabs, Tab, Panel } from 'react-bootstrap';
import DataAnalysisRunList from './data_analysis_run_list';
import DataAnalysisTimerList from './data_analysis_timer_list';
import ResourceDetails from "../../resource_details";

class DataAnalysisDetails extends ResourceDetails {
  static get apiType(){
    return 'data-analyses';
  }

  componentWillMount() {
    if (this.resourceId())
      this.props.loadResources(this.constructor.apiType + '/' + this.resourceId() + '?include=data-platform,data-analysis-root-instruments')
  }

  dataPlatform(){
    if (this.resource() && this.resource().included && this.resource().included['data-platform'])
      return this.resource().included['data-platform'];
    else
      return null;
  }

  render() {
    const Settings = (settings) => {
      return Object.keys(settings).map(k => (
        <div key={k}>
          <span>{k + ': ' + settings[k]}</span>
        </div>
      ));
    };
    return (
      <div className="data-analysis-details-component">
        <Breadcrumbs>
          <Link to="/data-platforms">Data platforms</Link>
          <Link to={"/data-platforms/" + (this.dataPlatform() ? this.dataPlatform().id : '')}>
            {this.dataPlatform() && this.dataPlatform().attributes.name}
          </Link>
          <Link to={"/data-platforms/" + (this.dataPlatform() ? this.dataPlatform().id : '') + "#data-analyses"}>
            Data analyses
          </Link>
          {this.resource() && this.resource().attributes.name}
        </Breadcrumbs>
        <Tabs defaultActiveKey={this.resolveTab('runs')} id="data-analysis-details-tabs" onSelect={this.handleTabSelect} mountOnEnter={true} unmountOnExit={true}>
          <Tab eventKey='runs' title="Runs">
            <DataAnalysisRunList dataPlatformId={this.dataPlatform() && this.dataPlatform().id}
                                 canCreate={this.resource() && this.resource().canCreate('data-analysis-runs')}
                                 dataAnalysisId={this.resourceId()}></DataAnalysisRunList>
          </Tab>
          <Tab eventKey='schedule' title="Schedule">
            <DataAnalysisTimerList dataPlatformId={this.dataPlatform() && this.dataPlatform().id}
                                   canCreate={this.resource() && this.resource().canCreate('data-analysis-timers')}
                                   dataAnalysisId={this.resourceId()}></DataAnalysisTimerList>
          </Tab>
          <Tab eventKey='properties' title="Properties">
            <dl>
              <dt>Type:</dt>
              <dd>{this.resource() && this.resource().attributes['analysis-type']}</dd>
              <dt>Name:</dt>
              <dd>{this.resource() && this.resource().attributes['name']}</dd>
              <dt>Replicate count:</dt>
              <dd>{this.resource() && this.resource().attributes['replicate-count']}</dd>
              <dt>Replicate group period:</dt>
              <dd>{this.resource() && this.resource().attributes['replicate-group-period-sec']} seconds</dd>
              <dt>Estimation interval:</dt>
              <dd>{this.resource() && this.resource().attributes['estimation-interval-days']} days</dd>
              <dt>Control groups:</dt>
              <dd>
                {this.resource() && this.resource().attributes['control-groups'] &&
                    this.resource().attributes['control-groups'].map((group, index) => (
                        <Panel key={`control-group-${index}`}>
                          <Panel.Body>
                            {Settings(group)}
                          </Panel.Body>
                        </Panel>
                    ))}
              </dd>
              <dt>Ranges:</dt>
              <dd>
                {this.resource() && this.resource().attributes['ranges'] &&
                 this.resource().attributes['ranges'].map((ranges, index) => (
                  <Panel key={`range-${index}`}>
                    <Panel.Body>
                      {Settings(ranges)}
                    </Panel.Body>
                  </Panel>
                ))}
              </dd>
              {this.resource() && this.resource().included && this.resource().included['data-analysis-root-instruments'] && <>
                <dt>Control instruments:</dt>
                <dd>
                  {this.resource().included['data-analysis-root-instruments'].map(instrument => (
                    <ul key={`instrument-${instrument.id}`}>
                      <li>{instrument.attributes.name}</li>
                      <ul>
                        {instrument.attributes['children-names'].map(name => (
                          <li key={`instrument-${instrument.id}-child-${name}`}>{name}</li>
                        ))}
                      </ul>
                    </ul>
                  ))}
                </dd>
              </>}
            </dl>
          </Tab>
        </Tabs>
      </div>
    );
  }
}

DataAnalysisDetails.propTypes = {
  resources: PropTypes.object
};

export default connect(DataAnalysisDetails.mapStateToProps, DataAnalysisDetails.mapDispatchToProps)(DataAnalysisDetails);
