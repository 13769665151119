import {Switch, Route, Redirect, withRouter} from 'react-router-dom'
import Platforms from "./modules/platforms/platforms";
import LoginForm from "./login_form";
import Admin from "./modules/admin/admin";
import Data from "./modules/data/data";
import Public from "./modules/public/public";
import UserAccount from "./user_account";

import React, {Component} from "react";

class Main extends Component {
  render() {
    return (
      <main className='main-component'>
        <Switch>
          <Route path="/account/:id" component={UserAccount}/>
          <Route path="/public" component={Public}/>
          <Route path="/login" component={LoginForm}/>
          <Route path="/admin" component={Admin}/>
          <Route path="/data-platforms" component={Data}/>
          <Route path="/platforms" component={Platforms}/>
        </Switch>
        {this.props.location && this.props.location.pathname === '/' &&
        <Redirect to="/public"/>}
      </main>
    );
  }
}

export default withRouter(Main);