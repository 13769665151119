import {
  LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE, LOGOUT_SUCCESS, UNAUTHORIZED, WEBSOCKET_ERROR, DISMISS_FLASH
} from './actions'

import {
  FLASH_TYPE_ALERT //, FLASH_TYPE_NOTICE
} from './flash'

const fetchCurrentUser = () => {
  try {
    return JSON.parse(localStorage.getItem('currentUser'));
  } catch (e) {
    return localStorage.getItem('currentUser');
  }
};

// The auth reducer. The starting state sets authentication
// based on a token being in local storage. In a real app,
// we would also want a util to check if the token is expired.
export function auth(state = {
                isFetching: false,
                isAuthenticated: localStorage.getItem('jwt') ? true : false,
                currentUser: fetchCurrentUser()
              }, action) {
  switch (action.type) {
    case 'API_READ_FAILED': // This is redux-json-api action that gets called if get request goes south
      if (action.payload && action.payload.response && action.payload.response.status === 401){
        action.asyncDispatch({ type: UNAUTHORIZED });
      }
      return state;
    case LOGIN_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isAuthenticated: false,
      });
    case LOGIN_SUCCESS:
      localStorage.setItem('jwt', action.jwt);
      localStorage.setItem('currentUser', typeof action.currentUser === 'string' ? action.currentUser : JSON.stringify(action.currentUser));
      return Object.assign({}, state, {
        isFetching: false,
        isAuthenticated: true,
        currentUser: typeof action.currentUser === 'string' ? JSON.parse(action.currentUser) : action.currentUser
      });
    case LOGIN_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        isAuthenticated: false,
      });
    case LOGOUT_SUCCESS:
      localStorage.removeItem('jwt');
      localStorage.removeItem('currentUser');
      localStorage.removeItem('returnUrl');
      return Object.assign({}, state, {
        isFetching: false,
        isAuthenticated: false
      });
    case UNAUTHORIZED:
      console.log("Unauthorized");
      localStorage.removeItem('jwt');
      localStorage.removeItem('currentUser');
      localStorage.setItem('returnUrl', window.location.href);
      window.location.href = '/login';
      return Object.assign({}, state, {
        isFetching: false,
        isAuthenticated: false
      });
    default:
      return state;
  }
}

export function flash(state = {flashType: null, flashMessage: null}, action){
  switch (action.type) {
    case LOGIN_FAILURE:
        return Object.assign({}, state, {
          flashType: FLASH_TYPE_ALERT,
          flashMessage: action.message
        });
    case LOGIN_SUCCESS:
      return Object.assign({}, state, {
        flashType: null,
        flashMessage: null
      });
    case UNAUTHORIZED:
      return Object.assign({}, state, {
        flashType: FLASH_TYPE_ALERT,
        flashMessage: 'Unauthorized'
      });
    case WEBSOCKET_ERROR:
      return Object.assign({}, state, {
        flashType: FLASH_TYPE_ALERT,
        flashMessage: action.error
      });
    case DISMISS_FLASH:
      return Object.assign({}, state, {
        flashType: null,
        flashMessage: null
      });
    default:
      return state;
  }
}
