import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, ControlLabel, Panel, Button, Checkbox } from 'react-bootstrap';
import DateTime from 'react-datetime';
import _ from 'lodash';
import moment from 'moment';
import Resource from "../../lib/resource";
import { connect } from "react-redux";
import { readEndpoint } from "redux-json-api";

class DataFilter extends React.Component {

  constructor(props) {
    super(props);
    this.state = {showFilter: Object.keys(this.props.filterParams).length > 0};
    this.state.filterParams = _.cloneDeep(this.props.filterParams);
    this.state.instrumentIds = [];
    this.state.groupIds = [];
  }

  componentWillMount(){
    this.props.loadResources('data-platforms/' + this.props.dataPlatformId + '?include=data-phases');
    this.props.loadResources(`data-instruments?filter[for-data-filter]=${this.props.dataPlatformId}&page[size]=500`).then((response) => {
      const body = response && response.body;
      if (body) {
        this.setState({instrumentIds: body.data.map(entity => entity.id)});
      }
    })
    this.props.loadResources(`data-groups?filter[for-data-filter]=${this.props.dataPlatformId}&page[size]=500`).then((response) => {
      const body = response && response.body;
      if (body) {
        this.setState({groupIds: body.data.map(entity => entity.id)});
      }
    })
  }

  dataPlatform(){
    if (this.props.dataPlatformId && this.props.resources && this.props.resources['data-platforms'])
      return(this.props.resources['data-platforms'].find((res) => { return res.id === this.props.dataPlatformId }));
    else
      return null;
  }

  dataInstruments(){
    if (this.props.resources && this.props.resources['data-instruments']) {
      return this.props.resources['data-instruments'].filter(i => (
        this.state.instrumentIds.includes(i.id)
      ));
    } else
      return [];
  }

  dataGroups(){
    if (this.props.resources && this.props.resources['data-groups']) {
      return this.props.resources['data-groups'].filter(g => (
        this.state.groupIds.includes(g.id) && g.attributes['category'] === 'instrument_group'
      ));
    } else
      return [];
  }

  dataSequences(){
    if (this.dataPlatform() && this.dataPlatform().included && this.dataPlatform().included['data-phases'])
      return this.dataPlatform().included['data-phases'].map(r => r.attributes['sequence-name']).filter((el, i, a) => i === a.indexOf(el));
    else
      return [];
  }

  dataSteps(){
    if (this.dataPlatform() && this.dataPlatform().included && this.dataPlatform().included['data-phases'])
      return this.dataPlatform().included['data-phases'].map(r => r.attributes['step-name']).filter((el, i, a) => i === a.indexOf(el));
    else
      return [];
  }

  handleTimeFilter(key, momentTime){
    let filter = _.cloneDeep(this.state.filterParams);
    if (typeof momentTime === "object")
      filter[key] = momentTime.format();
    else
      delete(filter[key]);
    this.setState({filterParams: filter});
  }

  handleSampledAfterChange(momentTime) {
    this.handleTimeFilter('sampled-after', momentTime);
  }

  handleSampledBeforeChange(momentTime) {
    this.handleTimeFilter('sampled-before', momentTime);
  }

  resolveDefaultFilterValue(key, value = null){
    if (this.state.filterParams && this.state.filterParams[key]){
      if (key === 'data-instrument.id' ||
        key === 'data-group.id' ||
        key === 'data-phase.step-name' ||
        key === 'data-phase.sequence-name')
        return this.state.filterParams[key].indexOf(value) !== -1;
      else if (key === 'sampled-after' || key === 'sampled-before') {
        return moment(this.state.filterParams[key]).format('MM/DD/YYYY HH:mm');
      } else
        return this.state.filterParams[key];
    } else
      return '';
  }

  handleInstrumentChange(ev){
    this.handleCheckboxChange(ev, 'data-instrument.id');
  }

  handleGroupChange(ev){
    this.handleCheckboxChange(ev, 'data-group.id');
  }

  handleStepChange(ev){
    this.handleCheckboxChange(ev, 'data-phase.step-name');
  }

  handleSequenceChange(ev){
    this.handleCheckboxChange(ev, 'data-phase.sequence-name');
  }

  handleCheckboxChange(ev, key){
    const id = ev.target.value;
    let filter = _.cloneDeep(this.state.filterParams);
    if (!filter[key])
      filter[key] = [];
    if (ev.target.checked)
      filter[key].push(id);
    else
      filter[key] = filter[key].filter(did => {
        if (isNaN(id))
          return did !== id;
        else
          return parseInt(did, 10) !== parseInt(id, 10);
      });
    if (filter[key].length === 0)
      delete(filter[key]);
    this.setState({filterParams: filter});
  }

  applyChanges(){
    this.props.onChange(this.state.filterParams);
  }

  render() {
    return (
      <div className="data-filter-component">
        <Button className="data-filter-toggle" onClick={() => this.setState({ showFilter: !this.state.showFilter })}>
          {!this.state.showFilter &&
          <span><i className="glyphicon glyphicon-filter"></i> Filter</span>}
          {this.state.showFilter &&
          <span><i className="glyphicon glyphicon-remove"></i> Hide filter</span>}
        </Button>
        <Panel onToggle={()=>(null)} expanded={this.state.showFilter} className={this.state.showFilter ? "" : "non-expanded"}>
          <Panel.Collapse>
            <Panel.Body>
              <FormGroup controlId="sampled-range">
                <ControlLabel>Sampled after</ControlLabel>
                <DateTime name="sampled-after" onChange={this.handleSampledAfterChange.bind(this)} defaultValue={this.resolveDefaultFilterValue("sampled-after")} timeFormat="HH:mm"/>
                <ControlLabel>Sampled before</ControlLabel>
                <DateTime name="sampled-before" onChange={this.handleSampledBeforeChange.bind(this)} defaultValue={this.resolveDefaultFilterValue("sampled-before")} timeFormat="HH:mm"/>
              </FormGroup>
              <FormGroup controlId="data-instrument.id">
                <ControlLabel>Instruments</ControlLabel>
                {this.dataInstruments().map((instrument) => {
                  return (<Checkbox checked={this.resolveDefaultFilterValue('data-instrument.id', instrument.id)} onChange={this.handleInstrumentChange.bind(this)} key={instrument.id} value={instrument.id}>
                    {instrument.attributes['name']}&nbsp;
                    ({instrument.attributes['instrument-type']}, {instrument.attributes['unit']})
                  </Checkbox>);
                })}
              </FormGroup>
              {this.dataGroups().length !== 0 &&
              <FormGroup controlId="data-group.id">
                <ControlLabel>Instrument groups</ControlLabel>
                {this.dataGroups().length > 0 && this.dataGroups().map((group) => {
                  return (<Checkbox checked={this.resolveDefaultFilterValue('data-group.id', group.id)} onChange={this.handleGroupChange.bind(this)} key={group.id} value={group.id}>
                    {group.attributes['name']}
                  </Checkbox>);
                })}
              </FormGroup>}
              <FormGroup controlId="data-phase.sequence-name">
                <ControlLabel>Sequences</ControlLabel>
                {this.dataSequences().map((seq) => {
                  return (<Checkbox checked={this.resolveDefaultFilterValue('data-phase.sequence-name', seq)} onChange={this.handleSequenceChange.bind(this)} key={seq} value={seq}>
                    {seq}
                  </Checkbox>);
                })}
              </FormGroup>
              <FormGroup controlId="data-phase.step-name">
                <ControlLabel>Steps</ControlLabel>
                {this.dataSteps().map((step) => {
                  return (<Checkbox checked={this.resolveDefaultFilterValue('data-phase.step-name', step)} onChange={this.handleStepChange.bind(this)} key={step} value={step}>
                    {step}
                    </Checkbox>);
                })}
              </FormGroup>
              <div>
                <Button bsStyle="primary" onClick={this.applyChanges.bind(this)}>Apply filter</Button>
              </div>
            </Panel.Body>
          </Panel.Collapse>
        </Panel>
      </div>
    );
  }
}

DataFilter.propTypes = {
  resources: PropTypes.object,
  filterParams: PropTypes.object,
  recordCount: PropTypes.number,
  onChange: PropTypes.func,
  dataPlatformId: PropTypes.string,
  excludeParameters: PropTypes.array
};

DataFilter.defaultProps = {
  excludeParameters: []
};

const mapStateToProps = (state) => {
  return Resource.mapStateToResources(state, 'data-platforms', 'data-instruments', 'data-groups');
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadResources: (url) => {
      return dispatch(readEndpoint(url))
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(DataFilter);
