import React from 'react';
import { Table, Checkbox, Button } from 'react-bootstrap';
import ResourceList from "../../resource_list";
import ResourceListPagination from '../../resource_list_pagination';
import FileSaver from 'file-saver';
import ajaxLoader from '../../assets/images/ajax-loader.gif';
import _ from 'lodash';
import Config from '../../config';
import { Link } from 'react-router-dom';
import DataFilter from "./data_filter";
import Confirm from 'react-confirm-bootstrap';
import Time from '../../lib/time';

const apiType = 'data';

class DataList extends ResourceList {

  static get apiType(){
    return apiType;
  }

  defaultQueryParams() {
    return {
      include: [
        'data-uncertainty.data-analysis-run.data-analysis'
      ],
      filter: {
        'data-platform.id': this.props.dataPlatformId
      },
      sort: ['-sampling_time']
    }
  }

  constructor(props) {
    super(props);
    this.state.loadingExport = false;
    this.state.selectedData = [];
    this.state.selectAllData = false;
  }

  loadCSV(){
    let config = {
      method: 'GET',
      headers: { 'Accept': 'text/csv', 'Content-Type': 'text/csv', 'Authorization': 'Bearer ' + localStorage.getItem('jwt')}
    };
    let url = Config.apiUri + '/';

    let queryParams = _.cloneDeep(this.state.queryParams);
    _.merge(queryParams, this.defaultQueryParams());
    url += this.buildQuery('data_export', queryParams);

    this.setState({loadingExport: true});

    fetch(url, config).then((response) => {
      return response.blob();
    }).then((blob) => {
      let filename = this.props.dataPlatformName + ' ' + (new Date().toISOString().slice(0, 19).replace('T', ' '));
      filename = filename.replace(/[^a-z0-9]/gi, '_').toLowerCase();
      console.log("Saving CSV as " + filename);
      FileSaver.saveAs(blob, filename + '.csv');
      this.setState({loadingExport: false});
    })
  }

  dataAnalysisRun(datum){
    if (!datum || !datum.included || !datum.included['data-uncertainty'])
      return null;
    if (!datum.included['data-uncertainty'].included || !datum.included['data-uncertainty'].included['data-analysis-run'])
      return null;
    return datum.included['data-uncertainty'].included['data-analysis-run'];
  }

  onFilterChange(params){
    this.loadResources({filter: params, currentPage: 1});
  }

  controlGroupExists(){
    return _.some(this.resources(), res => res.attributes['control-group'] !== null);
  }

  muExists(){
    return _.some(this.resources(), res => res.attributes['data-uncertainty-value'] !== null);
  }

  instrumentGroupExists(){
    return _.some(this.resources(), res => res.attributes['instrument-group'] !== null);
  }

  selectAllData(){
    if (!this.resources())
      return;
    const ss = !this.state.selectAllData;
    const selectedData = _.cloneDeep(this.state.selectedData);
    this.resources().forEach(res => {
      const id = res.id;
      const index = selectedData.indexOf(id);
      if (index !== -1 && !ss)
        selectedData.splice(index, 1);
      else if (index === -1 && ss)
        selectedData.push(id);
    });
    this.setState({selectedData: selectedData, selectAllData: ss});
  }

  selectData(event) {
    const selectedData = _.cloneDeep(this.state.selectedData);
    const id = event.target.dataset.id;
    const index = selectedData.indexOf(id);
    if (index !== -1)
      selectedData.splice(index, 1);
    else
      selectedData.push(id);
    this.setState({selectedData});
  }

  deleteSelectedData(){
    let promises = this.state.selectedData.map(d => this.handleResourceDelete(d));
    Promise.all(promises).then(() => {
      this.setState({selectedData: [], selectAllData: false});
      if (this.pageCount() > 1)
        this.loadResources(this.urlQueryParams());
    });
  }

  deleteIsPossible(){
    if (this.state.selectedData.length === 0)
      return false;
    return !this.state.selectedData.some(id => {
      const res = this.resources().find(r => r.id === id);
      return !res || !res.canDestroy();
    });
  }

  render() {
    return (
      <div className="data-list-component">
        <strong className="pull-left">Total size: {this.state.recordCount || 0} rows</strong>
        <DataFilter filterParams={this.state.queryParams.filter} onChange={this.onFilterChange.bind(this)} dataPlatformId={this.props.dataPlatformId}/>
        <button disabled={this.state.loadingExport} onClick={this.loadCSV.bind(this)} className="btn btn-primary">
          Download as CSV
        </button>
        {this.state.loadingExport &&
        <img src={ajaxLoader} alt="Loading..."/>}
        <Table responsive hover>
          <thead>
          <tr>
            <th title="Time">Time <Time.Zone/></th>
            <th title="Sequence">Sequence</th>
            <th title="Step">Step</th>
            <th title="Instrument name">Inst name</th>
            <th title="Instrument type">Inst type</th>
            <th title="Parameter">Param</th>
            <th title="Value">Value</th>
            <th title="Unit">Unit</th>
            {this.muExists() && <>
            <th title="Measurement uncertainty value">MU value</th>
            <th title="Measurement uncertainty unit">MU unit</th>
            </>}
            {this.controlGroupExists() &&
            <th title="Control group">Ctrl grp</th>}
            {this.instrumentGroupExists() &&
            <th title="Instrument group">Inst grp</th>}
            <th><Checkbox onChange={this.selectAllData.bind(this)} checked={this.state.selectAllData}/></th>
          </tr>
          </thead>
          <tbody>
          {this.resources().map(datum => (
            <tr key={datum.id} data-id={datum.id}>
              <td><Time resolution="milliseconds">{datum.attributes['sampling-time']}</Time></td>
              <td>{datum.attributes['sequence-name']}</td>
              <td>{datum.attributes['step-name']}</td>
              <td>{datum.attributes['instrument-name']}</td>
              <td>{datum.attributes['instrument-type']}</td>
              <td>{datum.attributes['parameter']}</td>
              <td>{datum.attributes['value']}</td>
              <td>{datum.attributes['unit']}</td>
              {this.muExists() &&
              <td>
                {datum.attributes['data-uncertainty-value'] && this.dataAnalysisRun(datum) &&
                <Link to={`/data-platforms/${this.props.dataPlatformId}/data-analyses/${this.dataAnalysisRun(datum).included['data-analysis'].id}/data-analysis-runs/${this.dataAnalysisRun(datum).id}`}>
                  {datum.attributes['data-uncertainty-value']}
                </Link>}
                {datum.attributes['data-uncertainty-value'] && !this.dataAnalysisRun(datum) &&
                datum.attributes['data-uncertainty-value']}
              </td>}
              {this.muExists() &&
              <td>{datum.attributes['data-uncertainty-unit'] === 'percentage' ? '%' : datum.attributes['data-uncertainty-unit']}</td>}
              {this.controlGroupExists() &&
              <td>{datum.attributes['control-group']}</td>}
              {this.instrumentGroupExists() &&
              <td>{datum.attributes['instrument-group']}</td>}
              <td><Checkbox data-id={datum.id} onChange={this.selectData.bind(this)} checked={this.state.selectedData.indexOf(datum.id) !== -1}/></td>
            </tr>
          ))}
          </tbody>
        </Table>
        <Confirm
          onConfirm={this.deleteSelectedData.bind(this)}
          body={`Are you sure you want to delete ${this.state.selectedData.length} data rows?`}
          confirmText="Confirm delete"
          title="Delete data">
          <Button className="pull-right" bsStyle="danger" disabled={! this.deleteIsPossible()}>
            <i className="glyphicon glyphicon-trash"/>
            &nbsp;
            Delete selected
          </Button>
        </Confirm>
        <ResourceListPagination resourceList={this}/>
      </div>
    );
  }
}

export default ResourceList.connect()(DataList);
