import React from 'react';
import PropTypes from 'prop-types';
import { Table, Button, Panel, Row, Col, FormGroup, ControlLabel, Checkbox } from 'react-bootstrap';
import ResourceList from '../../resource_list';
import _ from 'lodash';
import ResourceListPagination from "../../resource_list_pagination";
import Time from '../../lib/time';

const apiType = 'instrument-alarm-logs';

class InstrumentAlarmLogList extends ResourceList {

  static get apiType(){
    return apiType;
  }

  constructor(props) {
    super(props);
    this.state.showFilter = false;
    if (this.urlQueryParams().filter)
      this.state.showFilter = true;
  }

  defaultQueryParams() {
    return {
      filter: {
        'platform.id': this.props.platformId,
      },
      sort: ['-alarmed-at']
    }
  }

  handleInstrumentChange(ev){
    const name = ev.target.value;
    let filter = _.cloneDeep(this.state.queryParams.filter);
    if (!filter['instrument-name'])
      filter['instrument-name'] = [];
    if (ev.target.checked)
      filter['instrument-name'].push(name);
    else
      filter['instrument-name'] = filter['instrument-name'].filter(n => n !== name);
    if (filter['instrument-name'].length === 0)
      delete(filter['instrument-name']);
    this.loadResources({filter: filter, currentPage: 1});
  }

  filterInstrumentNames(){
    return this.props.instrumentNames ? this.props.instrumentNames : [];
  }

  resolveDefaultFilterValue(key, value = null){
    if (this.state && this.state.queryParams && this.state.queryParams.filter && this.state.queryParams.filter[key]){
      if (key === 'instrument-name')
        return this.state.queryParams.filter[key].indexOf(value) !== -1;
      else
        return this.state.queryParams.filter[key];
    } else
      return '';
  }

  render() {
    const filters = () => {
      return (
        <div className="filters">
          <strong>Total size: {this.state.recordCount || 0} rows</strong>
          &nbsp;
          <Button className="data-filter-toggle" onClick={() => this.setState({ showFilter: !this.state.showFilter })}>
            {!this.state.showFilter &&
            <span><i className="glyphicon glyphicon-filter"></i> Filter</span>}
            {this.state.showFilter &&
            <span><i className="glyphicon glyphicon-remove"></i> Hide filter</span>}
          </Button>
          <Panel onToggle={()=>(null)} expanded={this.state.showFilter} className={this.state.showFilter ? "" : "non-expanded"}>
            <Panel.Collapse>
              <Panel.Body>
                <Row>
                  <Col md={3}>
                    <FormGroup controlId="instrument-name">
                      <ControlLabel>Instruments</ControlLabel>
                      {this.filterInstrumentNames().map((instrument) => {
                        return <Checkbox checked={this.resolveDefaultFilterValue('instrument-name', instrument)} onChange={this.handleInstrumentChange.bind(this)} key={instrument} value={instrument}>{instrument}</Checkbox>
                      })}
                    </FormGroup>
                  </Col>
                  <Col md={3}></Col>
                  <Col md={3}></Col>
                  <Col md={3}></Col>
                </Row>
              </Panel.Body>
            </Panel.Collapse>
          </Panel>
        </div>
      );
    };
    return (
      <div className="instrument-alarm-log-list-component">
        {filters()}
        <Table responsive>
          <thead>
          <tr>
            <th>Type</th>
            <th>Alarmed at <Time.Zone/></th>
            <th>Instrument</th>
            <th>Data age</th>
            <th>Value</th>
            <th>Valid range</th>
            <th>Threshold</th>
          </tr>
          </thead>
          <tbody>
          {this.resources().map(resource => (
            <tr key={resource.id}>
              <td>{resource.attributes['alarm-type']}</td>
              <td><Time resolution="minutes">{resource.attributes['alarmed-at']}</Time></td>
              <td>{resource.attributes['instrument-name']}</td>
              <td><Time.Duration>{resource.attributes['instrument-data-age-ms']}</Time.Duration></td>
              <td>{resource.attributes['instrument-value']}</td>
              <td>{resource.attributes['value-range']}</td>
              <td>{resource.attributes['alarm-threshold-time-ms'] ? (resource.attributes['alarm-threshold-time-ms'] / 60000) + ' mins' : ''}</td>
            </tr>
          ))}
          </tbody>
        </Table>
        <ResourceListPagination resourceList={this}/>
      </div>
    );
  }
}

InstrumentAlarmLogList.propTypes = {
  platformId: PropTypes.string,
  instrumentNames: PropTypes.array
};

export default ResourceList.connect()(InstrumentAlarmLogList);