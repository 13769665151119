import Config from "../config";
import WebSocketWrapper from "./web_socket_wrapper";

class PlatformConnection{

  constructor(platformIds, platformIpAddresses, onData, onError){
    this.platformIds = !Array.isArray(platformIds) ? [platformIds] : platformIds;
    this.platformIpAddresses = !Array.isArray(platformIpAddresses) ? [platformIpAddresses] : platformIpAddresses;
    this.ws = null;
    this.onData = onData;
    this.onError = onError;
    this.sendDataTimer = null;
  }

  connect(){
    if (!this.ws)
      this._initWebSocket();
  }

  disconnect(){
    if (this.ws)
      this.ws.close();
  }

  sendData(data, ip = null){
    if (!ip && this.platformIpAddresses.length > 0)
      ip = this.platformIpAddresses[0];
    // Minimum data send interval is 50ms
    if (this.sendDataTimer !== null)
      clearTimeout(this.sendDataTimer);
    this.sendDataTimer = setTimeout(() => {
      this.ws.sendData({ip, data});
    }, 50);
  }

  static mergeInstrumentData(instrumentData, stateInstrumentData){
    let stateData = Object.assign([], stateInstrumentData);
    instrumentData.forEach(d => {
      const index = stateData.findIndex(sd => parseInt(sd.id, 10) === parseInt(d.id, 10));
      if (index !== -1) {
        stateData[index] = d;
      } else
        stateData.push(d);
    });
    return stateData;
  }

  _initWebSocket(){
    const jwt = localStorage.getItem('jwt');
    const url = Config.webSocketUri +
      '?platformId=' + this.platformIds.join(',') + '&jwt=' + jwt + '&ip=' + this.platformIpAddresses.join(',');
    const params = {
      reconnect: false,
      url: url,
      onMessage: this._handleData.bind(this),
      onError: this._handleError.bind(this),
      onClose: this._handleClose.bind(this),
      debug: false
    };
    this.ws = new WebSocketWrapper(params);
  }

  _handleError(error) {
    console.log(error);
    this.onError();
  }

  _handleClose(evt){
    if (evt.code > 1000){
      console.log("Websocket closed with code: " + evt.code);
      console.log(evt);
      this.onError();
    }
  }

  _parseInstrumentData(data){
    let instrumentData = [];
    if (Array.isArray(data)){
      data.forEach(d => d.instruments && instrumentData.push(d.instruments[0]))
    } else if (data.instruments){
      instrumentData = [data.instruments[0]];
    }
    return instrumentData;
    //if (instrumentData.length === 0)
    //  return null;
  }

  _parseSequenceData(data){
    let sequenceData = null;
    if (Array.isArray(data)){
      data.forEach(d => {
        if (d.sequenceStepId !== undefined && d.sequenceId !== undefined){
          sequenceData = {
            currentSequenceStepId: parseInt(d.sequenceStepId, 10),
            currentSequenceId: parseInt(d.sequenceId, 10)
          };
        }
      });
    } else if (data.sequenceStepId !== undefined && data.sequenceId !== undefined){
      sequenceData = {
        currentSequenceStepId: parseInt(data.sequenceStepId, 10),
        currentSequenceId: parseInt(data.sequenceId, 10)
      }
    }
    return sequenceData;
  }

  _handleData(data) {
    let result = JSON.parse(data);
    if (!result)
      return;
    const instrumentData = this._parseInstrumentData(result);
    const sequenceData = this._parseSequenceData(result);
    let params = {};
    if (instrumentData && instrumentData.length > 0)
      params.instrumentData = instrumentData;
    if (sequenceData)
      params = Object.assign(params, sequenceData);
    if (Object.keys(params).length > 0){
      this.onData(params);
    }
  }
}

export default PlatformConnection;