import React from 'react';
import { Link } from 'react-router-dom';
import InstrumentList from './instrument_list';
import StepList from './step_list';
import SequenceList from './sequence_list';
import { Tabs, Tab } from 'react-bootstrap';
import Breadcrumbs from '../../lib/breadcrumbs';
import InstrumentAlarmLogList from './instrument_alarm_log_list';
import Monitoring from './monitoring';
import UserGroupSelector from '../../user_group_selector';
import ResourceDetails from "../../resource_details";
import ChangeLog from "../../change_log";
import PlatformAdmin from "./platform_admin";

class PlatformDetails extends ResourceDetails {

  static get apiType(){
    return 'platforms';
  }

  render() {
    return (
      <div className="platform-details-component">
        <Breadcrumbs>
          <Link to="/platforms">Instrument platforms</Link>
          {this.resource() && this.resource().attributes.name}
        </Breadcrumbs>
        <Tabs defaultActiveKey={this.resolveTab('instruments')} id="platform-details-tabs" onSelect={this.handleTabSelect} mountOnEnter={true} unmountOnExit={true}>
          <Tab eventKey='instruments' title="Instruments">
            <InstrumentList platform={this.resource()}></InstrumentList>
          </Tab>
          {this.resource() && this.resource().canIndex('steps') &&
          <Tab eventKey='steps' title="Steps">
            <StepList platform={this.resource()}></StepList>
          </Tab>}
          {this.resource() && this.resource().canIndex('sequences') &&
          <Tab eventKey='sequences' title="Sequences">
            <SequenceList platform={this.resource()}></SequenceList>
          </Tab>}
          {this.resource() && this.resource().canIndex('instrumentAlarmLogs') &&
          <Tab eventKey='instrument-alarm-logs' title="Alarm log">
            <InstrumentAlarmLogList platformId={this.resourceId()} instrumentNames={this.resource() && this.resource().attributes['alarm-instruments']}></InstrumentAlarmLogList>
          </Tab>}
          <Tab eventKey='monitoring' title="Monitoring">
            <Monitoring platformId={this.resourceId()}
                        showAutomationControl={this.resource() && this.resource().canIndex('sequences')}
                        platformIpAddress={this.resource() && this.resource().attributes['ip-address']}></Monitoring>
          </Tab>
          {this.resource() && this.resource().canIndex('changeLogs') &&
          <Tab eventKey='change-log' title="Change log">
            <ChangeLog platformId={this.resourceId()}></ChangeLog>
          </Tab>}
          {this.resource() && this.resource().canIndex('user-groups') &&
          <Tab eventKey='user-groups' title="User groups">
            <UserGroupSelector platformId={this.resourceId()}></UserGroupSelector>
          </Tab>}
          {this.resource() && this.resource().canIndex('admin') &&
          <Tab eventKey='admin' title="Admin">
            <PlatformAdmin></PlatformAdmin>
          </Tab>}
        </Tabs>
      </div>
    );
  }
}

export default ResourceDetails.connect()(PlatformDetails);