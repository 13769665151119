import React from 'react';
import ResourceList from "../../resource_list";
import Breadcrumbs from "../../lib/breadcrumbs";
import { Link } from "react-router-dom";
import Confirm from "react-confirm-bootstrap";
import { Button } from "react-bootstrap";
import Time from '../../lib/time';
import OrderableTiles from "../../lib/orderable_tiles";

class RuleList extends ResourceList {

  static get apiType() {
    return 'rules';
  }

  defaultQueryParams() {
    return {
      filter: {
        'sequence-step.id': this.sequenceStepId()
      },
      include: ['conditions.instrument'],
      sort: ['index-number'],
      pageSize: 1000
    }
  }

  platformId(){
    return this.props.match.params.platformId;
  }

  sequenceId(){
    return this.props.match.params.sequenceId;
  }

  sequenceStepId(){
    return this.props.match.params.sequenceStepId;
  }

  platform(){
    return this.resource('platforms', this.platformId());
  }

  sequence(){
    return this.resource('sequences', this.sequenceId());
  }

  sequenceStep(id = null){
    if (id)
      return this.resource('sequence-steps', id);
    else
      return this.resource('sequence-steps', this.sequenceStepId());
  }

  componentWillMount() {
    super.componentWillMount();
    this.props.loadResources('platforms/' + this.platformId());
    this.props.loadResources('sequences/' + this.sequenceId());
    this.props.loadResources('sequence-steps?filter[platform.id]=' + this.platformId()+ '&include=sequence,step');
  }

  operators(){
    return {
      'eq': '==',
      'not_eq': '<>',
      'lt': '<',
      'gt': '>',
      'lteq': '<=',
      'gteq': '>='
    };
  }

  confirmReorder(id, newIndex){
    const data = {
      type: 'rules',
      id: id,
      attributes: {
        'index-number': newIndex
      }
    };
    this.props.updateResource(data).then(() => this.reloadResources());
  }

  render(){
    const condition_info = (condition) => (
      <span className="condition">
        {condition.attributes['condition-type'] === 'duration' &&
        <strong>Duration</strong>}
        {condition.attributes['condition-type'] === 'counter_value' &&
        <span>
          Counter&nbsp;
          <strong>{condition.attributes.characteristics['counter_name']}</strong>
          &nbsp;value&nbsp;
        </span>}
        {condition.attributes['condition-type'] === 'instrument_value' &&
        <span>
          Instrument&nbsp;
          <strong>{condition.included.instrument.attributes.name}</strong>
          &nbsp;value&nbsp;
        </span>}
        <span className="operator">{this.operators()[condition.attributes.operator] || ''}</span>
        <strong className="value">
        {condition.attributes['condition-type'] === 'counter_value' &&
        condition.attributes.value}
        {condition.attributes['condition-type'] === 'instrument_value' &&
        <span>
          {condition.attributes.value}
          &nbsp;
          {!condition.attributes['formula'] && condition.included.instrument.attributes.unit}
        </span>}
        {condition.attributes['condition-type'] === 'duration' &&
        <Time.Duration>{condition.attributes.value * 1000}</Time.Duration>}
        </strong>
      </span>
    );
    const consequence_info = (consequence) => (
      <React.Fragment>
        {consequence['type'] === 'continue' &&
        <span className="consequence">
          Continue to the <strong>next step</strong>
        </span>}
        {consequence['type'] === 'goto_step' &&
        <span className="consequence">
          Goto step
          {this.sequenceStep(consequence['sequence_step_id']) &&
          <strong>
            &nbsp;
            {this.sequenceStep(consequence['sequence_step_id']).included.sequence &&
             this.sequenceStep(consequence['sequence_step_id']).included.sequence.id !== this.sequenceId() &&
            <span>
              {this.sequenceStep(consequence['sequence_step_id']).included.sequence.attributes.name}
              {" - "}
            </span>}
            #{this.sequenceStep(consequence['sequence_step_id']).attributes['order-number']}
            {" - "}
            {this.sequenceStep(consequence['sequence_step_id']).included.step.attributes.name}
          </strong>}
        </span>}
        {consequence['type'] === 'add_counter' &&
        <span className="consequence">
          Change counter
          <strong> {consequence['name']} </strong>
          by
          <strong> {consequence['value']}</strong>
        </span>}
        {consequence['type'] === 'set_counter' &&
        <span className="consequence">
          Set counter
          <strong> {consequence['name']} </strong>
          to
          <strong> {consequence['value']}</strong>
        </span>}
      </React.Fragment>
    );
    return (
      <div className="rule-list-component">
        <Breadcrumbs>
          <Link to="/platforms">Instrument platforms</Link>
          <Link to={`/platforms/${this.platformId()}`}>{this.platform() && this.platform().attributes.name}</Link>
          <Link to={`/platforms/${this.platformId()}#sequences`}>Sequences</Link>
          <Link to={`/platforms/${this.platformId()}/sequences/${this.sequenceId()}/steps`}>{this.sequence() && this.sequence().attributes.name}</Link>
          <span>
            Step #{this.sequenceStep() && this.sequenceStep().attributes['order-number']}
            &nbsp;-&nbsp;
            {this.sequenceStep() && this.sequenceStep().included.step.attributes['name']}
          </span>
        </Breadcrumbs>
        {this.resources().length > 1 && this.resources()[0].canUpdate() &&
        <span className="tile-drag-to-order-hint">Drag tiles to reorder</span>}
        <OrderableTiles onConfirmReorder={this.confirmReorder.bind(this)}>
          {this.resources().map(resource => (
            <div data-id={resource.id} data-index-number={resource.attributes['index-number']} key={"rule_" + resource.id} className="tile">
              <span className="index-number">{resource.attributes['order-number']}</span>
              <div className="conditions">
                <strong>IF </strong>
                {resource.included && resource.included.conditions && resource.included.conditions.map((condition, index) => (
                  <span key={'condition' + condition.id}>
                    {condition_info(condition)}
                    {index < resource.included.conditions.length - 1 &&
                    <strong> AND </strong>}
                  </span>
                ))}
              </div>
              <div className="consequences">
                <strong>THEN </strong>
                {resource.attributes.consequences && resource.attributes.consequences.map((consequence, index) => (
                  <span key={'consequence' + resource.id + '-' + index}>
                    {consequence_info(consequence)}
                    {index < resource.attributes.consequences.length - 1 &&
                    <strong> AND </strong>}
                  </span>
                ))}
              </div>
              <div className="actions">
                {resource.canUpdate() &&
                <Link to={`/platforms/${this.platformId()}/sequences/${this.sequenceId()}/steps/${this.sequenceStepId()}/rules/${resource.id}/edit`}>
                  <i className="action-icon glyphicon glyphicon-pencil" title="Edit rules"></i>
                </Link>
                }
                {resource.canDestroy() &&
                <Confirm
                  onConfirm={() => {this.handleResourceDelete(resource.id)}}
                  body={`Are you sure you want to remove the rule?`}
                  confirmText="Confirm delete"
                  title="Remove rule from step">
                  <Button className="btn-link">
                    <i className="action-icon glyphicon glyphicon-trash"></i>
                  </Button>
                </Confirm>}
              </div>
            </div>
          ))}
        </OrderableTiles>
        <Link to={`${this.props.match.url}/new`} className="new-resource-tile-button">
          Add a rule
        </Link>
      </div>
    );
  }
}

export default ResourceList.connect()(RuleList);