import React from "react";
import PropTypes from 'prop-types';
import { FormGroup, ControlLabel, FormControl, HelpBlock } from 'react-bootstrap';

class ResourceFormSelect extends React.Component {

  componentWillMount() {
    if (this.props.relationship && this.props.resourceForm && this.props.name)
      this.props.resourceForm.relationships.push(this.props.name);
  }

  resolveLabel(){
    if (this.props.label)
      return this.props.label;
    return (this.props.name.charAt(0).toUpperCase() + this.props.name.slice(1)).replace('-',' ');
  }

  resolveValue(){
    const name = this.props.name;
    const form = this.props.resourceForm;

    if (form.resolveValue(name)){
      return form.resolveValue(name);
    }

    if (this.props.relationship &&
      form.resource() &&
      form.resource().relationships &&
      form.resource().relationships[this.props.name] &&
      form.resource().relationships[this.props.name].data
    ) {
      return form.resource().relationships[this.props.name].data.id;
    }
  }

  onChange(ev){
    const form = this.props.resourceForm;
    if (this.props.onChange)
      this.props.onChange(ev);
    else
      form.handleInputChange(ev);
  }

  objValue(path, context) {
    const namespaces = path.split(".");
    for (let i = 0; i < namespaces.length; i++) {
      context = context[namespaces[i]];
    }
    return context;
  }

  render() {
    const form = this.props.resourceForm;
    const name = this.props.name;
    return (
      <FormGroup controlId={name} className="resource-form-select-component" validationState={form.resolveError(name) ? 'error' : null}>
        <ControlLabel>{this.resolveLabel()}</ControlLabel>
        <FormControl componentClass="select" name={name} value={this.resolveValue()} onChange={this.onChange.bind(this)} disabled={this.props.disabled}>
          <option value="">- select -</option>
          {this.props.collection.map((obj) => {
            const value = this.props.valueAttribute !== '' ? this.objValue(this.props.valueAttribute, obj) : obj;
            const label = this.props.labelAttribute !== '' ? this.objValue(this.props.labelAttribute, obj) : obj;
            return <option key={"option-" + value} value={value}>{label}</option>
          })}
        </FormControl>
        {form.resolveError(name) &&
        <HelpBlock>{form.resolveError(name).title}</HelpBlock>
        }
      </FormGroup>
    )
  }

}

ResourceFormSelect.propTypes = {
  resourceForm: PropTypes.object,
  name: PropTypes.string,
  label: PropTypes.string,
  collection: PropTypes.array,
  relationship: PropTypes.bool,
  disabled: PropTypes.bool,
  valueAttribute: PropTypes.string,
  labelAttribute: PropTypes.string,
  onChange: PropTypes.func
};

ResourceFormSelect.defaultProps = {
  relationship: false,
  disabled: false,
  valueAttribute: 'id',
  labelAttribute: 'attributes.name'
};

export default ResourceFormSelect;