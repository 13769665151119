import React from 'react'
import { Route, Switch } from "react-router-dom";
import AdminTabs from "./admin_tabs";
import UserForm from "./user_form";
import UserGroupForm from "./user_group_form";
import OrganizationForm from "./organization_form";
import InstrumentPresetForm from "./instrument_preset_form";
import MediaAccountForm from "./media_account_form";

class Admin extends React.Component {
  render() {
    return (
      <div className="admin-component">
        <Switch>
          <Route exact path={`${this.props.match.url}`} component={AdminTabs}/>
          <Route path={`${this.props.match.url}/instrument-presets/new`}
                 render={(props)=> <InstrumentPresetForm {...props} returnPath={`${this.props.match.url}#instrument-presets`}/>}/>
          <Route path={`${this.props.match.url}/instrument-presets/:id/edit`}
                 render={(props)=> <InstrumentPresetForm {...props} returnPath={`${this.props.match.url}#instrument-presets`}/>}/>
          <Route path={`${this.props.match.url}/users/new`}
                 render={(props)=> <UserForm {...props} returnPath={`${this.props.match.url}#users`}/>}/>
          <Route path={`${this.props.match.url}/users/:id/edit`}
                 render={(props)=> <UserForm {...props} returnPath={`${this.props.match.url}#users`}/>}/>
          <Route path={`${this.props.match.url}/user-groups/new`}
                 render={(props)=> <UserGroupForm {...props} returnPath={`${this.props.match.url}#user-groups`}/>}/>
          <Route path={`${this.props.match.url}/user-groups/:id/edit`}
                 render={(props)=> <UserGroupForm {...props} returnPath={`${this.props.match.url}#user-groups`}/>}/>
          <Route path={`${this.props.match.url}/organizations/new`}
                 render={(props)=> <OrganizationForm {...props} returnPath={`${this.props.match.url}#organizations`}/>}/>
          <Route path={`${this.props.match.url}/organizations/:id/edit`}
                 render={(props)=> <OrganizationForm {...props} returnPath={`${this.props.match.url}#organizations`}/>}/>
          <Route path={`${this.props.match.url}/media-accounts/new`}
                 render={(props)=> <MediaAccountForm {...props} returnPath={`${this.props.match.url}#media-accounts`}/>}/>
          <Route path={`${this.props.match.url}/media-accounts/:id/edit`}
                 render={(props)=> <MediaAccountForm {...props} returnPath={`${this.props.match.url}#media-accounts`}/>}/>
        </Switch>
      </div>
    );
  }
}

export default Admin;