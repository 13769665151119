import React from 'react';
import PropTypes from 'prop-types';
import ResourceList from '../../resource_list';
import {Button, Panel, Glyphicon} from 'react-bootstrap';
import Time from '../../lib/time';

const apiType = 'sequences';

class PhaseTable extends ResourceList {

  static get apiType(){
    return apiType;
  }

  constructor(props){
    super(props);
    this.handleStopButtonClick = this.handleStopButtonClick.bind(this);
    this.handleReloadButtonClick = this.handleReloadButtonClick.bind(this);
  }

  defaultQueryParams() {
    return {
      filter: {
        'platform.id': this.props.platformId,
      },
      include: ['sequence-steps.step'],
      sort: ['index-number']
    }
  }

  sequenceSteps(sequence){
    if (!sequence || !sequence.included['sequence-steps'])
      return [];
    //console.log("TEST");
    //const stepIds = sequence.included['sequence-steps'].sort(res => res.attributes['index-number']).map(res => res.attributes['step-id']);
    //stepIds.map(id => sequence.included['steps'].id === id)
    //console.log(sequence.included['sequence-steps'].sort(res => parseInt(res.attributes['index-number'], 10)));
    return sequence.included['sequence-steps'].sort((a, b) => parseInt(a.attributes['index-number'], 10) - parseInt(b.attributes['index-number'], 10));
  }
/*
  sequences(){
    if (!this.props.resources || !this.props.resources['sequences'])
      return [];
    return this.props.resources['sequences'].filter(res => this.state.sequenceIds.indexOf(res.id) !== -1).sort(res => res.attributes['index-number']);
  }
*/
  resolveClass(sequence, sequenceStep){
    let classes = ['phase'];

    if (!sequence.attributes.active)
      classes.push('disabled-sequence');
    if (!sequenceStep.attributes.active)
      classes.push('disabled-step');

    if (this.props.currentSequenceStepId === parseInt(sequenceStep.id, 10))
      classes.push('current-step');
    if (this.props.currentSequenceId === parseInt(sequence.id, 10))
      classes.push('current-sequence');
    return classes.join(' ');
  }

  maxSteps(){
    let max = 0;
    if (this.resources())
      this.resources().forEach(seq => this.sequenceSteps(seq).length > max ? max = this.sequenceSteps(seq).length : null);
    return max;
  }

  emptyCells(seq){
    let ret = [];
    for (let i = 0; i < this.maxSteps() - this.sequenceSteps(seq).length; i ++)
      ret.push(i);
    return ret;
  }

  handleStopButtonClick(){
    if(this.props.sendData)
      this.props.sendData({stopRun: true});
  }

  handleReloadButtonClick(){
    if(this.props.sendData)
      this.props.sendData({reloadSequences: true});
  }

  handlePlayButtonClick(seqId){
    if(this.props.sendData && seqId)
      this.props.sendData({runSequence: seqId});
    else if (this.props.sendData)
      this.props.sendData({runSequenceLoop: true});
  }

  render(){
    return (
      this.resources().length > 0 &&
        <Panel className="phase-table-component">
          <Panel.Heading>Automation control</Panel.Heading>
          <Panel.Body>
            <table>
              <tbody>
              {this.resources().map(seq => (
                <tr key={seq.id}>
                  <th className='sequence-name' key={seq.id + 'x0'}>
                    {seq.attributes['start-date'] &&
                    <Glyphicon glyph="time" title={"Timer: "  + Time.formatTime(seq.attributes['start-date']) + "\nRep. interval: " + (seq.attributes['interval-value'] || '') + ' '  + (seq.attributes['interval-unit'] || '')}/>}
                    {seq.attributes.name}
                    <Button onClick={() => this.handlePlayButtonClick(seq.id)} className="play-sequence-button" disabled={this.props.currentSequenceId ? true : false}>
                      <Glyphicon glyph="play"/>
                    </Button>
                  </th>
                  {this.sequenceSteps(seq).map(ss => (
                    <td key={seq.id + 'x' + ss.id} className={this.resolveClass(seq, ss)}>
                      <span className="step-name">{ss.included['step'].attributes.name}</span>
                    </td>
                  ))}
                  {this.emptyCells(seq).map(ec => (
                    <td key={seq.id + 'xEC' + ec}>&nbsp;</td>
                  ))}
                </tr>
              ))}
              </tbody>
            </table>
            <Button onClick={this.handleReloadButtonClick} className="reload-button" bsStyle="default">
              <Glyphicon glyph="refresh"/>
              Reload
            </Button>
            <Button onClick={() => this.handlePlayButtonClick(null)} className="play-loop-button" bsStyle="default" disabled={this.props.currentSequenceId ? true : false}>
              <Glyphicon glyph="play"/>
              Play loop
            </Button>
            <Button onClick={this.handleStopButtonClick} className="stop-run-button" bsStyle="default" disabled={!this.props.currentSequenceId}>
              <Glyphicon glyph="stop"/>
              Stop run
            </Button>
          </Panel.Body>
        </Panel>
    );
  }
}

PhaseTable.propTypes = {
  platformId: PropTypes.string,
  currentSequenceStepId: PropTypes.number,
  currentSequenceId: PropTypes.number,
  sendData: PropTypes.func
};

export default ResourceList.connect()(PhaseTable);