import React from 'react';
import ResourceList from "../../resource_list";
import DataFilter from "./data_filter";
import WorldMap from "../../lib/world_map";
import Time from '../../lib/time';

const apiType = 'data';

class DataPlot extends ResourceList {

  static get apiType(){
    return apiType;
  }

  defaultQueryParams() {
    return {
      filter: {
        'data-platform.id': this.props.dataPlatformId,
      },
      sort: ['-sampling_time'],
      pageSize: 1000
    }
  }

  constructor(props) {
    super(props);
    this.state.showFilter = false;
    if (this.urlQueryParams().filter)
      this.state.showFilter = true;
  }

  onFilterChange(params){
    this.loadResources({filter: params, currentPage: 1});
  }

  markers(){
    let markers = [];
    let titles = {};
    this.resources().forEach(resource => {
      if (!resource.attributes.value || resource.attributes.value === '')
        return;
      const time = resource.attributes['sampling-time'];
      const formattedTime = Time.formatTime(time, 'withTz', 'milliseconds');
      if (resource.attributes.parameter === 'Coordinates'){
        let parts = resource.attributes.value.split(',');
        let lat = parseFloat(parts[0]);
        let lng = parseFloat(parts[1]);
        if (isNaN(lat) || isNaN(lng))
          return;
        markers.push({
          group: resource.attributes['instrument-group'] || '',
          time: formattedTime,
          lat: lat,
          lng: lng,
          //icon: 'https://mt.google.com/vt/icon/name=icons/spotlight/directions_destination_measle_8x.png&scale=1',
          title: 'Time: ' + formattedTime + "\n"
        });
      }
      const id = formattedTime + (resource.attributes['instrument-group'] || '');
      if (!titles[id])
        titles[id] = {};
      titles[id][resource.attributes['instrument-name']] = resource.attributes['value'] + ' '  + resource.attributes['unit'];
    });
    // Set titles
    markers.forEach(marker => {
      const id = marker.time + marker.group;
      if (titles[id])
        marker.title += Object.keys(titles[id]).map(key => key + ': ' + titles[id][key]).join("\n");
    });
    return markers;
  }

  paths(){
    let paths = {};
    this.resources().forEach((resource)=>{
      if (!resource.attributes.value || resource.attributes.value === '')
        return;
      if (resource.attributes.parameter === 'Coordinates'){
        let parts = resource.attributes.value.split(',');
        let lat = parseFloat(parts[0]);
        let lng = parseFloat(parts[1]);
        if (isNaN(lat) || isNaN(lng))
          return;
        if (!paths[resource.attributes['instrument-name']])
          paths[resource.attributes['instrument-name']] = [];
        paths[resource.attributes['instrument-name']].push({
          lat: lat,
          lng: lng
        });
      }
    });
    return paths;
  }

  render() {
    return (
      <div className="data-plot-component">
        <strong className="pull-left">Total size: {this.state.recordCount || 0} rows (max. row count to plot is 1000)</strong>
        <DataFilter filterParams={this.state.queryParams.filter} onChange={this.onFilterChange.bind(this)} dataPlatformId={this.props.dataPlatformId}/>
        <WorldMap markers={this.markers()} paths={this.paths()} fitBounds={true}/>
      </div>
    );
  }
}

export default ResourceList.connect()(DataPlot);