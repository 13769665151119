import React from 'react';
import PropTypes from 'prop-types';
import { readEndpoint, updateResource, createResource, deleteResource } from 'redux-json-api';
import _ from 'lodash';
import Resource from "./lib/resource";
import {connect} from "react-redux";

export default class ResourceForm extends React.Component {

  static get apiType() {
    // Override to set JSON API endpoint
    return null;
  }

  // Write some mapping here or install some plugin to do that automatically!
  pluralize(name){
    return name + 's';
  }

  redirectAfterCreate() {
    // Override to set return path from create
    return this.props && this.props.returnPath;
  }

  redirectAfterUpdate() {
    // Override to set return path from update
    return this.redirectAfterCreate();
  }

  constructor(props) {
    super(props);
    this.state = {errors: [], attributes: {}, submitting: false, submitted: false};
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.resolveValue = this.resolveValue.bind(this);
    this.resolveError = this.resolveError.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.relationships = [];
  }

  isSubmitting(){
    return this.state.submitting;
  }

  // Returns true when form has been submitted without errors
  isSubmitted(){
    return this.state.submitted;
  }

  resourceId(){
    return this.props.match && this.props.match.params && this.props.match.params.id;
  }

  componentWillMount() {
    let includes = '';
    if (this.constructor.relationships)
      includes = '?include=' + this.constructor.relationships.join(',');
    // Update or create?
    if (this.resourceId())
      this.props.loadResources(this.constructor.apiType + '/' + this.resourceId() + includes);
  }

  resource(apiType = null, id = null){
    if (!this.props.resources)
      return null;
    if (apiType == null && this.resourceId() && this.props.resources[this.constructor.apiType])
      return(this.props.resources[this.constructor.apiType].find((res) => { return res.id === this.resourceId() }));
    else if (id !== null && this.props.resources[apiType])
      return(this.props.resources[apiType].find((res) => { return res.id === id }));
    else
      return null;
  }

  loadResourcesAndStoreIds(url, stateParam = null){
    if (stateParam === null)
      stateParam = url.split('?')[0] + 'Ids';
    this.props.loadResources(url).then((response) => {
      if (response && response.body && response.body.data)
        this.setState({[stateParam]: response.body.data.map(entity => entity.id)});
    });
  }

  resources(type, ids = null){
    if (this.props.resources && this.props.resources[type] && ids)
      return this.props.resources[type].filter(res => ids.indexOf(res.id) !== -1);
    else if (this.props.resources && this.props.resources[type])
      return this.props.resources[type];
    else
      return [];
  }

  handleCheckboxChange(ev){
    const name = ev.target.name;
    const value = ev.target.checked;
    const attributes = _.cloneDeep(this.state.attributes);
    attributes[name] = value;
    this.setState({attributes: attributes});
  }

  handleInputChange(ev) {
    const name = ev.target.name;
    const value = ev.target.value;
    const attributes = _.cloneDeep(this.state.attributes);
    attributes[name] = value;
    this.setState({attributes: attributes});
  }

  updateAttributes(){
    if (this.relationships.length > 0)
      return _.omit(this.state.attributes, this.constructor.relationships);
    else
      return this.state.attributes;
  }

  createAttributes(){
    if (this.relationships.length > 0)
      return _.omit(this.state.attributes, this.constructor.relationships);
    else
      return this.state.attributes;
  }

  createRelationships(){
    let rs = {};
    this.relationships.forEach((r) => {
      if (this.state.attributes[r])
        rs[r] = {data: {type: this.pluralize(r), id: this.state.attributes[r]}};
    });
    return rs;
  }

  updateRelationships(){
    let rs = {};
    this.relationships.forEach((r) => {
      if (this.state.attributes[r])
        rs[r] = {data: {type: this.pluralize(r), id: this.state.attributes[r]}};
    });
    return rs;
  }

  handleSubmit(ev){
    this.setState({submitting: true});
    let promise;
    if (this.resource()) {
      promise = this.props.updateResource(
        this,
        {
          type: this.constructor.apiType,
          id: this.resource().id,
          attributes: this.updateAttributes(),
          relationships: this.updateRelationships()
        }
      )
    } else
      promise = this.props.createResource(
        this,
        {
          type: this.constructor.apiType,
          attributes: this.createAttributes(),
          relationships: this.createRelationships()
        }
      );
    return promise.then(
      (response) => {this.setState({submitting: false, submitted: true}); return response;},
      (error) => {this.setState({submitting: false}); return error;}
    );
  }

  handleDelete(){
    return this.props.deleteResource(
      this,
      {
        type: this.constructor.apiType,
        id: this.resource().id,
      }
    ).then(
      (response) => {this.setState({submitting: false, submitted: true}); return response;},
      (error) => {this.setState({submitting: false}); return error;}
    );
  }

  resolveValue(attribute){
    if (this.state.attributes[attribute] !== undefined &&
      this.state.attributes[attribute] !== null)
      return this.state.attributes[attribute];
    else if (this.resource()) {
      if (this.resource().attributes[attribute] !== undefined &&
        this.resource().attributes[attribute] !== null)
        return this.resource().attributes[attribute];
      else
        return '';
    } else
      return '';
  }

  resolveError(attribute){
    return this.state.errors.find(error => {
      return error.source && error.source.pointer.split('/').pop() === attribute;
    });
  }

  static mapStateToProps(state){
    return Resource.mapStateToResources(state);
  }

  static mapDispatchToProps = (dispatch) => {
    return {
      loadResources: (url) => {
        return dispatch(readEndpoint(url))
      },
      deleteResource: (component, data) => {
        return new Promise(function(resolve, reject){
          dispatch(deleteResource(data)).then(function(response){
            resolve(response && response.data);
          }).catch(function(err){
            console.log(err);
            if (err.response.data.errors)
              component.setState({errors: err.response.data.errors});
            reject(err.response.data);
          });
        })
      },
      createResource: (component, data) => {
        return new Promise(function(resolve, reject){
          dispatch(createResource(data)).then(function(response){
            resolve(response.data);
          }).catch(function(err){
            console.log(err);
            if (err.response.data.errors)
              component.setState({errors: err.response.data.errors});
            reject(err.response.data);
          });
        })
      },
      updateResource: (component, data) => {
        return new Promise(function(resolve, reject) {
          dispatch(updateResource(data)).then(function (response) {
            resolve(response.data);
          }).catch(function (err) {
            console.log(err);
            if (err.response.data.errors)
              component.setState({errors: err.response.data.errors});
            reject(err.response.data);
          });
        })
      }
    }
  };

  static connect(stateToProps = null, dispatchToProps = null){
    let mapState = null;
    let mapDispatch = null;
    if (!stateToProps)
      mapState = ResourceForm.mapStateToProps;
    else
      mapState = (dispatch) => {
        let props = ResourceForm.mapStateToProps(dispatch);
        return Object.assign(props, stateToProps(dispatch));
      };
    if (!dispatchToProps)
      mapDispatch = ResourceForm.mapDispatchToProps;
    else
      mapDispatch = (dispatch) => {
        let props = ResourceForm.mapDispatchToProps(dispatch);
        return Object.assign(props, dispatchToProps(dispatch));
      };
    return connect(mapState, mapDispatch);
  }
}

ResourceForm.propTypes = {
  resources: PropTypes.object
};