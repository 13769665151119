import React from 'react';
import Config from "../../config"
import Breadcrumbs from "../../lib/breadcrumbs"
import {Link} from "react-router-dom"
import Time from '../../lib/time'
import {Button} from "react-bootstrap"

class VideoArchive extends React.Component{
  constructor(props){
    super(props);
    this.state = {videos: [], missingThumbs: []};
  }

  componentWillMount() {
    this.fetchStreams();
  }

  destroyVideo(event, video){
    let passwd = prompt("Give password to destroy the video " + video);
    if (passwd){
      const formData = new FormData();
      formData.append('video', video);
      formData.append('password', passwd);
      const data = {
        method: 'POST',
        body: formData
      }
      fetch(Config.videoDestroyUri, data).then(response => {
        console.log(response);
        this.fetchStreams();
      }).catch(err =>
        console.log(err)
      );
    }
    event.stopPropagation();
    event.preventDefault();
    return false;
  }

  fetchStreams(){
    fetch(Config.videoArchiveListUri).then(response => response.json().then(videos => ({videos, response}))).then(
      ({videos, response}) => {
        if (!response.ok) {
          console.log("Unable to fetch MP4s");
        } else {
          this.setState({videos})
        }
      }).catch(err => {
      console.log(err);
    });
  }

  parseFileName(filename){
    try{
      let parts = filename.split('-')
      let p = parts.pop()
      const d = p.split('_')[1]
      const t = p.split('_')[2]
      const date = new Date()
      date.setDate(parseInt(d.substr(0, 2)))
      date.setMonth(parseInt(d.substr(2, 2)) - 1)
      date.setFullYear(parseInt(d.substr(4, 4)))
      date.setHours(parseInt(t.substr(0, 2)))
      date.setMinutes(parseInt(t.substr(2, 2)))
      date.setSeconds(parseInt(t.substr(4, 2)))
      return {
        name: parts.join('-'),
        time: date
      }
    } catch (e){
      console.error(e)
      return {
        name: 'invalid',
        time: new Date()
      }
    }
  }

  thumbMissing(name){
    this.setState({missingThumbs: this.state.missingThumbs.concat(name)})
  }

  videos(){
    return this.state.videos.sort((a, b) => {
      const t1 = this.parseFileName(a).time
      const t2 = this.parseFileName(b).time
      return t1 < t2 ? 1 : -1
    })
  }

  render(){
    return (
      <div className="video-archive-component">
        <Breadcrumbs>
          <Link to={'/public'}>Home</Link>
          Video archive
        </Breadcrumbs>
        {this.state.videos.length === 0 &&
        <h1>No videos in archive</h1>}
        {this.videos().map(video => (
          <React.Fragment key={video}>
            <a rel="noopener noreferrer"
               className="video-item"
               href={`${Config.videoArchiveUri}/${video}.mp4`}
               target="_blank">
              {this.state.missingThumbs.indexOf(video) === -1 &&
              <img alt={`${video}.png`}
                   src={`${Config.videoArchiveUri}/${video}.png`}
                   onError={() => this.thumbMissing(video)}/>}
              {this.state.missingThumbs.indexOf(video) !== -1 &&
              <div className="thumb-missing"><span>No thumbnail available</span></div>}
              <span className="time"><Time>{this.parseFileName(video).time}</Time></span>
              <br/>
              <span className="name">{this.parseFileName(video).name}</span>
              <Button className="btn-link destroy-video" onClick={(event => this.destroyVideo(event, video))}>
                <i className="action-icon glyphicon glyphicon-trash"></i>
              </Button>
            </a>
          </React.Fragment>
        ))}
        <div style={{clear: 'both'}}></div>
      </div>
    );
  }
}

export default VideoArchive;