import React from 'react';
import Pagination from 'react-bootstrap/lib/Pagination';
import { createUltimatePagination, ITEM_TYPES } from 'react-ultimate-pagination';
import PropTypes from "prop-types";
import ResourceFormFooter from "./resource_form_footer";

let UltimatePagination = createUltimatePagination({
  WrapperComponent: Pagination,
  itemTypeToComponent: {
    [ITEM_TYPES.PAGE]: ({value, isActive, onClick}) => (
      <Pagination.Item active={isActive} onClick={onClick}>{value}</Pagination.Item>
    ),
    [ITEM_TYPES.ELLIPSIS]: ({ isActive, onClick }) => (
      <Pagination.Ellipsis disabled={isActive} onClick={onClick} />
    ),
    [ITEM_TYPES.FIRST_PAGE_LINK]: ({ isActive, onClick }) => (
      <Pagination.First disabled={isActive} onClick={onClick} />
    ),
    [ITEM_TYPES.PREVIOUS_PAGE_LINK]: ({ isActive, onClick }) => (
      <Pagination.Prev disabled={isActive} onClick={onClick} />
    ),
    [ITEM_TYPES.NEXT_PAGE_LINK]: ({ isActive, onClick }) => (
      <Pagination.Next disabled={isActive} onClick={onClick} />
    ),
    [ITEM_TYPES.LAST_PAGE_LINK]: ({ isActive, onClick }) => (
      <Pagination.Last disabled={isActive} onClick={onClick} />
    ),
  },
});

class ResourceListPagination extends React.Component{
  render(){
    const resourceList = this.props.resourceList;
    return (
      <div className='resource-list-pagination-component'>
        {resourceList.pageCount() && resourceList.pageCount() > 1 &&
        <UltimatePagination
          hideEllipsis={false}
          boundaryPagesRange={1}
          siblingPagesRange={1}
          hidePreviousAndNextPageLinks={false}
          hideFirstAndLastPageLinks={false}
          totalPages={resourceList.pageCount()}
          currentPage={resourceList.currentPage()}
          onChange={resourceList.setPage} />}
      </div>
    )
  }
}

ResourceFormFooter.propTypes = {
  resourceList: PropTypes.object
};

export default ResourceListPagination;