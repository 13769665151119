import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Table, Button } from 'react-bootstrap';
import ResourceList from '../../resource_list';
import Confirm from 'react-confirm-bootstrap';
import ResourceListPagination from "../../resource_list_pagination";
import Time from '../../lib/time';
import { withRouter } from "react-router-dom";
import NewResourceLink from "../../lib/new_resource_link";

const apiType = 'steps';

class StepList extends ResourceList {

  static get apiType(){
    return apiType;
  }

  defaultQueryParams() {
    return {
      filter: {
        'platform.id': this.platformId()
      },
      include: ['step-instruments.instrument'],
      sort: ['name']
    }
  }

  platformId(){
    return this.props.match.params.id;
  }

  stepInstruments(step){
    if (step.included && step.included['step-instruments'])
      return step.included['step-instruments'];
    else
      return [];
  }

  samplingRate(resource){
    if (!resource.attributes['sampling-rate'] || resource.attributes['sampling-rate'] === '')
      return '';
    else
      return resource.attributes['sampling-rate'] + ' ms';
  }

  render() {
    const dataModeClass = (stepInstrument) => {
      switch(stepInstrument.attributes['store-data-mode']){
        case 'enabled':
          return 'badge-danger';
        case 'disabled':
          return 'badge-info';
        case 'on_change':
          return 'badge-warning'
        default:
          return ''
      }
    };
    const instrumentTag = (stepInstrument) => {
      const instrument = stepInstrument.included['instrument'];
      return (
        <span key={'si' + stepInstrument.id} className={'badge ' + dataModeClass(stepInstrument)} title={'State: ' + stepInstrument.attributes.state}>
          {instrument.attributes.name}
        </span>
      );
    };
    return (
      <div className="step-list-component">
        <NewResourceLink to={`/platforms/${this.platformId()}/${apiType}/new`} disabled={!this.props.platform || !this.props.platform.canCreate('steps')}>New step</NewResourceLink>
        <Table responsive hover>
          <thead>
          <tr>
            <th>Name</th>
            <th>Duration</th>
            <th>Sampling rate</th>
            <th>Instruments</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          {this.resources().map(resource => (
            <tr key={resource.id}>
              <td>{resource.attributes['name']}</td>
              <td><Time.Duration>{resource.attributes['duration'] * 1000}</Time.Duration></td>
              <td>{this.samplingRate(resource)}</td>
              <td>{this.stepInstruments(resource).map(r => instrumentTag(r))}</td>
              <td className="actions">
                {resource.canUpdate() &&
                <Link to={`/platforms/${this.platformId()}/${apiType}/${resource.id}/edit`}>
                  <i className="action-icon glyphicon glyphicon-pencil"></i>
                </Link>}
                {resource.canDestroy() &&
                <Confirm
                  onConfirm={() => {this.handleResourceDelete(resource.id)}}
                  body={`Are you sure you want to delete ${resource.attributes['name']}?`}
                  confirmText="Confirm delete"
                  title="Delete step">
                  <Button className="btn-link">
                    <i className="action-icon glyphicon glyphicon-trash"></i>
                  </Button>
                </Confirm>}
              </td>
            </tr>
          ))}
          </tbody>
        </Table>
        <ResourceListPagination resourceList={this}/>
      </div>
    );
  }
}

StepList.propTypes = {
  platform: PropTypes.object
};

export default withRouter(ResourceList.connect()(StepList));