import MapIconHouse from '../../assets/images/map_icon_house.png';
import MapIconHouseOffline from '../../assets/images/map_icon_house_offline.png';
import MapIconCar from '../../assets/images/map_icon_car.png';
import MapIconCarOffline from '../../assets/images/map_icon_car_offline.png';

export const instrumentMapIcon = (instrument, platformStatus = 'ok') => {
  let icon = null;

  if (platformStatus === 'ok'){
    if (instrument.attributes['instrument-type'] === 'Manual positioning')
      icon = MapIconHouse;
    else
      icon = MapIconCar;
  } else {
    if (instrument.attributes['instrument-type'] === 'Manual positioning')
      icon = MapIconHouseOffline;
    else
      icon = MapIconCarOffline;
  }

  return icon;
};