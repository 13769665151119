import React from 'react';
import ResourceForm from '../../resource_form';
import ResourceFormInput from "../../resource_form_input";
import ResourceFormFooter from "../../resource_form_footer";
import ResourceFormHeader from "../../resource_form_header";
import ResourceFormTypeahead from "../../resource_form_typeahead";

class InstrumentPresetForm extends ResourceForm {
  static get apiType(){
    return 'instrument-presets';
  }

  constructor(props){
    super(props);
    this.state.presetIds = [];
  }

  componentWillMount() {
    super.componentWillMount();
    this.loadResourcesAndStoreIds('instrument-presets', 'presetIds');
  }

  parameters(){
    const result = this.resources('instrument-presets', this.state.presetIds).map(ip => ip.attributes['parameter']);
    return result.filter((elem, pos, arr) => arr.indexOf(elem) === pos);
  }

  units(){
    const result = this.resources('instrument-presets', this.state.presetIds).map(ip => ip.attributes['unit']);
    return result.filter((elem, pos, arr) => arr.indexOf(elem) === pos);
  }

  render() {
    return (
      <div className="instrument-preset-form-component">
        <form>
          <ResourceFormHeader resourceForm={this}/>
          <ResourceFormInput name='instrument-type' resourceForm={this}/>
          <ResourceFormTypeahead name='parameter' resourceForm={this} options={this.parameters()}/>
          <ResourceFormTypeahead name='unit' resourceForm={this} options={this.units()}/>
          <ResourceFormFooter resourceForm={this}/>
        </form>
      </div>
    );
  }
};

export default ResourceForm.connect()(InstrumentPresetForm);