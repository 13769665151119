import React from 'react';
import { Route, Switch } from "react-router-dom";
import DataPlatformList from "./data_platform_list";
import DataPlatformDetails from "./data_platform_details";
import DataAnalysisList from "./data_analysis_list";
import DataAnalysisForm from "./data_analysis_form";
import DataAnalysisDetails from "./data_analysis_details";
import DataAnalysisRunForm from "./data_analysis_run_form";
import DataAnalysisRunDetails from "./data_analysis_run_details";
import DataPlatformForm from "./data_platform_form";
import DataInstrumentForm from "./data_instrument_form";
import DataAnalysisTimerForm from "./data_analysis_timer_form";

class Data extends React.Component{
  render() {
    return (
      <div className="data-component">
        <Switch>
          <Route exact path={`${this.props.match.url}`} component={DataPlatformList}/>
          <Route path={`${this.props.match.url}/:dataPlatformId/data-analyses/:dataAnalysisId/data-analysis-timers/:id/edit`} component={DataAnalysisTimerForm}/>
          <Route path={`${this.props.match.url}/:dataPlatformId/data-analyses/:dataAnalysisId/data-analysis-timers/new`} component={DataAnalysisTimerForm}/>
          <Route path={`${this.props.match.url}/:dataPlatformId/data-analyses/:dataAnalysisId/data-analysis-runs/new`} component={DataAnalysisRunForm}/>
          <Route path={`${this.props.match.url}/:dataPlatformId/data-analyses/:dataAnalysisId/data-analysis-runs/:id`} component={DataAnalysisRunDetails}/>
          <Route path={`${this.props.match.url}/:dataPlatformId/data-analyses/:id/edit`} component={DataAnalysisForm}/>
          <Route path={`${this.props.match.url}/:dataPlatformId/data-analyses/new`} component={DataAnalysisForm}/>
          <Route path={`${this.props.match.url}/:dataPlatformId/data-analyses/:id`} component={DataAnalysisDetails}/>
          <Route path={`${this.props.match.url}/:dataPlatformId/data-instruments/:id/edit`} component={DataInstrumentForm}/>
          <Route path={`${this.props.match.url}/:dataPlatformId/data-analyses`} component={DataAnalysisList}/>
          <Route path={`${this.props.match.url}/:id/edit`}
                 render={(props)=> <DataPlatformForm {...props} returnPath={this.props.match.url}/>}/>
          <Route path={`${this.props.match.url}/:id`} component={DataPlatformDetails}/>
        </Switch>
      </div>
    );
  }
}

export default Data;