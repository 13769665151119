import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loginRequest, loginError, loginSuccess } from './actions';
import { FormGroup, ControlLabel, FormControl, Button } from 'react-bootstrap';
import {Link} from "react-router-dom";
import Config from "./config";
import {setAxiosConfig} from "redux-json-api";

class LoginForm extends Component {

  constructor(props) {
    super(props);

    this.state = {email: '', password: ''};

    //this.state = {isToggleOn: true};

    // This binding is necessary to make `this` work in the callback
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    //this.handleSignInClick = this.handleSignInClick.bind(this);

  };

  handleInputChange(ev) {
    const name = ev.target.name;
    const value = ev.target.value;
    // Dynamic ES6 property key set, quite handy!
    this.setState({[name]: value});
  };

  handleSubmit() {
    //this.props.dispatch(loginUser(this.state, '/platforms'));
    const url = localStorage.getItem('returnUrl') || '/platforms';
    this.props.loginUser(this.state, url);
  };

  render() {
    return (
      <div className="login-component">
          <form>
            <h1>Login</h1>
            <FormGroup controlId="email">
              <ControlLabel>Email</ControlLabel>
              <FormControl autoFocus={true} type="email" name="email" value={this.state.email} onChange={this.handleInputChange}/>
            </FormGroup>
            <FormGroup controlId="password">
              <ControlLabel>Password</ControlLabel>
              <FormControl type="password" name="password" onChange={this.handleInputChange} onKeyPress={event => {
                if (event.key === "Enter") {
                  this.handleSubmit();
                }
              }}/>
            </FormGroup>
            <Link to="/" className="cancel-button">
              Cancel
            </Link>
            <span className="submit-button-container">
              <Button bsStyle="primary" onClick={this.handleSubmit}>Login</Button>
            </span>
          </form>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loginUser: (creds, redirect) => {
      const config = {
        method: 'POST',
        headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
        body: JSON.stringify({user: {email: creds.email, password: creds.password}})
      };

      let url = Config.apiUri + '/auth/sign_in';

      dispatch(loginRequest(creds));

      fetch(url, config).then(response => response.json().then(user => ({user, response}))).then(
        ({user, response}) => {
          if (!response.ok) {
            console.log("Auth error response");
            // If there was a problem, we want to
            // dispatch the error condition
            dispatch(loginError('cannot authenticate'));
            return Promise.reject(user)
          } else {
            console.log("Auth success");
            // If login was successful, set the token in local storage
            const jwt = response.headers.get("Authorization").replace(/^Bearer /, '');
            // Update redux-json-api access token
            dispatch(setAxiosConfig({
              headers: {'Authorization': 'Bearer ' + jwt}
            }));
            // Dispatch the success action
            dispatch(loginSuccess(jwt, user));
            window.location.href = redirect;
          }
        }).catch(err => {
        console.log(err);
        dispatch(loginError('Wrong password or/and username'));
      });
    }
  };
};

//export default UserList;
export default connect(null, mapDispatchToProps)(LoginForm);