class WebSocketWrapper{

  constructor(params){
    this.ws = null;
    this.url = params.url;
    this.attempts = 0;
    this.protocol = params.protocol;
    this.reconnectIntervalInMilliSeconds = params.reconnectIntervalInMilliSeconds || 0;
    this.reconnect = params.reconnect === undefined ? true : params.reconnect;
    this.debug = params.debug;
    this.onError = params.onError;
    this.onMessage = params.onMessage;
    this.onClose = params.onClose;
    this._setup();
  }

  logging(line) {
    if (this.debug === true) {
      console.log(line);
    }
  }

  _generateInterval (k) {
    if(this.reconnectIntervalInMilliSeconds > 0) {
      return this.reconnectIntervalInMilliSeconds;
    }
    return Math.min(30, (Math.pow(2, k) - 1)) * 1000;
  }

  _setup() {
    let websocket = null;

    try {
      websocket = new WebSocket(this.url, this.protocol);
    } catch (err) {
      this.logging('Unable to initialize WebSocket');
      if (this.onError)
        this.onError(err);
    }

   this.ws = websocket;

    websocket.onopen = () => {
      this.logging('Websocket connected: ' + this.url);
    };

    websocket.onmessage = (evt) => {
      this.onMessage(evt.data);
    };

    websocket.onerror = (evt) => {
      this.logging('Websocket ERROR: ' + evt);
      if (this.onError)
        this.onError(evt);
    };

    websocket.onclose = (evt) => {
      this.logging('Websocket disconnected: ' + evt.code);
      if (this.onClose)
        this.onClose(evt);
      if (this.reconnect) {
        let time = this._generateInterval(this.attempts);
        setTimeout(() => {
          this.attempts += 1;
          this._setup();
        }, time);
      }
    }
  }

  sendData(data){
    if (!this.ws)
      return;
    if (this.debug)
      console.log("Sending ws data: " + JSON.stringify(data));
    this.ws.send(JSON.stringify(data));
  }

  close() {
    this.reconnect = false;
    if (this.ws)
      this.ws.close();
  }
}

export default WebSocketWrapper;