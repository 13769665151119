import React from 'react';
import ResourceList from "../../resource_list";
import DataFilter from "./data_filter";
import DataGraph from '../../lib/data_graph';

const apiType = 'data';
const excludeParameters = ['Coordinates'];

class DataChart extends ResourceList {

  static get apiType(){
    return apiType;
  }

  defaultQueryParams() {
    return {
      include: [
        'data-uncertainty',
        'data-instrument'
      ],
      filter: {
        'data-platform.id': this.props.dataPlatformId,
      },
      sort: ['-sampling_time'],
      pageSize: 500
    }
  }

  onFilterChange(params){
    this.loadResources({filter: params, currentPage: 1});
  }

  render() {
    return (
      <div className="data-chart-component">
        <strong className="pull-left">Total size: {this.state.recordCount || 0} rows (max. row count to plot is 500)</strong>
        <DataFilter filterParams={this.state.queryParams.filter} onChange={this.onFilterChange.bind(this)} dataPlatformId={this.props.dataPlatformId} excludeParameters={excludeParameters}/>
        <DataGraph data={this.resources()} excludeParameters={excludeParameters}/>
      </div>
    );
  }
}

export default ResourceList.connect()(DataChart);