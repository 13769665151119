import React from 'react';
import connect from "react-redux/es/connect/connect";
import PropTypes from "prop-types";
import {readEndpoint} from "redux-json-api";
import Resource from "../../lib/resource";
import DataGraph from "../../lib/data_graph";
import PublicDataHeader from "./public_data_header";
import _ from 'lodash';

const excludeParameters = ['Coordinates'];

class PublicDataGraph extends React.Component{
  constructor(props){
    super(props);
    this.state = {dataIds: []};
  }

  instrument(){
    return this.props.dataInstrument;
  }

  componentWillMount() {
    this.loadResources();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!_.isEqual(prevProps.filter, this.props.filter))
      this.loadResources();
  }

  loadResources(){
    this.props.loadResources(this.url()).then(response => {
      if (response && response.body && response.body.data){
        this.setState({dataIds: response.body.data.map(entity => entity.id)});
      }
    });
  }

  data(){
    if (!this.props.resources || !this.props.resources['public-data'])
      return [];
    return this.state.dataIds.map(id => this.props.resources['public-data'].find(r => r.id === id)).filter(r => r);
  }

  url(){
    const filter = Object.assign({'data-instrument.id': this.instrument().id}, this.props.filter);
    const params = Object.keys(filter).map(key => `filter[${key}]=${encodeURIComponent(filter[key])}`).join('&');
    const size = Object.keys(this.props.filter).length > 0 ? this.props.maxPageSize : this.props.defaultPageSize;
    return `public-data?include=data-instrument&${params}&page[size]=${size}&sort=-sampling-time`;
  }

  render(){
    return (
      <div className="public-data-graph-component">
        <PublicDataHeader
          dataInstrument={this.instrument()}
          filter={this.props.filter}
          defaultPageSize={this.props.defaultPageSize}
          maxPageSize={this.props.maxPageSize}
        />
        <DataGraph height={330} data={this.data()} excludeParameters={excludeParameters} legend={false}/>
      </div>
    )
  }
}

PublicDataGraph.propTypes = {
  dataInstrument: PropTypes.object,
  filter: PropTypes.object,
  defaultPageSize: PropTypes.number,
  maxPageSize: PropTypes.number
};

const mapStateToProps = (state) => {
  return Resource.mapStateToResources(state, 'public-data');
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadResources: (url) => {
      return dispatch(readEndpoint(url))
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(PublicDataGraph);