import React from 'react';
import Config from "../../config";
import {Checkbox, FormGroup, ControlLabel, Panel} from "react-bootstrap";
import Breadcrumbs from "../../lib/breadcrumbs";
import {Link} from "react-router-dom";
import ReactHlsPlayer from 'react-hls-player';

class Showroom extends React.Component{

  constructor(props){
    super(props);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.state = {streams: [], activeStreams: []};
  }

  componentWillMount() {
    this.fetchStreams();
  }

  fetchStreams(){
    fetch(Config.hlsStreamsUri).then(response => response.json().then(streams => ({streams, response}))).then(
      ({streams, response}) => {
        if (!response.ok) {
          console.log("Unable to fetch HLS streams");
        } else {
          this.setState({streams: streams})
        }
      }).catch(err => {
      console.log(err);
    });
  }

  streamIsActive(name){
    return this.state.activeStreams.indexOf(name) !== -1;
  }

  handleCheckboxChange(ev){
    const name = ev.target.name;
    const value = ev.target.checked;
    // Create clone
    let activeStreams = [...this.state.activeStreams];
    if (value && activeStreams.indexOf(name) === -1)
      activeStreams.push(name);
    else if (!value && activeStreams.indexOf(name) !== -1)
      activeStreams = activeStreams.filter(s => s !== name);
    this.setState({activeStreams: activeStreams});
  }

  videoClass(){
    if (this.state.activeStreams.length < 5)
      return 'video-' + this.state.activeStreams.length;
    else
      return 'video-n';
  }

  render(){
    return (
      <div className="showroom-component">
        <Breadcrumbs>
          <Link to={'/public'}>Home</Link>
          Video showroom
        </Breadcrumbs>
        {this.state.streams.length === 0 &&
        <h1>No video streams online</h1>}
        {this.state.streams.length !== 0 &&
        <Panel className="available-streams">
          <Panel.Body>
          {this.state.streams.map(stream => (
            <FormGroup key={stream} controlId={stream}>
              <ControlLabel>{stream}</ControlLabel>
              <Checkbox defaultChecked={this.streamIsActive(stream)} name={stream} onChange={this.handleCheckboxChange}/>
            </FormGroup>
          ))}
          </Panel.Body>
        </Panel>}
        <div className={`active-streams ${this.videoClass()}`}>
        {this.state.activeStreams.map(stream => (
          <ReactHlsPlayer
              className="test"
              autoPlay={true}
              key={stream}
              src={Config.hlsUri + '/' + stream + '.m3u8'}
              controls={true}
          />
        ))}
        </div>
      </div>
    );
  }

}

export default Showroom;
