import React, { Component } from 'react';
import { connect } from 'react-redux';
import logo from './assets/images/logo_1.png';
import {logoutFailure, logoutRequest, logoutSuccess} from './actions';
import Config from "./config";
import { setAxiosConfig } from "redux-json-api";
import { Glyphicon } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';

class Header extends Component {

  constructor(props) {
    super(props);
    this.handleLogout = this.handleLogout.bind(this);
  }

  handleLogout(){
    this.props.logoutUser();
  }

  render(){
    const { isAuthenticated, currentUser } = this.props;
    return (
      <div className="header-component">
        <div className="logo">
          <img src={logo}  alt="SYKE"/>
        </div>
        <div className="title">
          EnviCal Manager
        </div>
        <div className="welcome">
          {isAuthenticated && <span>
            <span className="username">{currentUser && currentUser.name}</span>
            <Link to={"/account/" + currentUser.id}>Edit account <Glyphicon glyph="user"/></Link><br/>
            <Button style={{paddingRight: '0px'}} className="btn-link" onClick={this.handleLogout}>
              Logout <Glyphicon glyph="off"/>
            </Button>
          </span>}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    currentUser: state.auth.currentUser
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => {
      const config = {
        method: 'DELETE',
        headers: {
          'Accept': 'application/json',
          'Content-Type':'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('jwt')
        }
      };

      let url = Config.apiUri + '/auth/sign_out';

      dispatch(logoutRequest());
      dispatch(setAxiosConfig({
        headers: {'Authorization': ''}
      }));
      dispatch(logoutSuccess());
      fetch(url, config).then(response => {
        window.location.href = '/';
        console.log(response);
      }).catch(err => {
        console.log(err);
        dispatch(logoutFailure());
      });
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);