import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Navbar, Nav, NavItem } from 'react-bootstrap';

class Menu extends Component {

  shouldShowAdmin(){
    if (!this.props.currentUser)
      return false;
    if (this.props.currentUser.admin)
      return true;
    if (this.props.currentUser.permissions && (
      this.props.currentUser.permissions['users'].index ||
      this.props.currentUser.permissions['user-groups'].index ||
      this.props.currentUser.permissions['organizations'].index
    )) return true;
    return false;
  }

  isActive(key){
    const parts = window.document.location.pathname.split('/').filter(p => p !== '');
    return parts[0] === key;
  }

  render(){
    if (!this.props.isAuthenticated)
      return null;
    return (
      <div className="menu-component">
        <Navbar collapseOnSelect className="menu-bar">
          <Navbar.Header>
            <Navbar.Toggle />
          </Navbar.Header>
          <Navbar.Collapse>
            <Nav>
              {this.shouldShowAdmin() &&
              <NavItem active={this.isActive('admin')} href="/admin" eventKey={1}>Admin</NavItem>}
              <NavItem active={this.isActive('platforms')} href="/platforms" eventKey={2}>Instruments</NavItem>
              <NavItem active={this.isActive('data-platforms')} href="/data-platforms" eventKey={3}>Data</NavItem>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    currentUser: state.auth.currentUser
  };
};

export default connect(mapStateToProps, null)(Menu);