import React from "react";
import PropTypes from 'prop-types';
import ajaxLoader from './assets/images/ajax-loader.gif';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';

class ResourceFormFooter extends React.Component {

  defaultSubmitLabel(){
    if (this.props.resourceForm.resource())
      return "Update";
    else
      return "Create";
  }

  render() {
    const form = this.props.resourceForm;
    const cancelPath = form.redirectAfterCreate();
    const cancelLabel = "Cancel";
    const submitLabel = this.defaultSubmitLabel();
    const onSubmit = form.handleSubmit;
    return (
      <div className="resource-form-footer-component">
        <Link to={cancelPath} className="cancel-button">
          {cancelLabel}
        </Link>
        <span className="submit-button-container">
          {form.isSubmitting() &&
          <img src={ajaxLoader} alt="loading..."/>}
            &nbsp;
            <Button bsStyle="primary" onClick={onSubmit} disabled={form.isSubmitting()}>
            {submitLabel}
          </Button>
        </span>
        {form.isSubmitted() &&
        <Redirect to={form.resource() ? form.redirectAfterUpdate() : form.redirectAfterCreate()}/>}
      </div>
    )
  }

}

ResourceFormFooter.propTypes = {
  resourceForm: PropTypes.object
};

export default ResourceFormFooter;