import React from 'react';
import { withRouter } from "react-router-dom";
import ResourceForm from './resource_form';
import ResourceFormInput from "./resource_form_input";
import ResourceFormFooter from "./resource_form_footer";
import ResourceFormHeader from "./resource_form_header";

class DiaryEntryForm extends ResourceForm {
  static get apiType(){
    return 'diary-entries';
  }

  redirectAfterCreate() {
    if (this.props.match.params.platformId && this.props.match.params.instrumentId)
      return `/platforms/${this.props.match.params.platformId}/instruments/${this.props.match.params.instrumentId}#diary`;
    else
      return '/';
  }

  createRelationships() {
    let subject = {};
    if (this.props.match.params.instrumentId)
      subject.data = {type: 'instruments', id: this.props.match.params.instrumentId};
    let rel = super.createRelationships();
    rel.subject = subject;
    return rel;
  }

  render() {
    return (
      <div className="diary-entry-form-component">
        <form>
          <ResourceFormHeader resourceForm={this} label={this.resourceId() ? "Edit diary entry" : "New diary entry"}/>
          <ResourceFormInput componentClass="textarea" name="content" resourceForm={this}/>
          <ResourceFormFooter resourceForm={this}/>
        </form>
      </div>
    );
  }
}

export default withRouter(ResourceForm.connect()(DiaryEntryForm));