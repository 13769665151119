export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';
export const UNAUTHORIZED = 'UNAUTHORIZED';
export const WEBSOCKET_ERROR = 'WEBSOCKET_ERROR';
export const DISMISS_FLASH = 'DISMISS_FLASH';

export function dismissFlash(){
  return {
    type: DISMISS_FLASH
  }
}

export function webSocketError(error){
  return {
    type: WEBSOCKET_ERROR,
    error: error
  }
}

export function loginRequest(creds) {
  return {
    type: LOGIN_REQUEST
  }
}

export function loginSuccess(jwt, user) {
  return {
    type: LOGIN_SUCCESS,
    jwt: jwt,
    currentUser: user
  }
}

export function loginError(message) {
  return {
    type: LOGIN_FAILURE,
    message
  }
}

export function logoutRequest() {
  return {
    type: LOGOUT_REQUEST
  }
}

export function logoutSuccess() {
  return {
    type: LOGOUT_SUCCESS
  }
}

export function logoutFailure() {
  return {
    type: LOGOUT_FAILURE
  }
}