import React from 'react';
import PropTypes from 'prop-types';
import ResourceForm from '../../resource_form';
import { Checkbox } from 'react-bootstrap';
import _ from 'lodash';
import ResourceFormFooter from "../../resource_form_footer";
import ResourceFormDateTime from "../../resource_form_date_time";

const apiType = 'data-analysis-runs';

class DataAnalysisRunForm extends ResourceForm {
  static get apiType() {
    return apiType;
  }

  constructor(props){
    super(props);
    this.state.dataInstruments = [];
    this.handleDataInstrumentChange = this.handleDataInstrumentChange.bind(this);
  }

  analysisId(){
    return this.props && this.props.match && this.props.match.params && this.props.match.params.dataAnalysisId;
  }

  componentWillMount() {
    super.componentWillMount();
    if (this.analysisId())
      this.props.loadResources('data-analyses/' + this.analysisId() + '?include=data-platform.data-instruments');
  }

  dataPlatformId(){
    return this.props && this.props.match && this.props.match.params && this.props.match.params.dataPlatformId;
  }

  redirectAfterCreate() {
    return '/data-platforms/' + this.dataPlatformId()+ '/data-analyses/' + this.analysisId() + '#runs';
  }

  createRelationships(){
    return {
      'data-analysis': {
        data: {
          type: 'data-analyses',
          id: this.analysisId()
        }
      },
      'data-instruments': {
        data: this.state.dataInstruments.map((di) => ({type: 'data-instruments', id: di}))
      }
    };
  }

  // Extend default attributes with analysis-type specific stuff
  createAttributes(){
    let attributes = super.createAttributes();
    let keys = [];
    if (this.isType('auto_mukit')){
      keys = [
        'replicate-count', 'replicate-group-period-sec', 'estimation-interval-days', 'control-groups', 'ranges'
      ]
    } else if (this.isType('auto_mukit_dynamic')){
      keys = [
        'replicate-count', 'replicate-group-period-sec', 'estimation-interval-days', 'ranges'
      ]
    } else if (this.isType('manual_mu')){
      keys = [
        'ranges'
      ]
    }
    for (const key of keys) {
      attributes[key] = this.dataAnalysis() && this.dataAnalysis().attributes[key];
    }
    return attributes;
  }

  dataAnalysis(){
    if (this.analysisId() && this.props.resources && this.props.resources['data-analyses'])
      return(this.props.resources['data-analyses'].find(res => res.id === this.analysisId()));
    else
      return null;
  }

  dataPlatform(){
    if (!this.dataAnalysis() || !this.dataAnalysis().included || !this.dataAnalysis().included['data-platform'])
      return null;
    return this.dataAnalysis().included['data-platform'];
  }

  dataInstruments(){
    if (!this.dataPlatform() || !this.dataPlatform().included || !this.dataPlatform().included['data-instruments'])
      return [];
    return this.dataPlatform().included['data-instruments'];
  }

  isType(type){
    if (!this.dataAnalysis())
      return false;
    return this.dataAnalysis().attributes['analysis-type'] === type;
  }

  handleDataInstrumentChange(event) {
    const dataInstruments = _.cloneDeep(this.state.dataInstruments);
    const id = event.target.dataset.id;

    if (event.target.checked)
      dataInstruments.push(id);
    else {
      const index = dataInstruments.indexOf(id);
      if (index >= 0)
        dataInstruments.splice(index, 1);
    }
    this.setState({dataInstruments: dataInstruments});
  };

  render() {
    const dataInstrumentRow = (di) => {
      return (
        <tr key={di.id}>
          <td>
            <Checkbox data-id={di.id} name="instrument" onChange={this.handleDataInstrumentChange}/>
          </td>
          <td>{di.attributes.name} ({di.attributes['instrument-type']})</td>
        </tr>
      )
    };
    return (
      <div className="data-analysis-run-form-component">
        <form className="horizontal">
          <h1>New data analysis run</h1>
          <ResourceFormDateTime resourceForm={this} name="from-time"/>
          <ResourceFormDateTime resourceForm={this} name="to-time"/>
          <h3>Instruments</h3>
          <table className="data-analysis-run-instruments">
            <tbody>
            { this.dataInstruments().map((di) => dataInstrumentRow(di)) }
            </tbody>
          </table>
          <ResourceFormFooter resourceForm={this}/>
        </form>
      </div>
    );
  }
}

DataAnalysisRunForm.propTypes = {
  params: PropTypes.object
};

export default ResourceForm.connect()(DataAnalysisRunForm);
