import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveContainer, ComposedChart, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Line, Brush, Area } from 'recharts';
import DataGraphTooltip from './data_graph_tooltip';
import Time from './time';

class DataGraph extends React.Component {

  constructor(props){
    super(props);
    this.state = {brushStartIndex: null, brushEndIndex: null};
    this.brushChangeTimeoutHandle = null;
    //this.brushState = null;
  }

  lineColors(index){
    const colors = [
      "#ce5032",
      "#70ba43",
      "#5d6cd7",
      "#a256c5",
      "#bcad46",
      "#c68540",
      "#5c8fcb",
      "#c06b5e",
      "#59aa65",
      "#46b2a1",
      "#d84268",
      "#d452ad",
      "#697731",
      "#9d77c1",
      "#9f456e",
      "#de87b4",
      "#8884d8",
      "#82ca9d",
      "#a20a0d"
    ];
    return colors[index % colors.length];
  }

  fillColors(index){
    return this.lineColors(index);
  }

  resources(){
    return this.props.data;
  }

  // Returns unit if it's same for all data rows, otherwise null
  unit(){
    let unit = null;
    this.resources().every((r) => {
      if (this.props.excludeParameters.indexOf(r.attributes['parameter']) === -1){
        if (unit === null)
          unit = r.attributes['unit'];
        else if (unit !== r.attributes['unit']){
          unit = null;
          return false;
        }
      }
      return true;
    });
    return unit;
  }

  instruments(){
    if (!this.resources())
      return [];

    let ret = [];

    //this.resources().forEach((r) => {
    //  if (this.props.excludeParameters.indexOf(r.attributes['parameter']) === -1)
    //    ret.push(r.included['data-instrument']);
    //});

    this.resources().filter(r =>
      this.props.excludeParameters.indexOf(r.attributes['parameter']) === -1
    ).forEach(r => {
      const instrument = r.included['data-instrument'];
      if (!ret.find(obj => obj.id === instrument.id))
        ret.push(instrument);
    });

    //console.log(names.filter((el, i, a) => i === a.indexOf(el)));

    //return ret.filter((el, i, a) => i === a.indexOf(el));
    return ret;
  }

  precision (num) {
    let numAsStr = num.toFixed(10); //number can be presented in exponential format, avoid it
    numAsStr = numAsStr.replace(/0+$/g, '');
    return String(numAsStr).replace('.', '').length - num.toFixed().length;
  }

  muValues(resource){
    const value = parseFloat(resource.attributes['value']);
    const uValue = resource.attributes['data-uncertainty-value'];
    const unit = resource.attributes['data-uncertainty-unit'];
    const precision = this.precision(value);
    if (unit === 'percentage'){
      return [
        (value - (value * uValue * 0.005)).toFixed(precision),
        (value + (value * uValue * 0.005)).toFixed(precision)
      ];
    } else {
      return [
        (value - (uValue * 0.5)).toFixed(precision),
        (value + (uValue * 0.5)).toFixed(precision)
      ];
    }
  }

  utcDate(jsonTime){
    //console.log(k);

    let date = jsonTime.split('T')[0].split('-');
    let time = jsonTime.split('T')[1].replace('Z','').split(':');

    //console.log(date);
    //console.log(time);

    //let ts = Date.parse(k);

    const year = date[0];
    const month = parseInt(date[1], 10) - 1;
    const day = date[2];

    return Date.UTC(year, month, day, time[0], time[1], time[2].split('.')[0], time[2].split('.')[1]);
  }

  data(){
    let data = {};

    this.resources().forEach(r => {
      const key = r.attributes['sampling-time'];
      let id = r.included['data-instrument'].id;
      if (!data[key])
        data[key] = {};
      data[key][id] = r.attributes['value'];
      if (r.attributes['data-uncertainty-value'])
        data[key]['mu_' + id] = this.muValues(r);
      data[key]['unit_' + id] = r.attributes['unit'];
    });

    //console.log(data);

    let ret = Object.keys(data).map(k => {
      let tmp = {ts: this.utcDate(k)};
      this.instruments().forEach(instrument => {
        const id = instrument.id;
        tmp[id] = (data[k][id] || null);
        if (!isNaN(parseFloat(tmp[id])))
          tmp[id] = parseFloat(tmp[id]);
        if (data[k]['mu_' + id])
          tmp['mu_' + id] = data[k]['mu_' + id];
        tmp['unit_' + id] = data[k]['unit_' + id];
      });
      //return Object.assign({ts: this.utcDate(k)}, tmp);
      return tmp;
    });

    return ret.reverse();
  }

  formatDate(utcDate){
    //return new Date(utcDate).toUTCString();
    return Time.formatTime(utcDate, 'default', 'minutes');
  }

  formatBrushDate(value){
    if (this.data())
      return this.formatDate(this.data()[value]['ts']);
    else
      return '';
  }

  ticks() {
    const len = this.data() && this.data().length;
    if (!len)
      return [];
    if (len > 1) {
      const is = this.state.brushStartIndex ? this.state.brushStartIndex : 0;
      const ie = this.state.brushEndIndex ? this.state.brushEndIndex : len - 1;
      const min = this.data()[is].ts;
      const max = this.data()[ie].ts;
      let ret = []
      const iterations = 12;
      for (let i = 0; i <= iterations; i ++)
        ret.push(min + i * ((max - min) / iterations));
      //console.log(Time.formatTime(ret[0]));
      //console.log(Time.formatTime(ret[ret.length - 1]));
      return ret;
    } else
      return [this.data()[0].ts - 60000, this.data()[0].ts + 60000];
  }

  domain(){
    if (this.state.brushStartIndex === null)
      return [this.ticks()[0], this.ticks().splice(-1,1)[0]];
    else {
      console.log(this.state.brushStartIndex);
      return [this.data()[this.state.brushStartIndex].ts, this.data()[this.state.brushEndIndex].ts];
    }
  }

  onBrushChange(obj){
    if (this.brushChangeTimeoutHandle)
      window.clearTimeout(this.brushChangeTimeoutHandle);
    this.brushChangeTimeoutHandle = window.setTimeout(() => {
      //console.log(obj);
      this.setState({brushStartIndex: obj.startIndex, brushEndIndex: obj.endIndex});
    }, 250);
    //this.setState({brushStartIndex: obj.startIndex, brushEndIndex: obj.endIndex});
    //this.brushState = obj;
  }

  render() {
    return (
      <div className="data-graph-component">
        <ResponsiveContainer width='100%' height={this.props.height}>
          <ComposedChart data={this.data()}
                         margin={{top: 5, right: 30, left: 20, bottom: 5}}>
            <XAxis dataKey="ts"
                   scale="utc"
                   type="number"
                   allowDataOverflow
                   tick={{fontSize: "0.9em"}}
                   domain={this.domain()}
                   //domain={['dataMin', 'dataMax']}
                   ticks={this.ticks()}
                   minTickGap={10}
                   tickFormatter={this.formatDate}/>

            {this.unit() === null && this.instruments().map((instrument, index) => (
              <YAxis key={'axis' + instrument.id} yAxisId={'axis' + index} hide={true}/>
            ))}

            {this.unit() !== null &&
            <YAxis key={'axis'} yAxisId={'axis'} label={{value: this.unit(), angle: -90, position: 'insideLeft'}}/>}

            <CartesianGrid strokeDasharray="3 3"/>
            <Tooltip content={<DataGraphTooltip/>}/>
            {this.props.legend &&
            <Legend/>}

            {this.instruments().map((instrument, index) => (
              <Line dot={false} key={'line' + instrument.id} yAxisId={this.unit() !== null ? 'axis' : 'axis' + index} type="monotone" dataKey={instrument.id} stroke={this.lineColors(index)} name={instrument.attributes['name']}/>
            ))}

            {this.instruments().map((instrument, index) => (
              <Area key={'area' + instrument.id} yAxisId={this.unit() !== null ? 'axis' : 'axis' + index} type="monotone" dataKey={'mu_' + instrument.id} stroke="none" fill={this.fillColors(index)} legendType="none"/>
            ))}

            {this.data().length > 0 &&
            <Brush onChange={this.onBrushChange.bind(this)}
                   tickFormatter={this.formatBrushDate.bind(this)}
                   startIndex={this.state.brushStartIndex || 0}
                   endIndex={this.state.brushEndIndex || (this.data() && this.data().length - 1) || 0}
                   height={30}
                   travellerWidth={12}/>}
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    );
  }
}

DataGraph.propTypes = {
  data: PropTypes.array,
  excludeParameters: PropTypes.array,
  legend: PropTypes.bool,
  height: PropTypes.number
};

DataGraph.defaultProps = {
  excludeParameters: [],
  legend: true,
  height: 480
};

export default DataGraph;
