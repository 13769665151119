import React from 'react';
import ResourceForm from '../../resource_form';
import ResourceFormHeader from "../../resource_form_header";
import ResourceFormFooter from "../../resource_form_footer";
import ResourceFormInput from "../../resource_form_input";

class PlatformForm extends ResourceForm {
  static get apiType() {
    return 'platforms';
  }

  redirectAfterCreate() {
    return '/platforms#platform-list';
  }

  render() {
    return (
      <div className="platform-form-component">
        <form>
          <ResourceFormHeader resourceForm={this}/>
          <ResourceFormInput name="name" resourceForm={this}/>
          <ResourceFormInput name="serial-number" resourceForm={this}/>
          <ResourceFormInput name="access-key" resourceForm={this}/>
          <ResourceFormFooter resourceForm={this}/>
        </form>
      </div>
    );
  }
};

export default ResourceForm.connect()(PlatformForm);