import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

const formats = {
  default: {
    minutes: 'MMM Do YYYY, HH:mm',
    seconds: 'MMM Do YYYY, HH:mm:ss',
    milliseconds: 'MMM Do YYYY, HH:mm:ss.SSS'
  }
};

class Time extends React.Component{
  render(){
    return (
      <span className={"time-component " + (this.props.className ? this.props.className : '')}>
        {Time.formatTime(this.props.children, this.props.format, this.props.resolution)}
      </span>
    );
  }

  static _format(format, resolution){
    if (format === 'withTz')
      return formats['default'][resolution] + ' Z z';
    else
      return formats[format][resolution];
  }

  static timezone(){
    return moment.tz.guess() || 'Europe/Helsinki';
  }

  static formatTime(time, format = 'default', resolution = 'seconds'){
    if (!time)
      return '';
    return moment(time).tz(Time.timezone()).format(Time._format(format, resolution));
  }

  static formatDuration(ms){
    if (ms === null || isNaN(ms))
      return '';
    if (ms <= 1000)
      return Math.round(ms) + ' ms';
    else if (ms <= 1000 * 60)
      return (Math.round((ms / 1000) * 100) / 100) + ' sec';
    else{
      const min = Math.floor(ms / 60000);
      const sec = Math.round(ms % 60000 / 1000);
      if (sec > 0)
        return  `${min} min ${sec} sec`;
      else
        return  `${min} min`;
    }
  }
}

Time.Zone = () => {
  return <span className="time-component timezone">tz:&nbsp;{Time.timezone()}</span>;
};

Time.Duration = (props) => {
  return <span className="time-component duration">{Time.formatDuration(props.children)}</span>;
};

Time.propTypes = {
  time: PropTypes.any,
  format: PropTypes.string,
  resolution: PropTypes.string
};

export default Time;