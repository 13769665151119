import React from 'react';
import ResourceForm from '../../resource_form';
import { Checkbox } from 'react-bootstrap';
import _ from 'lodash';
import ResourceFormFooter from "../../resource_form_footer";
import ResourceFormCheckbox from "../../resource_form_checkbox";
import ResourceFormInput from "../../resource_form_input";

const apiType = 'data-analysis-timers';

class DataAnalysisTimerForm extends ResourceForm {
  static get apiType() {
    return apiType;
  }

  constructor(props){
    super(props);
    this.state.dataInstruments = {};
    this.handleDataInstrumentChange = this.handleDataInstrumentChange.bind(this);
  }

  analysisId(){
    return this.props && this.props.match && this.props.match.params && this.props.match.params.dataAnalysisId;
  }

  componentWillMount() {
    // Update or create?
    if (this.resourceId()) {
      this.props.loadResources(this.constructor.apiType + '/' + this.resourceId() + '?include=data-instruments');
    } else {
      this.setState({attributes: {active: true}});
    }
    if (this.analysisId()) {
      this.props.loadResources('data-analyses/' + this.analysisId() + '?include=data-platform.data-instruments').then(r => {
        if (!this.resourceId() && r.body && r.body.data) {
          const interval = r.body.data.attributes['estimation-interval-days'];
          this.setState({attributes: {'interval-days': interval, active: true}});
        }
      })
    }
  }

  dataPlatformId(){
    return this.props && this.props.match && this.props.match.params && this.props.match.params.dataPlatformId;
  }

  redirectAfterCreate() {
    return '/data-platforms/' + this.dataPlatformId()+ '/data-analyses/' + this.analysisId() + '#schedule';
  }

  updateRelationships() {
    return this.createRelationships();
  }

  createRelationships(){
    let instrumentIds = [];
    if (this.resource())
      instrumentIds = this.resource().included['data-instruments'].map(di => di.id);
    Object.keys(this.state.dataInstruments).forEach(id => {
      if (this.state.dataInstruments[id] && instrumentIds.indexOf(id) === -1)
        instrumentIds.push(id);
      else if (this.state.dataInstruments[id] === false && instrumentIds.indexOf(id) !== -1)
        instrumentIds.splice(instrumentIds.indexOf(id), 1);
    });
    return {
      'data-analysis': {
        data: {
          type: 'data-analyses',
          id: this.analysisId()
        }
      },
      'data-instruments': {
        data: instrumentIds.map((id) => ({type: 'data-instruments', id: id}))
      }
    };
  }

  dataAnalysis(){
    if (this.analysisId() && this.props.resources && this.props.resources['data-analyses'])
      return(this.props.resources['data-analyses'].find(res => res.id === this.analysisId()));
    else
      return null;
  }

  dataPlatform(){
    if (!this.dataAnalysis() || !this.dataAnalysis().included || !this.dataAnalysis().included['data-platform'])
      return null;
    return this.dataAnalysis().included['data-platform'];
  }

  dataInstruments(){
    if (!this.dataPlatform() || !this.dataPlatform().included || !this.dataPlatform().included['data-instruments'])
      return [];
    return this.dataPlatform().included['data-instruments'];
  }

  handleDataInstrumentChange(event) {
    const dataInstruments = _.cloneDeep(this.state.dataInstruments);
    const id = event.target.dataset.id;
    dataInstruments[id] = event.target.checked;
    this.setState({dataInstruments: dataInstruments});
  }

  instrumentIsSelected(id){
    if (this.state.dataInstruments[id] !== null && this.state.dataInstruments[id] !== undefined) {
      return this.state.dataInstruments[id];
    } else if (this.resource() && this.resource().included && this.resource().included['data-instruments']){
      const di = this.resource().included['data-instruments'].find(di => parseInt(di.id) === parseInt(id));
      return !!di;
    }
  }

  handleIntervalChange(ev) {
    const name = ev.target.name;
    const value = ev.target.value;
    const attributes = _.cloneDeep(this.state.attributes);
    attributes[name] = value !== '' ? value * 24 : '';
    this.setState({attributes: attributes});
  }

  render() {
    const dataInstrumentRow = (di) => {
      return (
        <tr key={di.id}>
          <td>
            <Checkbox checked={this.instrumentIsSelected(di.id)} data-id={di.id} name="instrument" onChange={this.handleDataInstrumentChange}/>
          </td>
          <td>{di.attributes.name} ({di.attributes['instrument-type']})</td>
        </tr>
      )
    };
    return (
      <div className="data-analysis-run-form-component">
        <form className="horizontal">
          <h1>Schedule run</h1>
          <ResourceFormCheckbox resourceForm={this} name="active"/>
          <ResourceFormInput
              resourceForm={this}
              name="interval-days"
              label="Interval (days)"
              type="number"
          />
          <h3>Instruments</h3>
          <table className="data-analysis-run-instruments">
            <tbody>
            { this.dataInstruments().map((di) => dataInstrumentRow(di)) }
            </tbody>
          </table>
          <ResourceFormFooter resourceForm={this}/>
        </form>
      </div>
    );
  }
}

export default ResourceForm.connect()(DataAnalysisTimerForm);
