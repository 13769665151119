import React from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Chat from "../../lib/chat";
import GaugeAnalog from "../../lib/gauge_analog";
import GaugeDigital from "../../lib/gauge_digital";
import Slider from "../../lib/slider";
import Relay from "../../lib/relay";
import WorldMap from "../../lib/world_map";
import Time from "../../lib/time";
import Compass from "../../lib/compass";
import {instrumentMapIcon} from "./instrument_icon";
import GForceMeter from "../../lib/g_force_meter";
import SpectrumGraph from "../../lib/spectrum_graph";

class InstrumentWidget extends React.Component {

  constructor(props){
    super(props);
    this.mapDragged = false;
    this.sendData = this.sendData.bind(this);
    this.sendDataTimer = null;
  }

  characteristic(key){
    if (this.props.instrument.attributes.characteristics &&
      this.props.instrument.attributes.characteristics[key] !== null &&
      this.props.instrument.attributes.characteristics[key] !== undefined)
      return this.props.instrument.attributes.characteristics[key];
    else
      return null;
  }

  // Types in order of presentation in monitoring
  static widgetTypes(){
    return [
      'relay',
      'slider',
      'gaugeAnalog',
      'gaugeDigital',
      'spectrumGraph',
      'compass',
      'gForce',
      'worldMap',
      'chat'
    ];
  }

  static resolveWidgetType(instrument){
    const characteristic = (key) => {
      return instrument.attributes.characteristics && instrument.attributes.characteristics[key];
    };
    if (!characteristic('type') && instrument.attributes['parameter'] === 'Coordinates')
      return 'worldMap';
    else if (characteristic('type') === 'positioning')
      return 'worldMap';
    else if (characteristic('type') === 'digital' || characteristic('type') === 'gauge_digital')
      return 'gaugeDigital';
    else if (characteristic('type') === 'analog' || characteristic('type') === 'gauge_analog')
      return 'gaugeAnalog';
    else if (characteristic('type') === 'slider')
      return 'slider';
    else if (characteristic('type') === 'relay')
      return 'relay';
    else if (characteristic('type') === 'chat')
      return 'chat';
    else if (characteristic('type') === 'compass')
      return 'compass';
    else if (characteristic('type') === 'g_force')
      return 'gForce';
    else if (characteristic('type') === 'spectrum_graph')
      return 'spectrumGraph';
    else if (!characteristic('type') && instrument.attributes['instrument-type'] === 'Relay')
      return 'relay';
    return 'gaugeAnalog';
  }

  widgetType(){
    return InstrumentWidget.resolveWidgetType(this.props.instrument);
  }

  instrumentValue(){
    return this.props.value;
  }

  instrumentBoolValue(){
    return this.props.value && parseInt(this.props.value, 10) ? true : false;
  }

  rangeMin(){
    if (this.props.instrument.included && this.props.instrument.included['instrument-alarm'])
      return this.props.instrument.included['instrument-alarm'].attributes['range-value-min'];
    else
      return null;
  }

  rangeMax(){
    if (this.props.instrument.included && this.props.instrument.included['instrument-alarm'])
      return this.props.instrument.included['instrument-alarm'].attributes['range-value-max'];
    else
      return null;
  }

  instrumentCoordinates(){
    //return {lat: 0, lng: 0};
    let value = this.instrumentValue();
    if (!value || !value.split)
      return {lat: 0, lng: 0};
    let parts = value.split(',');
    return {lat: parseFloat(parts[0]), lng: parseFloat(parts[1])};
  }

  instrumentMapMarkers(){
    let marker = Object.assign({}, this.instrumentCoordinates());
    if (this.props.instrument){
      marker.title = this.instrumentValue(this.props.instrument) + ' ' + this.props.instrument.attributes['unit'];
      marker.icon = instrumentMapIcon(this.props.instrument);
    }
    return [marker];
  }

  markerValues(){
    if (this.characteristic('value_max') !== null &&
      this.characteristic('value_steps') !== null){
      let values = [];
      const min = this.characteristic('value_min') !== null ? parseFloat(this.characteristic('value_min')) : 0;
      const max = parseFloat(this.characteristic('value_max'));
      const range = max - min;
      const steps = parseInt(this.characteristic('value_steps'));
      values.push(min);
      for (let i = 1; i < steps; i ++)
        values.push(min + i * (range / steps));
      values.push(max);
      return values;
    } else {
      return null;
    }
  }

  sendData(data){
    if (this.props.platformConnection){
      this.props.platformConnection.sendData({
        instruments: [
          {
            id: this.props.instrument.id,
            state: data
          }
        ]
      });
    }
  }

  dataTimeOk(){
    if (this.props.instrument.attributes['instrument-type'] === 'Manual positioning')
      return true;
    if (!this.props.dataTime || !this.props.avgDataInterval)
      return false;
    const dataTime = Date.parse(this.props.dataTime);
    const treshold = Math.max(this.props.avgDataInterval * 1.25, 5000);
    return Date.now() < dataTime + treshold;
  }

  render(){
    const senderName = this.props.currentUser ? this.props.currentUser.name : 'User';
    const title = () => {
      const info = [
        'Last data received at: ' + Time.formatTime(this.props.dataTime, 'withTz'),
        'Average data interval: ' + Time.formatDuration(this.props.avgDataInterval),
      ];
      return info.join("\n");
    };
    const instrument = this.props.instrument;
    if (!instrument)
      return <div className="instrument-widget-component"></div>;
    return (
      <div key={instrument.id} className="instrument-widget-component">
        <span className={"instrument-name " + (this.dataTimeOk() ? '' : 'data-expired')} title={title()}>{instrument.attributes.name}</span>
        {this.widgetType() === 'chat' &&
        <Chat senderName={senderName}
              receiverName={instrument.attributes.name}
              value={this.instrumentValue()}
              onSendMessage={this.sendData}
              dataTime={this.props.dataTime}/>}

        {this.widgetType() === 'spectrumGraph' &&
        <SpectrumGraph value={this.instrumentValue()} height={256} width={512} unit={instrument.attributes.unit} valueMin={this.characteristic('value_min')} valueMax={this.characteristic('value_max')} parameter={instrument.attributes.parameter}/>}

        {this.widgetType() === 'relay' &&
        <Relay value={this.instrumentBoolValue()} onToggle={this.sendData}/>}

        {this.widgetType() === 'compass' &&
        <Compass key={instrument.id} value={this.instrumentValue()} size={this.props.analogGaugeSize || 180}/>}

        {this.widgetType() === 'gForce' &&
        <GForceMeter key={instrument.id} value={this.instrumentValue()} size={this.props.analogGaugeSize || 180} markerValues={this.markerValues(instrument)} factor={0.001}/>}

        {this.widgetType() === 'gaugeAnalog' &&
        <GaugeAnalog key={instrument.id} unit={instrument.attributes.unit} value={this.instrumentValue()} size={this.props.analogGaugeSize || 180} markerValues={this.markerValues(instrument)} rangeMax={this.rangeMax()} rangeMin={this.rangeMin()} parameter={instrument.attributes.parameter}/>}

        {this.widgetType() === 'gaugeDigital' &&
        <GaugeDigital key={instrument.id} unit={instrument.attributes.unit} value={this.instrumentValue()} rangeMax={this.rangeMax()} rangeMin={this.rangeMin()} parameter={instrument.attributes.parameter}/>}

        {this.widgetType() === 'slider' &&
        <Slider key={instrument.id}
                onChange={this.sendData}
                unit={instrument.attributes.unit}
                value={this.instrumentValue()}
                size={256}
                markerValues={this.markerValues(instrument)}
                rangeMax={this.rangeMax()}
                rangeMin={this.rangeMin()}
                scale={parseInt(this.characteristic('value_scale'))}
                parameter={instrument.attributes.parameter}/>}

        {this.widgetType() === 'worldMap' &&
        <WorldMap markers={this.instrumentMapMarkers(instrument)} center={this.mapDragged ? undefined : this.instrumentCoordinates(instrument)} onDragStart={() => {this.mapDragged = true}}/>}
      </div>);
  }
}

InstrumentWidget.propTypes = {
  instrument: PropTypes.object,
  value: PropTypes.any,
  analogGaugeSize: PropTypes.number,
  platformConnection: PropTypes.object,
  platformIpAddress: PropTypes.string,
  dataTime: PropTypes.string,
  avgDataInterval: PropTypes.number
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.auth.currentUser
  };
};

export default connect(mapStateToProps, null)(InstrumentWidget);