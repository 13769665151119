import React from 'react';
import { Table, Button } from 'react-bootstrap';
import Breadcrumbs from "../../lib/breadcrumbs";
import ResourceList from "../../resource_list";
import ResourceListPagination from "../../resource_list_pagination";
import ResourceRow from "../../resource_row";
import {Link} from "react-router-dom";
import Time from "../../lib/time";
import Confirm from "react-confirm-bootstrap";
import ajaxLoader from "../../assets/images/ajax-loader.gif";

const apiType = 'data-platforms';

class DataPlatformList extends ResourceList {

  static get apiType(){
    return apiType;
  }

  defaultQueryParams() {
    return {
      sort: ['name']
    }
  }

  render() {
    return (
      <div className="data-platform-list-component">
        <Breadcrumbs>Data platforms</Breadcrumbs>
        <Table responsive hover>
          <thead>
          <tr>
            <th>Name</th>
            <th>Organization</th>
            <th>Data rows</th>
            <th>Last import <Time.Zone/></th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          {this.state.loading && <img src={ajaxLoader} alt="Loading..."/>}
          {this.resources().map(resource => (
            <ResourceRow key={resource.id} redirectTo={resource.canShow() && `${this.props.match.url}/${resource.id}`}>
              <td>{resource.attributes['name']}</td>
              <td>{resource.attributes['organization-name']}</td>
              <td>{resource.attributes['data-count']}</td>
              <td><Time>{resource.attributes['harvest-end-at']}</Time></td>
              <td className="actions">
                {resource.canUpdate() &&
                <Link to={`${this.props.match.url}/${resource.id}/edit`}>
                  <i className="action-icon glyphicon glyphicon-pencil"></i>
                </Link>}
                {resource.canDestroy() &&
                <Confirm
                  onConfirm={() => {this.handleResourceDelete(resource.id)}}
                  body={`Are you sure you want to delete ${resource.attributes['name']} and all its data?`}
                  confirmText="Confirm delete"
                  title="Delete data platform">
                  <Button className="btn-link">
                    <i className="action-icon glyphicon glyphicon-trash"></i>
                  </Button>
                </Confirm>}
                {resource.canShow() &&
                <i className="action-icon glyphicon glyphicon-chevron-right"></i>}
              </td>
            </ResourceRow>
          ))}
          </tbody>
        </Table>
        <ResourceListPagination resourceList={this}/>
      </div>
    );
  }
}

export default ResourceList.connect()(DataPlatformList);
