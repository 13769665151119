import React from 'react';
import PropTypes from 'prop-types';

class GForceMeter extends React.Component{

  constructor(props){
    super(props);
    this.range = Math.PI * 2;
    this._frameId = null;
    this.lastAnimationTime = null;
    this.loop = this.loop.bind(this);
    this.state = {currentVector: {x: 0, y: 0, z: 0}};
  }

  componentWillUnmount() {
    this.stopLoop();
  }

  componentWillMount() {
    this.startLoop();
  }

  componentWillReceiveProps(nextProps, nextContext){
    this.startLoop();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    //console.log(this.state);
  }

  startLoop() {
    if( this._frameId === null ) {
      this._frameId = window.requestAnimationFrame( this.loop );
    }
  }

  updatedVectorComponent(component, deltaTime, target){
    const current = this.state.currentVector;
    const speed = Math.max(Math.abs(current[component] - target[component]), 0.01);
    let d = deltaTime * 0.005 * speed;
    if (current[component] < target[component])
      return Math.min(target[component], current[component] + d);
    else if (current[component] > target[component])
      return Math.max(target[component], current[component] - d);
    else
      return target[component];
  }

  loop() {
    const now = Date.now();
    const target = this.vector();

    if (this.lastAnimationTime === null)
      this.lastAnimationTime = now;

    const d = now - this.lastAnimationTime;

    this.lastAnimationTime = now;

    const x = this.updatedVectorComponent('x', d, target);
    const y = this.updatedVectorComponent('y', d, target);
    const z = this.updatedVectorComponent('z', d, target);

    //const x = target.x;
    //const y = target.y;
    //const z = target.z;

    this.setState({currentVector: {x, y, z}});

    // Set up next iteration of the loop
    if (target.x === x &&
      target.y === y &&
      target.z === z)
      this.stopLoop();
    else
      this._frameId = window.requestAnimationFrame( this.loop );
  }

  stopLoop() {
    if (this._frameId !== null){
      window.cancelAnimationFrame( this._frameId );
      this._frameId = null;
      this.lastAnimationTime = null;
    }
    // Note: no need to worry if the loop has already been cancelled
    // cancelAnimationFrame() won't throw an error
  }

  maxValue(){
    return this.props.markerValues[this.props.markerValues.length - 1] || 1;
  }

  vector(){
    let x = 0;
    let y = 0;
    let z = 0;
    if (this.props.value && this.props.value.x)
      x = this.props.value.x * this.props.factor / this.maxValue();
    if (this.props.value && this.props.value.y)
      y = this.props.value.y * this.props.factor / this.maxValue();
    if (this.props.value && this.props.value.z)
      z = this.props.value.z * this.props.factor / this.maxValue();
    return {x, y, z};
  }

  xyPos(){
    const cx = this.state.currentVector.x * this.xySize() * 0.5 + this.xyCenter().cx;
    const cy = this.state.currentVector.y * -1 * this.xySize() * 0.5 + this.xyCenter().cy;
    return {cx, cy};
  }

  zPos(){
    const cx = this.zCenter().cx;
    const cy = this.state.currentVector.z * -1 * this.zSize() * 0.5 + this.zCenter().cy;
    return {cx, cy};
  }

  roundedValue(v){
    if (v === null || isNaN(parseFloat(v)))
      return 0;
    return Math.ceil(v * 100) / 100;
  }

  xyCenter(){
    return {cx: this.props.size * 0.5, cy: this.props.size * 0.45};
  }

  zCenter(){
    return {cx: this.props.size * 1.15, cy: this.props.size * 0.45};
  }

  xySize(){
    return this.props.size  * 0.75;
  }

  zSize(){
    return this.props.size  * 0.75;
  }

  xyLines(){
    return [
      {
        x1: this.xyCenter().cx - this.xySize() * 0.5,
        x2: this.xyCenter().cx + this.xySize() * 0.5,
        y1: this.xyCenter().cy,
        y2: this.xyCenter().cy,
      },
      {
        x1: this.xyCenter().cx,
        x2: this.xyCenter().cx,
        y1: this.xyCenter().cy - this.xySize() * 0.5,
        y2: this.xyCenter().cy + this.xySize() * 0.5,
      }
    ];
  }

  xyTexts(){
    let texts = [];
    this.props.markerValues.filter(v => v !== 0).forEach(v => {
      const r = this.xySize() * 0.5 * (v / this.maxValue()) - 2;
      texts.push({
        x: this.xyCenter().cx + Math.cos(-Math.PI * 0.25) * r,
        y: this.xyCenter().cy + Math.sin(-Math.PI * 0.25) * r + this.props.size * 0.05,
        textAnchor: 'end',
        text: this.roundedValue(v)
      });
      texts.push({
        x: this.xyCenter().cx + Math.cos(Math.PI * 0.75) * r,
        y: this.xyCenter().cy + Math.sin(Math.PI * 0.75) * r,
        textAnchor: 'start',
        text: '-' + this.roundedValue(v)
      });
    });
    return texts;
  }

  xyLabel(){
    let label = [];
    if (this.props.value && this.props.value.x !== null && this.props.value.x !== undefined)
      label.push("X: " + this.roundedValue(this.props.value.x * this.props.factor) + 'g');
    else
      label.push("X: -");
    if (this.props.value && this.props.value.y !== null && this.props.value.y !== undefined)
      label.push("Y: " + this.roundedValue(this.props.value.y * this.props.factor) + 'g');
    else
      label.push("Y: -");
    return label.join(' ');
  }

  zLabel(){
    if (this.props.value && this.props.value.z !== null && this.props.value.z !== undefined)
      return "Z: " + this.roundedValue(this.props.value.z * this.props.factor) + "g";
    else
      return "Z: -";
  }

  zLine(){
    return {
      x1: this.zCenter().cx,
      y1: this.zCenter().cy - this.zSize() * 0.5,
      x2: this.zCenter().cx,
      y2: this.zCenter().cy + this.zSize() * 0.5
    };
  }

  zMarkerLines(){
    let markers = [];
    this.props.markerValues.forEach(v => {
      markers.push({
        x1: this.zCenter().cx,
        y1: this.zCenter().cy + this.zSize() * 0.5 * (v / this.maxValue()),
        x2: this.zCenter().cx + this.props.size * 0.09,
        y2: this.zCenter().cy + this.zSize() * 0.5 * (v / this.maxValue())
      });
      if (v > 0){
        markers.push({
          x1: this.zCenter().cx,
          y1: this.zCenter().cy - this.zSize() * 0.5 * (v / this.maxValue()),
          x2: this.zCenter().cx + this.props.size * 0.09,
          y2: this.zCenter().cy - this.zSize() * 0.5 * (v / this.maxValue())
        });
      }
    });
    return markers;
  }

  zTexts(){
    let texts = [];
    this.props.markerValues.forEach(v => {
      if (v > 0){
        texts.push({
          x: this.zCenter().cx + this.props.size * 0.11,
          y: this.zCenter().cy + this.zSize() * 0.5 * (v / this.maxValue()) + this.props.size * 0.015,
          text: '-' + this.roundedValue(v)
        });
      }
      texts.push({
        x: this.zCenter().cx + this.props.size * 0.11,
        y: this.zCenter().cy - this.zSize() * 0.5 * (v / this.maxValue()) + this.props.size * 0.015,
        text: this.roundedValue(v)
      });
    });
    return texts;
  }

  render(){
    return (
      <svg height={this.props.size} width={this.props.size * 1.5}>
        <circle {...this.xyCenter()} r={this.xySize() * 0.5} stroke="#666" strokeWidth="2" fill="white" />
        {this.props.markerValues.map(v => (
          <circle key={"circle" + v} {...this.xyCenter()} r={this.xySize() * 0.5 * (v / this.maxValue())} stroke="#aaa" strokeWidth="1" fill="transparent"/>
        ))}
        {this.xyLines().map((line, index) => (
          <line key={"xyLine" + index} {...line} stroke="#aaa" strokeWidth="1"/>
        ))}
        {this.xyTexts().map((text, index) => (
          <text key={"xyText" + index} x={text.x} y={text.y} textAnchor={text.textAnchor} fill="#aaa" fontSize={this.props.size * 0.05}>{text.text}</text>
        ))}
        <circle {...this.xyPos()} r={this.props.size * 0.04} stroke="black" strokeWidth="1" fill="#f00" />
        <text x={this.xyCenter().cx} y={this.props.size * 0.95} textAnchor="middle" fill="#aaa" fontSize={this.props.size * 0.06}>
          {this.xyLabel()}
        </text>
        <line key="z-axel" stroke="#666" strokeWidth="2" {...this.zLine()}/>
        {this.zMarkerLines().map((line, index) => (
          <line key={"zMarkerLine" + index} {...line} stroke="#aaa" strokeWidth="1"/>
        ))}
        {this.zTexts().map((text, index) => (
          <text key={"zText" + index} x={text.x} y={text.y} textAnchor="start" fill="#aaa" fontSize={this.props.size * 0.05}>{text.text}</text>
        ))}
        <circle {...this.zPos()} r={this.props.size * 0.04} stroke="black" strokeWidth="1" fill="#f00" />
        <text x={this.zCenter().cx} y={this.props.size * 0.95} textAnchor="middle" fill="#aaa" fontSize={this.props.size * 0.06}>
          {this.zLabel()}
        </text>
      </svg>
    )
  }
}

GForceMeter.propTypes = {
  size: PropTypes.number,
  markerValues: PropTypes.array,
  value: PropTypes.any,
  factor: PropTypes.number
};

export default GForceMeter;