import React from 'react';
import WorldMap from "../../lib/world_map";
import Time from '../../lib/time';
import Resource from "../../lib/resource";
import {readEndpoint} from "redux-json-api";
import connect from "react-redux/es/connect/connect";
import PropTypes from "prop-types";
import PublicDataHeader from "./public_data_header";
import _ from 'lodash';

class PublicDataPlot extends React.Component {

  constructor(props) {
    super(props);
    this.state = {dataIds: []};
  }

  url(){
    const filter = Object.assign({'data-instrument.id': this.ids().join(',')}, this.props.filter);
    const params = Object.keys(filter).map(key => `filter[${key}]=${encodeURIComponent(filter[key])}`).join('&');
    const size = Object.keys(this.props.filter).length > 0 ? this.props.maxPageSize : this.props.defaultPageSize;
    return `public-data?include=data-instrument&${params}&page[size]=${size}&sort=-sampling-time`;
  }

  instrument(){
    return this.props.dataInstrument;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!_.isEqual(prevProps.filter, this.props.filter))
      this.loadResources();
  }

  componentWillMount() {
    this.loadResources();
  }

  loadResources(){
    this.props.loadResources(this.url()).then((response) => {
      if (response && response.body && response.body.data)
        this.setState({dataIds: response.body.data.map(entity => entity.id)});
    });
  }

  data(){
    if (!this.props.resources || !this.props.resources['public-data'])
      return [];
    return this.state.dataIds.map(id => this.props.resources['public-data'].find(r => r.id === id))
      .filter(r => r && r.attributes.value && r.attributes.value !== '');
  }

  markers(){
    let markers = [];
    let titles = {};
    this.data().forEach(resource => {
      const formattedTime = Time.formatTime(resource.attributes['sampling-time'], 'withTz', 'milliseconds');
      if (resource.attributes.parameter === 'Coordinates'){
        const parts = resource.attributes.value.split(',');
        const lat = parseFloat(parts[0]);
        const lng = parseFloat(parts[1]);
        if (isNaN(lat) || isNaN(lng))
          return;
        markers.push({
          time: formattedTime,
          lat: lat,
          lng: lng,
          title: 'Time: ' + formattedTime + "\n"
        });
      }
      const id = formattedTime;
      if (!titles[id])
        titles[id] = {};
      titles[id][resource.attributes['instrument-name']] = resource.attributes['value'] + ' '  + resource.attributes['unit'];
    });
    // Set titles
    markers.forEach(marker => {
      const id = marker.time;
      if (titles[id])
        marker.title += Object.keys(titles[id]).map(key => key + ': ' + titles[id][key]).join("\n");
    });
    return markers;
  }

  paths(){
    const path = this.markers().map(marker => ({lat: marker['lat'], lng: marker['lng']}));
    return {path: path};
  }

  ids(){
    return [this.props.dataInstrument.id].concat(this.props.groupInstruments.map(gi => gi.id));
  }

  render() {
    return (
      <div className="public-data-plot-component">
        <PublicDataHeader dataInstrument={this.instrument()} filter={this.props.filter} defaultPageSize={this.props.defaultPageSize} maxPageSize={this.props.maxPageSize}/>
        <WorldMap markers={this.markers()} paths={this.paths()} fitBounds={true}/>
      </div>
    );
  }
}

PublicDataPlot.propTypes = {
  dataInstrument: PropTypes.object,
  groupInstruments: PropTypes.array,
  filter: PropTypes.object,
  defaultPageSize: PropTypes.number,
  maxPageSize: PropTypes.number
};

const mapStateToProps = (state) => {
  return Resource.mapStateToResources(state, 'public-data');
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadResources: (url) => {
      return dispatch(readEndpoint(url))
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(PublicDataPlot);