import React from 'react';
import ResourceForm from '../../resource_form';
import { withRouter } from 'react-router-dom';
import { updateResource, createResource, deleteResource } from 'redux-json-api';
import ResourceFormFooter from "../../resource_form_footer";
import ResourceFormHeader from "../../resource_form_header";
import ResourceFormInput from "../../resource_form_input";
import StepFormInstruments from "./step_form_instruments";

class StepForm extends ResourceForm {
  static get apiType() {
    return 'steps';
  }

  constructor(props){
    super(props);
    this.state.stepInstruments = [];
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillMount() {
    // Update or create?
    if (this.resourceId())
      this.props.loadResources(this.constructor.apiType + '/' + this.resourceId() + '?include=step-instruments.instrument');
  }

  platformId(){
    return this.props.match && this.props.match.params.platformId;
  }

  redirectAfterCreate(){
    return `/platforms/${this.platformId()}#steps`;
  }

  stepInstruments(){
    let ri = (this.resource() && this.resource().included && this.resource().included['step-instruments']) || [];
    ri = ri.filter(i => !this.state.stepInstruments.find(si => si.id === i.id));
    return ri.concat(this.state.stepInstruments);
  }

  createRelationships(){
    return {platform: {data: {type: 'platforms', id: this.platformId()}}};
  }

  isSubmitted(){
    return false;
  }

  handleSubmit(ev){
    let self = this;
    super.handleSubmit(ev).then(function(response){
      if (!response.id)
        return;
      Promise.all(self.state.stepInstruments.map((si) => {return self.props.saveStepInstrument(si, response.id)})).then(
        () => self.props.history.push(self.redirectAfterCreate())
      );
    })
  }

  render() {
    return (
      <div className="step-form-component">
        <form className="horizontal">
          <ResourceFormHeader resourceForm={this}/>
          <ResourceFormInput resourceForm={this} name="name"/>
          <ResourceFormInput resourceForm={this} name="duration" label="Duration (s)"/>
          <ResourceFormInput resourceForm={this} name="sampling-rate" label="Sampling rate (ms)"/>
          <StepFormInstruments resourceForm={this} platformId={this.platformId()} stepInstruments={this.stepInstruments()}/>
          <ResourceFormFooter resourceForm={this}/>
        </form>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    saveStepInstrument: (si, stepId) => {
      const relationships = {
        step: {
          data: {
            type: 'steps',
            id: stepId
          }
        },
        instrument: {
          data: {
            type: 'instruments',
            id: si.included.instrument.id
          }
        }
      };
      let attributes = {
        'state': (si.attributes['state'] !== '' && si.attributes['state']) || 0,
        'store-data-mode': si.attributes['store-data-mode'] || null,
        'control-group': si.attributes['control-group']
      };
      if (si.id[0] === '_' && si.attributes.control){
        // CREATE
        let data = {
          type: 'step-instruments',
          attributes: attributes,
          relationships: relationships
        };
        return dispatch(createResource(data));
      } else if (si.id[0] !== '_'){
        if (si.attributes.control || si.attributes.control === undefined){
          // UPDATE
          let data = {
            type: 'step-instruments',
            attributes: attributes,
            id: si.id,
            relationships: relationships
          };
          return dispatch(updateResource(data));
        } else {
          // DESTROY
          let data = {
            type: 'step-instruments',
            id: si.id,
          };
          return dispatch(deleteResource(data));
        }
      }
    }
  };
};

export default withRouter(ResourceForm.connect(null, mapDispatchToProps)(StepForm));