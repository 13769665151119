import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { Table, Button } from 'react-bootstrap';
import ResourceList from '../../resource_list';
import Confirm from 'react-confirm-bootstrap';
import ResourceListPagination from "../../resource_list_pagination";
import ResourceRow from "../../resource_row";
import NewResourceLink from "../../lib/new_resource_link";
import Time from "../../lib/time";

const apiType = 'data-analyses';

class DataAnalysisList extends ResourceList {

  static get apiType(){
    return apiType;
  }

  defaultQueryParams() {
    return {
      filter: {
        'data-platform.id': this.props.dataPlatformId,
      },
      sort: ['name']
    }
  }

  render() {
    return (
      <div className="data-analysis-list-component">
        <NewResourceLink to={`${this.props.match.url}/${apiType}/new`}
                         disabled={!this.props.canCreate}>
          New analysis
        </NewResourceLink>
        <Table responsive hover>
          <thead>
          <tr>
            <th>Name</th>
            <th>Type</th>
            <th>Created at (updated at)</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          {this.resources().map(resource => (
            <ResourceRow key={resource.id} disabled={!resource.canShow()} redirectTo={resource.canShow() && `${this.props.match.url}/${apiType}/${resource.id}`}>
              <td>{resource.attributes['name']}</td>
              <td>{resource.attributes['analysis-type']}</td>
              <td>
                {Time.formatTime(resource.attributes['created-at'], 'default', 'minutes')}
                {resource.attributes['updated-at'] !== resource.attributes['created-at'] ? ` (${Time.formatTime(resource.attributes['updated-at'], 'default', 'minutes')})` : ''}
              </td>
              <td className="actions">
                {resource.canUpdate() &&
                <Link to={`${this.props.match.url}/${apiType}/${resource.id}/edit`}>
                  <i className="action-icon glyphicon glyphicon-pencil"></i>
                </Link>}
                {resource.canDestroy() &&
                <Confirm
                  onConfirm={() => {this.handleResourceDelete(resource.id)}}
                  body={`Are you sure you want to delete ${resource.attributes['name']}?`}
                  confirmText="Confirm delete"
                  title="Delete data analysis">
                  <Button className="btn-link">
                    <i className="action-icon glyphicon glyphicon-trash"></i>
                  </Button>
                </Confirm>}
                {resource.canShow() &&
                <i className="action-icon glyphicon glyphicon-chevron-right"></i>}
              </td>
            </ResourceRow>
          ))}
          </tbody>
        </Table>
        <ResourceListPagination resourceList={this}/>
      </div>
    );
  }
}

DataAnalysisList.propTypes = {
  dataPlatformId: PropTypes.string,
  canCreate: PropTypes.bool
};

export default withRouter(ResourceList.connect()(DataAnalysisList));
