import React from 'react';
import { Route, Switch } from "react-router-dom";
import PublicDataOrganizations from './public_data_organizations';
import PublicDataInstruments from './public_data_instruments';
import Entry from './entry';
import Showroom from "./showroom";
import VideoArchive from "./video_archive";

class Public extends React.Component{
  render() {
    return (
      <div className="public-component">
        <Switch>
          <Route exact path={`${this.props.match.url}`} component={Entry}/>
          <Route path={`${this.props.match.url}/data/:organization/:group`} component={PublicDataInstruments}/>
          <Route path={`${this.props.match.url}/data`} component={PublicDataOrganizations}/>
          <Route path={`${this.props.match.url}/showroom`} component={Showroom}/>
          <Route path={`${this.props.match.url}/videos`} component={VideoArchive}/>
        </Switch>
      </div>
    );
  }
}

export default Public;
