import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Config from '../../config';

class Camera extends Component{

  constructor(props){
    super(props);
    this.state = {error: false};
  }

  url(){
    const ip = this.props.platformIpAddress;
    const id = this.props.platformId;
    const jwt = localStorage.getItem('jwt');
    if (!ip || !id || !jwt)
      return null;
    return `${Config.videoProxyUri}?ip=${ip}&platformId=${id}&jwt=${jwt}`;
  }

  handleError(){
    this.setState({error: true});
  }

  render(){
    if (this.url() && !this.state.error){
      return (
        <div className="camera-component">
          <a href={this.url()} target='_blank' rel="noopener noreferrer">
            <img src={this.url()} alt="Video stream" onError={this.handleError.bind(this)}/>
          </a>
        </div>
      );
    } else {
      return "";
    }
  }
}

Camera.propTypes = {
  platformIpAddress: PropTypes.string,
  platformId: PropTypes.number
};

export default Camera;