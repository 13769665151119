import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

class ResourceRow extends Component {
  constructor(props){
    super(props);
    this.state = {clicked: false};
  }

  handleClick(event){
    if (!this.props.redirectTo)
      return;
    if (event.target.nodeName.toLowerCase() === 'a')
      return;
    else if (event.target.nodeName.toLowerCase() === 'button')
      return;
    else if (event.target.parentElement.nodeName.toLowerCase() === 'a')
      return;
    else if (event.target.parentElement.nodeName.toLowerCase() === 'button')
      return;
    this.setState({clicked: true});
  }

  render(){
    return (
      <tr className={`resource-row-component ${this.props.redirectTo && 'clickable'} ${this.props.className}`} onClick={this.handleClick.bind(this)}>
        {this.props.children}
        {this.state.clicked &&
        <Redirect to={this.props.redirectTo}/>}
      </tr>
    )
  }
}

export default ResourceRow;