import React from "react";
import PropTypes from "prop-types";
import {Button, Col, FormControl, Glyphicon, Row} from "react-bootstrap";
import _ from 'lodash';
import {Typeahead} from "react-bootstrap-typeahead";

class RuleFormConsequences extends React.Component {
  constructor(props){
    super(props);
    this.addConsequence = this.addConsequence.bind(this);
    this.onChangeConsequence = this.onChangeConsequence.bind(this);
  }

  form(){
    return this.props.resourceForm;
  }

  consequences(){
    return this.form().resolveValue('consequences') || [];
  }

  addConsequence(event){
    let attributes = _.cloneDeep(this.form().state.attributes);
    let consequences = _.cloneDeep(this.form().resolveValue('consequences')) || [];
    consequences.push({type: 'continue'});
    attributes['consequences'] = consequences;
    this.form().setState({attributes});
  }

  removeConsequence(index){
    let attributes = _.cloneDeep(this.form().state.attributes);
    let consequences = _.cloneDeep(this.form().resolveValue('consequences')) || [];
    consequences.splice(index, 1);
    attributes['consequences'] = consequences;
    this.form().setState({attributes});
  }

  sequenceSteps(sequenceName = null){
    if (sequenceName)
      return this.props.sequenceSteps.filter(r => r.included.sequence.attributes.name === sequenceName);
    else
      return this.props.sequenceSteps;
  }

  sequenceNames(){
    const tmp = this.sequenceSteps().find(ss => ss.included.sequence.id === this.props.sequenceId);
    let seqName = null;
    if (tmp)
      seqName = tmp.included.sequence.attributes.name;
    let names = this.sequenceSteps().map(ss => ss.included.sequence.attributes.active && ss.included.sequence.attributes.name).filter((el, i, a) =>
      (el && i === a.indexOf(el) && el !== seqName)
    );
    if (seqName)
      return [seqName, ...names];
    else
      return names;
  }

  consequenceTypes(){
    return {
      'continue': 'Continue',
      'goto_step': 'Goto step',
      'add_counter': 'Add counter',
      'set_counter': 'Set counter'
    };
  }

  onChangeConsequence(ev, index){
    const name = ev.target.name;
    const value = ev.target.value;
    let attributes = _.cloneDeep(this.form().state.attributes);
    let consequences = _.cloneDeep(this.form().resolveValue('consequences')) || [];
    consequences[index][name] = value;
    attributes['consequences'] = consequences;
    this.form().setState({attributes});
  }

  handleCounterNameTypeaheadChange(values, index){
    if (Array.isArray(values)){
      values = values.map(v => {
        if (v && v.customOption)
          return v.label;
        else
          return v;
      });
      if (values.length > 0)
        values = values[0];
      else
        return;
    }
    this.onChangeConsequence({target: {name: 'name', value: values}}, index);
  }

  selectedCounterName(consequence){
    if (!consequence.name)
      return [];
    return [consequence.name];
  }

  render(){
    const consequence = (consequence, index) => {
      return (
        <Row key={"consequence" + index} className="consequence">
          <Col md={4}>
            <FormControl componentClass="select" name="type" value={consequence.type} onChange={(ev) => this.onChangeConsequence(ev, index)}>
              {Object.keys(this.consequenceTypes()).map(type => (
                <option key={type} value={type}>{this.consequenceTypes()[type] || type}</option>
              ))}
            </FormControl>
          </Col>
          {consequence.type === 'continue' &&
          <Col md={8}>
            <Button onClick={() => this.removeConsequence(index)}>
              <Glyphicon glyph="trash"/>
            </Button>
          </Col>}
          {consequence.type === 'goto_step' &&
          <React.Fragment>
            <Col md={4}>
              <FormControl componentClass="select" name="sequence_step_id" value={consequence.sequence_step_id} onChange={(ev) => this.onChangeConsequence(ev, index)}>
                <option value={null}>- select step -</option>
                {this.sequenceNames().map(seq => (
                  <optgroup key={seq} label={seq}>
                    {this.sequenceSteps(seq).map((ss, index) => (
                      <option key={ss.id} value={ss.id}>{index + 1} - {ss.included.step.attributes.name}</option>
                    ))}
                  </optgroup>
                ))}
              </FormControl>
            </Col>
            <Col md={4}>
              <Button onClick={() => this.removeConsequence(index)}>
                <Glyphicon glyph="trash"/>
              </Button>
            </Col>
          </React.Fragment>}
          {(consequence.type === 'add_counter' || consequence.type === 'set_counter') &&
          <React.Fragment>
            <Col md={4}>
              <Typeahead onInputChange={(values) => this.handleCounterNameTypeaheadChange(values, index)}
                         onChange={(values) => this.handleCounterNameTypeaheadChange(values, index)}
                         selected={this.selectedCounterName(consequence)}
                         id={consequence.id + 'ahead'}
                         allowNew={true}
                         placeholder="counter name"
                         options={this.props.counters}/>
            </Col>
            <Col md={2}>
              <FormControl name="value" value={consequence.value} onChange={(ev) => this.onChangeConsequence(ev, index)} placeholder="value"/>
            </Col>
            <Col md={2}>
              <Button onClick={() => this.removeConsequence(index)}>
                <Glyphicon glyph="trash"/>
              </Button>
            </Col>
          </React.Fragment>}
        </Row>
      )
    };
    return (
      <div className="rule-form-consequences-component">
        {this.consequences().map((con, index) => (
          consequence(con, index)
        ))}
        <Button onClick={this.addConsequence}>
          <Glyphicon glyph="plus"/>
          Add consequence
        </Button>
      </div>
    );
  }
}

RuleFormConsequences.propTypes = {
  resourceForm: PropTypes.object,
  sequenceSteps: PropTypes.array,
  sequenceId: PropTypes.string
};

export default RuleFormConsequences;
