import React from 'react';
import { Button } from 'react-bootstrap';
import CopyToClipboard from "react-copy-to-clipboard";
import PropTypes from "prop-types";
import Config from "../../config";

class PublicDataHeader extends React.Component{
  onCopy(){
    alert("URL copied to the clipboard!");
  }

  instrument(){
    return this.props.dataInstrument || {attributes: {}}
  }

  url(){
    const filter = Object.assign({'data-instrument.id': this.instrument().id}, this.props.filter);
    const params = Object.keys(filter).map(key => `filter[${key}]=${encodeURIComponent(filter[key])}`).join('&');
    const size = Object.keys(this.props.filter).length > 0 ? this.props.maxPageSize : this.props.defaultPageSize;
    return Config.jsonApiUri + encodeURI(`public-data?${params}&page[size]=${size}&sort=-sampling-time`);
  }

  render(){
    return (
      <div className="public-data-header-component">
        <h3>
          {this.instrument().attributes['public-name'] || this.instrument().attributes['name']}
          <CopyToClipboard text={this.url()} onCopy={this.onCopy}>
            <Button title={'Click to copy JSON API link to the clipboard: ' + this.url()} className="btn-link url-link">
              <i className="glyphicon glyphicon-link"></i>
            </Button>
          </CopyToClipboard>
        </h3>
        {this.instrument().attributes['description'] &&
        <p>{this.instrument().attributes['description']}</p>}
      </div>
    );
  }
}

PublicDataHeader.propTypes = {
  dataInstrument: PropTypes.object,
  filter: PropTypes.object,
  maxPageSize: PropTypes.number,
  defaultPageSize: PropTypes.number
};

export default PublicDataHeader;
