import React from "react";
import { readEndpoint } from "redux-json-api";
import Resource from "./lib/resource";
import {connect} from "react-redux";

class ResourceDetails extends React.Component {
  static get apiType() {
    // Override to set JSON API endpoint
    return 'overrideMePlease';
  }

  componentWillMount() {
    if (this.resourceId())
      this.props.loadResources(this.constructor.apiType + '/' + this.resourceId());
  }

  resourceId(){
    return this.props.match && this.props.match.params && this.props.match.params.id;
  }

  resource(){
    if (this.resourceId() && this.props.resources && this.props.resources[this.constructor.apiType])
      return(this.props.resources[this.constructor.apiType].find((res) => { return res.id === this.resourceId() }));
    else
      return null;
  }

  handleTabSelect(tab){
    window.history.pushState(null, null, `?#${tab}`);
  }

  resolveTab(defaultTab){
    const hashParts = window.location.hash.split('#');
    if (hashParts.length > 1)
      return hashParts.slice(-1)[0];
    else
      return defaultTab;
  }

  static mapDispatchToProps = (dispatch) => {
    return {
      loadResources: (url) => {
        dispatch(readEndpoint(url))
      }
    }
  };

  static mapStateToProps = (state) => {
    let props = Resource.mapStateToResources(state);
    props.currentUser = state.auth.currentUser;
    return props;
  };

  static connect(stateToProps = null, dispatchToProps = null){
    let mapState = null;
    let mapDispatch = null;
    if (!stateToProps)
      mapState = ResourceDetails.mapStateToProps;
    else
      mapState = (dispatch) => {
        let props = ResourceDetails.mapStateToProps(dispatch);
        return Object.assign(props, stateToProps(dispatch));
      };
    if (!dispatchToProps)
      mapDispatch = ResourceDetails.mapDispatchToProps;
    else
      mapDispatch = (dispatch) => {
        let props = ResourceDetails.mapDispatchToProps(dispatch);
        return Object.assign(props, dispatchToProps(dispatch));
      };
    return connect(mapState, mapDispatch);
  }
}

export default ResourceDetails;