import React from "react";
import PropTypes from 'prop-types';

class ResourceFormHeader extends React.Component {

  resolveLabel(){
    const form = this.props.resourceForm;
    const res = form.constructor.apiType.replace(/s+$/, "").replace('-', ' ');
    if (form.resource())
      return "Edit " + res;
    else
      return "New " + res;
  }

  render() {
    const label = this.props.label || this.resolveLabel();
    return (
      <div className="resource-form-header-component">
        <h1>{label}</h1>
      </div>
    )
  }

}

ResourceFormHeader.propTypes = {
  resourceForm: PropTypes.object,
  label: PropTypes.string
};

export default ResourceFormHeader;