import React from 'react';
import PropTypes from 'prop-types';
import {FormGroup, ControlLabel, Panel, Button, Checkbox} from 'react-bootstrap';
import DateTime from 'react-datetime';
import _ from 'lodash';
import moment from 'moment';

class PublicDataFilter extends React.Component {

  constructor(props) {
    super(props);
    this.state = {showFilter: Object.keys(this.props.filterParams).length > 0};
    this.state.filterParams = _.cloneDeep(this.props.filterParams);
  }

  handleTimeFilter(key, momentTime){
    let filter = _.cloneDeep(this.state.filterParams);
    if (typeof momentTime === "object")
      filter[key] = momentTime.format();
    else
      delete(filter[key]);
    this.setState({filterParams: filter});
  }

  handleSampledAfterChange(momentTime) {
    this.handleTimeFilter('sampled-after', momentTime);
  }

  handleSampledBeforeChange(momentTime) {
    this.handleTimeFilter('sampled-before', momentTime);
  }

  resolveDefaultFilterValue(key, value){
    if (this.state.filterParams && this.state.filterParams[key]) {
      if (key === 'data-instrument.id')
        return this.state.filterParams[key].indexOf(value) !== -1;
      else if (key === 'sampled-after' || key === 'sampled-before') {
        return moment(this.state.filterParams[key]).format('MM/DD/YYYY HH:mm');
      } else
        return this.state.filterParams[key];
    } else
      return '';
  }

  handleInstrumentChange(ev){
    this.handleCheckboxChange(ev, 'data-instrument.id');
  }

  handleCheckboxChange(ev, key){
    const id = ev.target.value;
    let filter = _.cloneDeep(this.state.filterParams);
    if (!filter[key])
      filter[key] = [];
    if (ev.target.checked)
      filter[key].push(id);
    else
      filter[key] = filter[key].filter(did => {
        if (isNaN(id))
          return did !== id;
        else
          return parseInt(did, 10) !== parseInt(id, 10);
      });
    if (filter[key].length === 0)
      delete(filter[key]);
    this.setState({filterParams: filter});
  }

  applyChanges(){
    this.props.onChange(this.state.filterParams);
  }

  render() {
    return (
      <div className="public-data-filter-component">
        <Button className="data-filter-toggle" onClick={() => this.setState({ showFilter: !this.state.showFilter })}>
          {!this.state.showFilter &&
          <span><i className="glyphicon glyphicon-filter"></i> Filter</span>}
          {this.state.showFilter &&
          <span><i className="glyphicon glyphicon-remove"></i> Hide filter</span>}
        </Button>
        <Panel onToggle={()=>(null)} expanded={this.state.showFilter} className={this.state.showFilter ? "" : "non-expanded"}>
          <Panel.Collapse>
            <Panel.Body>
              <FormGroup controlId="sampled-range">
                <ControlLabel>Sampled after</ControlLabel>
                <DateTime name="sampled-after" onChange={this.handleSampledAfterChange.bind(this)} defaultValue={this.resolveDefaultFilterValue("sampled-after")} timeFormat="HH:mm"/>
                <ControlLabel>Sampled before</ControlLabel>
                <DateTime name="sampled-before" onChange={this.handleSampledBeforeChange.bind(this)} defaultValue={this.resolveDefaultFilterValue("sampled-before")} timeFormat="HH:mm"/>
              </FormGroup>
              <FormGroup controlId="data-instrument.id">
                <ControlLabel>Instruments</ControlLabel>
                {this.props.dataInstruments.map(instrument => {
                  return (<Checkbox checked={this.resolveDefaultFilterValue('data-instrument.id', instrument.id)} onChange={this.handleInstrumentChange.bind(this)} key={instrument.id} value={instrument.id}>
                    {instrument.attributes['public-name'] || instrument.attributes['name']}
                  </Checkbox>);
                })}
              </FormGroup>
              <div>
                <Button bsStyle="primary" onClick={this.applyChanges.bind(this)}>Apply filter</Button>
              </div>
            </Panel.Body>
          </Panel.Collapse>
        </Panel>
      </div>
    );
  }
}

PublicDataFilter.propTypes = {
  filterParams: PropTypes.object,
  dataInstruments: PropTypes.array,
  onChange: PropTypes.func
};

PublicDataFilter.defaultProps = {
  excludeParameters: []
};

export default PublicDataFilter;