import React from 'react';
import { Route, Switch } from "react-router-dom";
import PlatformEntry from "./platform_entry";
import PlatformDetails from "./platform_details";
import PlatformForm from "./platform_form";
import InstrumentForm from "./instrument_form";
import InstrumentDetails from "./instrument_details";
import InstrumentAlarmForm from "./instrument_alarm_form";
import StepForm from "./step_form";
import SequenceForm from "./sequence_form";
import SequenceStepList from "./sequence_step_list";
import SequenceStepForm from "./sequence_step_form";
import DiaryEntryForm from "../../diary_entry_form";
import RuleList from "./rule_list";
import RuleForm from "./rule_form";

class Platforms extends React.Component{
  render() {
    return (
      <div className="platforms-component">
        <Switch>
          <Route exact path={`${this.props.match.url}`} component={PlatformEntry}/>
          <Route path={`${this.props.match.url}/:platformId/instruments/:instrumentId/alarms/:id/edit`} component={InstrumentAlarmForm}/>
          <Route path={`${this.props.match.url}/:platformId/instruments/:instrumentId/alarms/new`} component={InstrumentAlarmForm}/>
          <Route path={`${this.props.match.url}/:platformId/instruments/:instrumentId/diary/:id/edit`} component={DiaryEntryForm}/>
          <Route path={`${this.props.match.url}/:platformId/instruments/:instrumentId/diary/new`} component={DiaryEntryForm}/>
          <Route path={`${this.props.match.url}/:platformId/instruments/:id/edit`} component={InstrumentForm}/>
          <Route path={`${this.props.match.url}/:platformId/instruments/new`} component={InstrumentForm}/>
          <Route path={`${this.props.match.url}/:platformId/instruments/:id`} component={InstrumentDetails}/>
          <Route path={`${this.props.match.url}/:platformId/steps/:id/edit`} component={StepForm}/>
          <Route path={`${this.props.match.url}/:platformId/steps/new`} component={StepForm}/>
          <Route path={`${this.props.match.url}/:platformId/sequences/:sequenceId/steps/:sequenceStepId/rules/:id/edit`} component={RuleForm}/>
          <Route path={`${this.props.match.url}/:platformId/sequences/:sequenceId/steps/:sequenceStepId/rules/new`} component={RuleForm}/>
          <Route path={`${this.props.match.url}/:platformId/sequences/:sequenceId/steps/:sequenceStepId/rules`} component={RuleList}/>
          <Route path={`${this.props.match.url}/:platformId/sequences/:sequenceId/steps/:id/edit`} component={SequenceStepForm}/>
          <Route path={`${this.props.match.url}/:platformId/sequences/:sequenceId/steps/new`} component={SequenceStepForm}/>
          <Route path={`${this.props.match.url}/:platformId/sequences/:sequenceId/steps`} component={SequenceStepList}/>
          <Route path={`${this.props.match.url}/:platformId/sequences/:id/edit`} component={SequenceForm}/>
          <Route path={`${this.props.match.url}/:platformId/sequences/new`} component={SequenceForm}/>
          <Route path={`${this.props.match.url}/:id/edit`} component={PlatformForm}/>
          <Route path={`${this.props.match.url}/new`} component={PlatformForm}/>
          <Route path={`${this.props.match.url}/:id`} component={PlatformDetails}/>
        </Switch>
      </div>
    );
  }
}

export default Platforms;