import React from "react";
import PropTypes from 'prop-types';
import { FormGroup, ControlLabel, Checkbox, HelpBlock } from 'react-bootstrap';

class ResourceFormCheckbox extends React.Component {

  resolveLabel(){
    if (this.props.label)
      return this.props.label;
    return (this.props.name.charAt(0).toUpperCase() + this.props.name.slice(1)).replace('-',' ');
  }

  render() {
    const form = this.props.resourceForm;
    const name = this.props.name;
    return (
      <FormGroup className="resource-form-checkbox-component" controlId={name} validationState={form.resolveError(name) ? 'error' : null}>
        <ControlLabel>{this.resolveLabel()}</ControlLabel>
        <Checkbox defaultChecked={form.resolveValue(name)} name={name} onChange={form.handleCheckboxChange} disabled={this.props.disabled}/>
        {form.resolveError(name) &&
        <HelpBlock>{form.resolveError(name).title}</HelpBlock>}
      </FormGroup>
    )
  }

}

ResourceFormCheckbox.propTypes = {
  resourceForm: PropTypes.object,
  name: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool
};

export default ResourceFormCheckbox;