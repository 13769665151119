import React from 'react';
import { Link } from 'react-router-dom';
import Breadcrumbs from "../../lib/breadcrumbs";
import ResourceList from "../../resource_list";
import PublicDataGraph from "./public_data_graph";
import PublicDataPlot from "./public_data_plot";
import PublicDataFilter from "./public_data_filter";
import _ from 'lodash';

class PublicDataInstruments extends ResourceList {
  static get apiType(){
    return 'public-data-platforms';
  }

  defaultQueryParams() {
    return {
      filter: {'organization-name': this.organizationName()},
      include: ['data-instruments'],
      sort: ['name'],
      pageSize: 1000
    }
  }

  constructor(props){
    super(props);
    this.state.filter = {};
    this.defaultPageSize = 100;
    this.maxPageSize = 500;
  }

  organizationName(){
    return decodeURIComponent(this.props.match.params.organization);
  }

  publicGroup(){
    return decodeURIComponent(this.props.match.params.group);
  }

  instruments(){
    return this.resources().map(r => r.included['data-instruments']).flat()
      .filter(i => i.attributes['public-group'] === this.publicGroup())
      .sort((a, b) => (a.attributes['public-index-number'] || 0) - (b.attributes['public-index-number'] || 0));
  }

  groupInstruments(instrument){
    return this.instruments().filter(i => (
      i.attributes['instrument-group'] === instrument.attributes['instrument-group'] &&
        instrument.id !== i.id
    ));
  }

  onFilterChange(filter){
    this.setState({filter});
  }

  instrumentIsVisible(instrument){
    if (!this.state.filter ||
      !this.state.filter['data-instrument.id'] ||
      this.state.filter['data-instrument.id'].length === 0)
      return true;
    return this.state.filter['data-instrument.id'].indexOf(instrument.id) !== -1;
  }

  render() {
    const visualizeData = (instrument) => {
      return(
        <div key={"instrument-data-" + instrument.id}>
          {instrument.attributes['parameter'] !== 'Coordinates' &&
          <PublicDataGraph dataInstrument={instrument}
                           filter={_.omit(this.state.filter, 'data-instrument.id')}
                           defaultPageSize={this.defaultPageSize}
                           maxPageSize={this.maxPageSize}/>}
          {instrument.attributes['parameter'] === 'Coordinates' &&
          <PublicDataPlot dataInstrument={instrument}
                          groupInstruments={this.groupInstruments(instrument)}
                          filter={_.omit(this.state.filter, 'data-instrument.id')}
                          defaultPageSize={this.defaultPageSize}
                          maxPageSize={this.maxPageSize}/>}
        </div>
      )
    };
    return (
      <div className="public-data-instruments-component">
        <Breadcrumbs>
          <Link to={'/public'}>Home</Link>
          <Link to={'/public/data'}>Public data</Link>
          {this.organizationName()}
          {this.publicGroup()}
        </Breadcrumbs>
        <PublicDataFilter dataInstruments={this.instruments()}
                          filterParams={this.state.filter}
                          onChange={this.onFilterChange.bind(this)}/>
        {this.instruments().filter(i => this.instrumentIsVisible(i)).map(i => visualizeData(i))}
      </div>
    );
  }
}

export default ResourceList.connect()(PublicDataInstruments);