import React from 'react';
import PropTypes from 'prop-types';
import Confirm from 'react-confirm-bootstrap';

class OrderableTiles extends React.Component{
  constructor(props){
    super(props);
    this.handleDragOver = this.handleDragOver.bind(this);
    this.handleDragStart = this.handleDragStart.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
    this.cancelReorder = this.cancelReorder.bind(this);
    this.confirmReorder = this.confirmReorder.bind(this);
    this.handleDragEnd = this.handleDragEnd.bind(this);
    this.tilesContainer = null;
    this.dragTile = null;
    this.newIndexNumber = null;
    this.state = {confirmReorder: false};
  }

  componentDidMount() {
    this.initDrag();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.removeListeners();
    this.initDrag();
  }

  componentWillUnmount() {
    this.removeListeners();
  }

  removeListeners(){
    if (!this.tilesContainer)
      return;
    const elems = this.tilesContainer.getElementsByClassName('tile');
    for(let i = 0; i < elems.length; i ++) {
      const tile = elems[i];
      tile.removeEventListener('dragstart', this.handleDragStart);
      tile.removeEventListener('dragend', this.handleDragEnd);
      tile.removeEventListener('dragover', this.handleDragOver);
      tile.removeEventListener('drop', this.handleDrop);
    }
  }

  initDrag(){
    if (!this.tilesContainer)
      return;
    const elems = this.tilesContainer.getElementsByClassName('tile');
    for(let i = 0; i < elems.length; i ++){
      const tile = elems[i];
      tile.setAttribute('draggable', true);
      tile.addEventListener('dragstart', this.handleDragStart);
      tile.addEventListener('dragend', this.handleDragEnd);
      tile.addEventListener('dragover', this.handleDragOver);
      tile.addEventListener('drop', this.handleDrop);
    }
  }

  handleDragOver(event){
    const elems = this.tilesContainer.getElementsByClassName('tile');
    for(let i = 0; i < elems.length; i ++){
      const t = elems[i];
      if (t === event.currentTarget && this.dragTile !== t)
        t.classList.add('drag-target');
      else
        t.classList.remove('drag-target');
    }
    event.preventDefault();
  }

  handleDragStart(event){
    event.dataTransfer.setData('text/plain', 'Must set something for Mozilla');
    this.dragTile = event.currentTarget;
    this.dragTile.classList.add('dragging');
  }

  handleDrop(event){
    event.preventDefault();
    const targetId = event.currentTarget.getAttribute('data-id');
    const targetIndex = event.currentTarget.getAttribute('data-index-number');
    const sourceId = this.dragTile.getAttribute('data-id');
    if (targetId === sourceId) {
      return;
    } else if (targetId){
      this.newIndexNumber = targetIndex;
      this.setState({confirmReorder: true});
    }
  }

  handleDragEnd(){
    const elems = this.tilesContainer.getElementsByClassName('tile');
    for(let i = 0; i < elems.length; i ++){
      const t = elems[i];
      t.classList.remove('dragging');
      t.classList.remove('drag-target');
    }
  }

  cancelReorder(){
    this.setState({confirmReorder: false});
  }

  confirmReorder(){
    this.props.onConfirmReorder(this.dragTile.getAttribute('data-id'), this.newIndexNumber);
    this.setState({confirmReorder: false});
  }

  render(){
    return (
      <div className="orderable-tiles-component" ref={(comp) => this.tilesContainer = comp}>
        {this.props.children}
        {this.state.confirmReorder &&
        <Confirm visible={true}
                 body={`Are you sure to change the order?`}
                 title="Confirm new order"
                 confirmBSStyle="primary"
                 onConfirm={this.confirmReorder}
                 onClose={this.cancelReorder}
                 confirmText="Reorder">
          <span></span>
        </Confirm>}
      </div>
    );
  }
}

OrderableTiles.propTypes = {
  onConfirmReorder: PropTypes.func
};

export default OrderableTiles;