import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { Table, Button } from 'react-bootstrap';
import ResourceList from "../../resource_list";
import Confirm from 'react-confirm-bootstrap';
import Time from '../../lib/time';
import ResourceListPagination from "../../resource_list_pagination"
import NewResourceLink from "../../lib/new_resource_link"

const apiType = 'data-analysis-timers';

class DataAnalysisTimerList extends ResourceList {

  static get apiType(){
    return apiType;
  }

  analysisId(){
    return this.props && this.props.dataAnalysisId;
  }

  defaultQueryParams() {
    return {
      filter: {
        'data-analysis.id': this.analysisId()
      },
      sort: ['-created-at'],
      include: ['data-instruments']
    }
  }

  render() {
    return (
      <div className="data-analysis-timer-list-component">
        <NewResourceLink to={`${this.props.match.url}/${apiType}/new`}
                         disabled={!this.props.canCreate}>
          Schedule run
        </NewResourceLink>
        <Table responsive hover>
          <thead>
          <tr>
            <th>Interval (days)</th>
            <th>Instruments</th>
            <th>Last run at <Time.Zone/></th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          {this.resources().map(resource => (
            <tr key={resource.id} className={resource.attributes.active ? '' : 'inactive'}>
              <td>{resource.attributes['interval-days']}</td>
              <td>{resource.included && resource.included['data-instruments'] && resource.included['data-instruments'].map(i => i.attributes.name).join(', ')}</td>
              <td><Time>{resource.attributes['last-run-at']}</Time></td>
              <td className="actions">
                <Link to={`${this.props.match.url}/data-analysis-timers/${resource.id}/edit`}>
                  <i className="action-icon glyphicon glyphicon-pencil"></i>
                </Link>
                <Confirm
                  onConfirm={() => {this.handleResourceDelete(resource.id)}}
                  body={`Are you sure you want to delete scheduled run?`}
                  confirmText="Confirm delete"
                  title="Delete data analysis run">
                  <Button className="btn-link">
                    <i className="action-icon glyphicon glyphicon-trash"></i>
                  </Button>
                </Confirm>
              </td>
            </tr>
          ))}
          </tbody>
        </Table>
        <ResourceListPagination resourceList={this}/>
      </div>
    );
  }
}

DataAnalysisTimerList.propTypes = {
  dataPlatformId: PropTypes.string,
  dataAnalysisId: PropTypes.string
};

export default withRouter(ResourceList.connect()(DataAnalysisTimerList));
