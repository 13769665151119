import React from "react";
import PropTypes from 'prop-types';
import { FormGroup, ControlLabel, FormControl, Table, Button } from 'react-bootstrap';
import { connect } from "react-redux";
import Resource from "../../lib/resource";
import { readEndpoint } from 'redux-json-api';
import _  from 'lodash';

class UserFormRoles extends React.Component {

  constructor(props){
    super(props);
    this.addRole = this.addRole.bind(this);
    this.removeRole = this.removeRole.bind(this);
    this.state = { userGroupIds: [] };
  }

  componentWillMount() {
    this.props.loadResources('user-groups?include=organization').then((response) => {
      if (response && response.body && response.body.data)
        this.setState({userGroupIds: response.body.data.map(entity => entity.id)});
    });
  }

  addRole(ev){
    const form = this.props.resourceForm;
    const id = '_' + form.state.roles.length;
    const userGroup = this.userGroups().find((ug) => {return parseInt(ug.id, 10) === parseInt(ev.target.value, 10)});
    if (! userGroup)
      return;
    const role = {
      id: id,
      roleType: 'basic',
      userGroupName: userGroup.included.organization.attributes.name + ' - ' + userGroup.attributes.name,
      userGroupId: userGroup.id
    };
    const roles = form.state.roles.concat([role]);
    form.setState({roles: roles});
  }

  removeRole(ev){
    const form = this.props.resourceForm;
    let id = ev.target.dataset.id;

    // Not temp id? Set to be removed from db
    if (id[0] !== '_' && form.state.removedRoles.indexOf(id) ===  -1){
      form.setState({removedRoles: form.state.removedRoles.concat([id])});
    }

    form.setState({roles: form.state.roles.filter((role) => role.id.toString() !== id.toString())});
  }

  roles(){
    return this.props.roles || [];
  }

  userGroups(){
    if (this.props.resources && this.props.resources['user-groups'])
      return this.props.resources['user-groups'].filter((ug) => {
        return (! this.props.roles.some(r => r.userGroupId === ug.id) && this.state.userGroupIds.indexOf(ug.id) !== -1);
      });
    else
      return [];
  }

  userGroupsByOrganization(){
    let organizations = [];
    this.userGroups().forEach((ug) => {
      const orgName = ug.included.organization.attributes.name;
      if (organizations.indexOf(orgName) === -1)
        organizations.push(orgName);
    });
    let ret = {};
    organizations.forEach((org) => {
      ret[org] = this.userGroups().filter(ug => ug.included.organization.attributes.name === org);
    });
    return ret;
  }

  handleRoleTypeChange(ev, role){
    const form = this.props.resourceForm;
    let roles = _.cloneDeep(this.props.roles);
    roles.forEach((r) => {
      if (r.id.toString() === role.id.toString())
        r.roleType = ev.target.value;
    });
    form.setState({roles});
  }

  render() {
    const roleRow = (role, i) => {
      return (
        <tr key={"role-row-" + role.id} data-key={role.id}>
          <td>{role.userGroupName}</td>
          <td>
            <FormControl componentClass="select" value={role.roleType} onChange={(ev)=>{this.handleRoleTypeChange(ev, role)}}>
              <option value="basic">Basic</option>
              <option value="group_admin">Group admin</option>
              <option value="organization_admin">Organization admin</option>
            </FormControl>
          </td>
          <td>
            <Button onClick={this.removeRole} data-id={role.id} className="btn-link">
              <i data-id={role.id} className="glyphicon glyphicon-trash"></i>
            </Button>
          </td>
        </tr>
      )
    };

    return (
      <div className="user-form-roles-component">
        <FormGroup controlId="roles">
          <ControlLabel>Add role to group</ControlLabel>
          <FormControl value="" componentClass="select" name="step" onChange={this.addRole}>
            <option value="">- select group -</option>
            {Object.keys(this.userGroupsByOrganization()).map((org) => (
              <optgroup key={org} label={org}>
                {this.userGroupsByOrganization()[org].map((obj) => (
                  <option key={"user-group-" + obj.id} value={obj.id}>{obj.attributes.name}</option>
                ))}
              </optgroup>
            ))}
          </FormControl>
        </FormGroup>
        <h3>Roles</h3>
        { this.roles().length === 0 &&
          <p>- no roles -</p>
        }
        { this.roles().length > 0 &&
          <Table className="roles">
            <tbody>
            { this.roles().map((role, i) => roleRow(role, i + 1)) }
            </tbody>
          </Table>
        }
      </div>
    )
  }

}

UserFormRoles.propTypes = {
  resourceForm: PropTypes.object,
  roles: PropTypes.array
};

const mapStateToProps = (state) => {
  return Resource.mapStateToResources(state, 'user-groups');
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadResources: (url) => {
      return dispatch(readEndpoint(url))
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserFormRoles);