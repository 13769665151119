import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {Button, Checkbox, ControlLabel, FormControl, FormGroup} from 'react-bootstrap';
import Time from './time';

class Chat extends Component{

  constructor(props){
    super(props);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.state = {messagingEnabled: true, message: "", sendOnEnter: true};
    this.lastDataTime = null;
    this.messages = [];
    this.messagesContainer = null;
    this.messageInput = null;
    this.lastScrollTop = 0;
    this.userDrag = false;
  }

  componentDidMount() {
    this.messagesContainer.addEventListener('scroll', this.handleScroll , true);
  }

  componentWillUnmount() {
    this.messagesContainer.removeEventListener('scroll', this.handleScroll);
  }

  componentWillMount(){
    if (this.lastDataTime !== this.props.dataTime){
      this.messages.push({message: this.props.value, type: 'received', time: this.props.dataTime});
      this.lastDataTime = this.props.dataTime;
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.lastDataTime !== nextProps.dataTime){
      this.messages.push({message: nextProps.value, type: 'received', time: nextProps.dataTime});
      if (this.messages.length > this.props.messagesLimit)
        this.messages.shift();
      this.lastDataTime = nextProps.dataTime;
      this.scroll();
    }
  }

  scroll(){
    if (this.messagesContainer && !this.userDrag)
      setTimeout(() => this.messagesContainer.scrollTo(0, this.messagesContainer.scrollHeight), 100);
  }

  sendMessage(){
    this.setState({messagingEnabled: false});
    setTimeout(()=>{
      this.setState({messagingEnabled: true});
      if (this.messageInput)
        this.messageInput.focus();
    }, 250);
    this.props.onSendMessage(this.state.message);
    this.messages.push({message: this.state.message, type: 'sent', time: new Date().toISOString()});
    if (this.messages.length > this.props.messagesLimit)
      this.messages.shift();
    this.userDrag = false;
    this.scroll();
    this.setState({message: ""});
  }

  handleKeyPress(event){
    if (event.key === "Enter" && this.state.sendOnEnter){
      this.sendMessage();
    }
  }

  handleChange(event){
    this.setState({message: event.target.value});
  }

  handleCheckboxChange(){
    this.setState({sendOnEnter: !this.state.sendOnEnter});
  }

  handleScroll(event){
    const scroll = event.target.scrollTop;
    if (scroll < this.lastScrollTop){
      this.userDrag = true;
    } else if (scroll > event.target.scrollHeight - 500)
      this.userDrag = false;
    this.lastScrollTop = scroll;
  }

  formattedMessage(message){
    if (Array.isArray(message) || typeof message === 'object')
      return JSON.stringify(message);
    else
      return message;
  }

  render(){
    return (
      <div className="chat-component">
        <div className="messages" ref={comp => this.messagesContainer = comp}>
          {this.messages.map(msg => (
            <div className={msg.type} key={msg.time}>
              <div className="icon"></div>
              <div className="bubble">
                {msg.type === 'sent' &&
                <span className="sender">{this.props.senderName}<span className="time"><Time resolution="milliseconds">{msg.time}</Time></span></span>}
                {msg.type === 'received' &&
                <span className="sender">{this.props.receiverName}<span className="time"><Time resolution="milliseconds">{msg.time}</Time></span></span>}
                <span className="message">{this.formattedMessage(msg.message)}</span>
              </div>
            </div>
          ))}
        </div>
        <FormGroup className="message-input">
          <ControlLabel>Write message</ControlLabel>
          <FormControl inputRef={comp => this.messageInput = comp} value={this.state.message} componentClass="textarea" name="message" onChange={this.handleChange} onKeyPress={this.handleKeyPress} disabled={!this.state.messagingEnabled}/>
          <Button bsStyle="primary" onClick={this.sendMessage} disabled={!this.state.messagingEnabled}>Send</Button>
          <Checkbox defaultChecked={this.state.sendOnEnter} name="send-on-enter" onChange={this.handleCheckboxChange}/>
          <ControlLabel className="send-on-enter">Send on enter</ControlLabel>
        </FormGroup>
      </div>
    );
  }
}

Chat.propTypes = {
  senderName: PropTypes.string,
  receiverName: PropTypes.string,
  value: PropTypes.any,
  dataTime: PropTypes.string,
  onSendMessage: PropTypes.func,
  messagesLimit: PropTypes.number
};

Chat.defaultProps = {
  senderName: "Sender",
  receiverName: "Receiver",
  messagesLimit: 100
};

export default Chat;
