import React from "react";
import PropTypes from 'prop-types';
import { FormGroup, ControlLabel, FormControl, HelpBlock } from 'react-bootstrap';

class ResourceFormInput extends React.Component {

  constructor(props){
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  resolveLabel(){
    if (this.props.label)
      return this.props.label;
    return (this.props.name.charAt(0).toUpperCase() + this.props.name.slice(1)).replace('-',' ');
  }

  handleChange(ev){
    if (this.props.onChange)
      this.props.onChange(ev);
    else
      this.props.resourceForm.handleInputChange(ev);
  }

  value(){
    if (this.props.value !== undefined)
      return this.props.value;
    else
      return this.props.resourceForm.resolveValue(this.props.name);
  }

  render() {
    const form = this.props.resourceForm;
    const name = this.props.name;
    return (
      <FormGroup className="resource-form-input-component" controlId={name} validationState={form.resolveError(name) ? 'error' : null}>
        <ControlLabel>{this.resolveLabel()}</ControlLabel>
        <FormControl componentClass={this.props.componentClass} type={this.props.type} name={name} value={this.value()} onChange={this.handleChange} disabled={this.props.disabled} placeholder={this.props.placeholder}/>
        {form.resolveError(name) &&
        <HelpBlock>{form.resolveError(name).title}</HelpBlock>
        }
      </FormGroup>
    )
  }

}

ResourceFormInput.propTypes = {
  resourceForm: PropTypes.object,
  name: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  componentClass: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.any
};

ResourceFormInput.defaultProps = {
  type: 'text',
  disabled: false,
  placeholder: null,
  componentClass: 'input'
};


export default ResourceFormInput;