import React from 'react'
import { Tabs, Tab } from 'react-bootstrap';
import Breadcrumbs from '../../lib/breadcrumbs';
import UserList from './user_list';
import OrganizationList from './organization_list';
import UserGroupList from './user_group_list';
import InstrumentPresetList from './instrument_preset_list';
import { connect } from "react-redux";
import ChangeLog from "../../change_log";
import MediaAccountList from "./media_account_list"

class AdminTabs extends React.Component {
  resolveTab(){
    const hashParts = window.location.hash.split('#');
    if (hashParts.length > 1)
      return hashParts.slice(-1)[0];
    else
      return 'users';
  }

  handleTabSelect(tab){
    window.history.pushState(null, null, '?#' + tab);
  }

  shouldShowTab(resourceName){
    return (this.props.currentUser &&
      this.props.currentUser.permissions &&
      this.props.currentUser.permissions[resourceName] &&
      this.props.currentUser.permissions[resourceName].index);
  }

  render() {
    return (
      <div className="admin-tabs-component">
        <Breadcrumbs>Admin</Breadcrumbs>
        <Tabs defaultActiveKey={this.resolveTab()} id="admin-tabs" onSelect={this.handleTabSelect} mountOnEnter={true} unmountOnExit={true}>
          {this.shouldShowTab('organizations') &&
          <Tab eventKey='organizations' title="Organizations">
            <OrganizationList></OrganizationList>
          </Tab>}
          {this.shouldShowTab('user-groups') &&
          <Tab eventKey='user-groups' title="User groups">
            <UserGroupList></UserGroupList>
          </Tab>}
          {this.shouldShowTab('users') &&
          <Tab eventKey='users' title="Users">
            <UserList></UserList>
          </Tab>}
          {this.shouldShowTab('instrument-presets') &&
          <Tab eventKey='instrument-presets' title="Instrument presets">
            <InstrumentPresetList></InstrumentPresetList>
          </Tab>}
          {this.shouldShowTab('media-accounts') &&
          <Tab eventKey='media-accounts' title="Media accounts">
            <MediaAccountList></MediaAccountList>
          </Tab>}
          {this.props.currentUser.admin &&
          <Tab eventKey='change-log' title="Change log">
            <ChangeLog platformId={null}></ChangeLog>
          </Tab>}
        </Tabs>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.auth.currentUser
  };
};

export default connect(mapStateToProps, null)(AdminTabs);