import React from "react";
import PropTypes from 'prop-types';
import { FormGroup, ControlLabel, HelpBlock } from 'react-bootstrap';
import DateTime from "react-datetime";
import _ from 'lodash';
import Time from './lib/time';

class ResourceFormDateTime extends React.Component {

  resolveLabel(){
    return this.props.label ?
      this.props.label :
      (this.props.name.charAt(0).toUpperCase() + this.props.name.slice(1)).replace('-',' ');
  }

  handleChange(momentTime){
    let attributes = _.cloneDeep(this.props.resourceForm.state.attributes);
    if (momentTime === '') {
      attributes[this.props.name] = '';
    } else if (typeof momentTime === "object") {
      attributes[this.props.name] = momentTime.format();
    } else {
      delete(attributes[this.props.name]);
    }
    this.props.resourceForm.setState({attributes});
  }

  render() {
    const form = this.props.resourceForm;
    const name = this.props.name;
    const value = Time.formatTime(form.resolveValue(name));
    return (
      <FormGroup
        className="resource-form-date-time-component"
        controlId={name}
        validationState={form.resolveError(name) ? 'error' : null}
      >
        <ControlLabel>{this.resolveLabel()}</ControlLabel>
        <DateTime
          name={name}
          onChange={this.handleChange.bind(this)}
          value={value}
          timeFormat="HH:mm:ss"
          dateFormat="MMM Do YYYY,"
        />
        {form.resolveError(name) &&
        <HelpBlock>{form.resolveError(name).title}</HelpBlock>}
      </FormGroup>
    )
  }

}

ResourceFormDateTime.propTypes = {
  resourceForm: PropTypes.object,
  name: PropTypes.string,
  label: PropTypes.string
};

export default ResourceFormDateTime;
