const protocol = window.location.protocol || 'http:';

const apiUri = process.env.NODE_ENV === 'production' ? `${protocol}//api.ecm-syke.fi` : `${protocol}//localhost:3000`;

const wsUri = protocol === 'https:' ? 'wss://api.ecm-syke.fi:13377' : 'ws://api.ecm-syke.fi:1337';
const videoUri = protocol === 'https:' ? 'https://api.ecm-syke.fi:36677' : 'http://api.ecm-syke.fi:3667';
const hlsUri = protocol === 'https:' ? 'https://www.ecm-syke.fi:8001' : 'http://www.ecm-syke.fi:8000';

const Config = {
  apiUri: apiUri,
  webSocketUri: process.env.NODE_ENV === 'production' ? wsUri : 'ws://localhost:3666',
  videoProxyUri: process.env.NODE_ENV === 'production' ? videoUri : 'http://localhost:3667',
  jsonApiUri: apiUri + '/api/v1/',
  hlsUri: `${hlsUri}/hls`,
  hlsStreamsUri: `${hlsUri}/streams.php`,
  videoArchiveUri: `${hlsUri}/mp4`,
  videoArchiveListUri: `${hlsUri}/videos.php`,
  videoDestroyUri: `${hlsUri}/destroy_video.php`
};

export default Config;
