import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import PlatformList from './platform_list';
import PlatformMap from './platform_map';
import Breadcrumbs from '../../lib/breadcrumbs';
import { withRouter } from "react-router-dom";

class PlatformEntry extends React.Component {
  constructor(props){
    super(props);
    this.handleTabSelect = this.handleTabSelect.bind(this);
  }

  resolveTab(){
    const hashParts = window.location.hash.split('#');
    if (hashParts.length > 1)
      return hashParts.slice(-1)[0];
    else
      return 'platform-map';
  }

  handleTabSelect(tab){
    // Empty the query (filter and current page etc.) when tab is changed
    let location = window.location;
    location.hash = tab;
    location.search = '';
    this.props.history.push(location);
  }

  render() {
    return (
      <div className="platform-entry-component">
        <Breadcrumbs>Instrument platforms</Breadcrumbs>
        <Tabs defaultActiveKey={this.resolveTab()} id="platform-entry-tabs" onSelect={this.handleTabSelect} mountOnEnter={true} unmountOnExit={true}>
          <Tab eventKey='platform-map' title="Map">
            <PlatformMap/>
          </Tab>
          <Tab eventKey='platform-list' title="List">
            <PlatformList/>
          </Tab>
        </Tabs>
      </div>
    );
  }
}

export default withRouter(PlatformEntry);