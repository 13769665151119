import React, { Component } from 'react';

class Breadcrumbs extends Component {
  render(){
    return (
      <div className='breadcrumbs-component'>
        {React.Children.map(this.props.children, (child, i) => {
          if (i < React.Children.count(this.props.children) - 1)
            return [child, <i className="glyphicon glyphicon-chevron-right"></i>];
          else
            return child;
        })}
      </div>
    )
  }
}

export default Breadcrumbs;