import React from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Breadcrumbs from '../../lib/breadcrumbs';
import { Row, Col, Panel } from 'react-bootstrap';
import _ from 'lodash';
import ResourceDetails from "../../resource_details";
import Time from '../../lib/time';

class DataAnalysisRunDetails extends ResourceDetails {
  static get apiType(){
    return 'data-analysis-runs';
  }

  componentWillMount() {
    if (this.resourceId())
      this.props.loadResources(this.constructor.apiType + '/' + this.resourceId() + '?include=data-analysis.data-platform,data-analysis.data-analysis-root-instruments,data-instruments,data-analysis-results')
  }

  dataInstruments(){
    if (this.resource() && this.resource().included && this.resource().included['data-instruments'])
      return this.resource().included['data-instruments'];
    else
      return [];
  }

  dataAnalysisResults(){
    if (this.resource() && this.resource().included && this.resource().included['data-analysis-results'])
      return this.resource().included['data-analysis-results'];
    else
      return [];
  }

  dataAnalysis(){
    if (this.resource() && this.resource().included && this.resource().included['data-analysis'])
      return this.resource().included['data-analysis'];
    else
      return null;
  }

  dataPlatform(){
    if (this.dataAnalysis() && this.dataAnalysis().included && this.dataAnalysis().included['data-platform'])
      return this.dataAnalysis().included['data-platform'];
    else
      return null;
  }

  formatResultKey(key){
    switch(key) {
      case 'range':
        return 'Range';
      case 'errors':
        return 'Errors';
      case 'fromTime':
        return 'Samples from';
      case 'toTime':
        return 'Samples to';
      case 'uRw':
        return 'Within-laboratory reproducibility, u(Rw)';
      case 'ub':
        return 'Method and laboratory bias, u(bias)';
      case 'uncertainty':
        return 'Combined standard uncertainty, uc';
      case 'expandedUncertainty':
        return 'Expanded uncertainty, U';
      default:
        return key;
    }
  }

  formatResultValue(key, resultData){
    const value = resultData[key];
    switch(key) {
      case 'fromTime':
      case 'toTime':
        return Time.formatTime(value, 'withTz');
      case 'uRw':
      case 'ub':
      case 'uncertainty':
      case 'expandedUncertainty':
        return `${value} ${resultData['range']['mode'] === 'relative' ? '%' : ''}`;
      default:
        return _.isObject(value) ?
          (Array.isArray(value) ? value.join(', ') : JSON.stringify(value, null, 2)) :
          value;
    }
  }

  render() {
    const resultPanelStyle = (type) => {
      switch(type){
        case 'fail':
          return 'danger';
        case 'info':
          return 'info';
        case 'success':
          return 'success';
        default:
          return 'default';
      }
    };
    const Settings = (settings) => {
      return Object.keys(settings).map(k => (
          <div key={k}>
            <span>
              {k}
              :&nbsp;
              {`${settings[k]} ${k === 'uncertainty' && settings['uncertainty_type'] === 'relative' ? '%' : ''}`}
            </span>
          </div>
      ));
    };
    const Results = (r) => {
      return (
        <Panel key={r.id} header={r.attributes['result-type']} bsStyle={resultPanelStyle(r.attributes['result-type'])}>
          <Panel.Heading>
            {r.attributes['result-type']}
          </Panel.Heading>
          <Panel.Body>
            {_.isObject(r.attributes['result-data']) && Object.keys(r.attributes['result-data']).map(k => (
              <dl key={r.id + k}>
                <dt>{this.formatResultKey(k)}:&nbsp;</dt>
                <dd>{this.formatResultValue(k, r.attributes['result-data'])}</dd>
              </dl>
            ))}
            {!_.isObject(r.attributes['result-data']) &&
              r.attributes['result-data']}
          </Panel.Body>
        </Panel>
      );
    };
    return (
      <div className="data-analysis-run-details-component">
        <Breadcrumbs>
          <Link to="/data-platforms">Data platforms</Link>
          <Link to={"/data-platforms/" + (this.dataPlatform() ? this.dataPlatform().id : '')}>
            {this.dataPlatform() && this.dataPlatform().attributes.name}
          </Link>
          <Link to={"/data-platforms/" + (this.dataPlatform() ? this.dataPlatform().id : '') + "#data-analyses"}>
            Data analyses
          </Link>
          <Link to={"/data-platforms/" + (this.dataPlatform() ? this.dataPlatform().id : '') + "/data-analyses/" + (this.dataAnalysis() ? this.dataAnalysis().id : '')}>
            {this.dataAnalysis() && this.dataAnalysis().attributes.name}
          </Link>
          <Link to={"/data-platforms/" + (this.dataPlatform() ? this.dataPlatform().id : '') + "/data-analyses/" + (this.dataAnalysis() ? this.dataAnalysis().id : '') + "#runs"}>
            Runs
          </Link>
        </Breadcrumbs>
        <Row>
          <Col md={6}>
            <h3>Properties</h3>
            <dl>
              <dt>Type</dt>
              <dd>{this.dataAnalysis() && this.dataAnalysis().attributes['analysis-type']}</dd>
              <dt>Started at</dt>
              <dd>{this.resource() && Time.formatTime(this.resource().attributes['started-at'], 'withTz')}</dd>
              <dt>Finished at</dt>
              <dd>{this.resource() && (Time.formatTime(this.resource().attributes['finished-at'], 'withTz') || '-')}</dd>
              <dt>Status</dt>
              <dd>{this.resource() && this.resource().attributes['status']}</dd>
              <dt>Samples from</dt>
              <dd>{this.resource() && Time.formatTime(this.resource().attributes['from-time'], 'withTz')}</dd>
              <dt>Samples to</dt>
              <dd>{this.resource() && Time.formatTime(this.resource().attributes['to-time'], 'withTz')}</dd>
              <dt>Instruments</dt>
              <dd>{this.dataInstruments().map(di => di.attributes.name).join(', ')}</dd>
              {this.resource() && this.resource().attributes['replicate-count'] && <>
                <dt>Replicate count</dt>
                <dd>{this.resource().attributes['replicate-count']}</dd>
              </>}
              {this.resource() && this.resource().attributes['estimation-interval-days'] && <>
                <dt>Estimation interval:</dt>
                <dd>{this.resource().attributes['estimation-interval-days']} days</dd>
              </>}
              {this.resource() && this.resource().attributes['control-groups'] && <>
              <dt>Control groups:</dt>
              <dd>
                {this.resource().attributes['control-groups'].map((group, index) => (
                  <Panel key={`control-group-${index}`}>
                    <Panel.Body>
                      {Settings(group)}
                    </Panel.Body>
                  </Panel>
                ))}
              </dd>
              </>}
              <dt>Ranges:</dt>
              <dd>
                {this.resource() && this.resource().attributes['ranges'] &&
                    this.resource().attributes['ranges'].map((ranges, index) => (
                        <Panel key={`range-${index}`}>
                          <Panel.Body>
                            {Settings(ranges)}
                          </Panel.Body>
                        </Panel>
                    ))}
              </dd>
            </dl>
            {this.dataAnalysis() && this.dataAnalysis().included && this.dataAnalysis().included['data-analysis-root-instruments'] && <>
              <dt>Control instruments:</dt>
              <dd>
                {this.dataAnalysis().included['data-analysis-root-instruments'].map(instrument => (
                  <ul key={`instrument-${instrument.id}`}>
                    <li>{instrument.attributes.name}</li>
                    <ul>
                      {instrument.attributes['children-names'].map(name => (
                        <li key={`instrument-${instrument.id}-child-${name}`}>{name}</li>
                      ))}
                    </ul>
                  </ul>
                  ))}
              </dd>
            </>}
          </Col>
          <Col md={6}>
            <h3>Results</h3>
            {this.dataAnalysisResults().map(result => (
              Results(result)
            ))}
          </Col>
        </Row>
      </div>
    );
  }
}

DataAnalysisRunDetails.propTypes = {
  resources: PropTypes.object
};

export default connect(DataAnalysisRunDetails.mapStateToProps, DataAnalysisRunDetails.mapDispatchToProps)(DataAnalysisRunDetails);
