import React from 'react';
import ResourceForm from '../../resource_form';
import ResourceFormInput from "../../resource_form_input";
import ResourceFormFooter from "../../resource_form_footer";
import ResourceFormHeader from "../../resource_form_header";
import ResourceFormSelect from "../../resource_form_select";

class UserGroupForm extends ResourceForm {
  static get apiType(){
    return 'user-groups';
  }

  static get relationships() {
    return ['organization'];
  }

  constructor(props){
    super(props);
    this.state.organizationsIds = [];
  }

  componentWillMount() {
    super.componentWillMount();
    this.loadResourcesAndStoreIds('organizations');
  }

  organizations(){
    return this.resources('organizations', this.state.organizationsIds);
  }

  render() {
    return (
      <div className="user-group-form-component">
        <form>
          <ResourceFormHeader resourceForm={this}/>
          <ResourceFormSelect name='organization' resourceForm={this} collection={this.organizations()} relationship={true}/>
          <ResourceFormInput name='name' resourceForm={this}/>
          <ResourceFormInput name='instrument-group-filter' resourceForm={this} label="Instrument grp filter"/>
          <ResourceFormFooter resourceForm={this}/>
        </form>
      </div>
    );
  }
};

export default ResourceForm.connect()(UserGroupForm);