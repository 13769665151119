export default class Resource{
  constructor(props){
    Object.keys(props).forEach(key => this[key] = props[key]);
  }

  canCreate(endpoint = null){
    return this._can('create', endpoint);
  }

  canIndex(endpoint = null){
    return this._can('index', endpoint);
  }

  canUpdate(){
    return this._can('update');
  }

  canDestroy(){
    return this._can('destroy');
  }

  canShow(){
    return this._can('show');
  }

  _can(action, endpoint = null){
    if (!this.meta || !this.meta.permissions)
      return true;
    else if (!endpoint)
      return this.meta.permissions[action];
    else
      return this.meta.permissions[endpoint] && this.meta.permissions[endpoint][action];
  }

  static mapStateToResources(state, ...apiTypes){
    let props = {resources: {}};
    (apiTypes.length > 0 ? apiTypes : Resource._allApiTypes(state)).forEach(apiType => {
      props.resources[apiType] = [];
      if (state.api && state.api[apiType] && state.api[apiType].data) {
        props.resources[apiType] = state.api[apiType].data.map(datum => new Resource(datum));
        props.resources[apiType].forEach((res) => {
          Resource._mapRelated(state, res, [apiType]);
        });
      }
    });
    //console.log(props);
    return props;
  }

  static _allApiTypes(state){
    return Object.keys(state.api).filter((elem, pos, arr) => arr.indexOf(elem) === pos);
  }

  // Recursively map included related resources, handled array is used for preventing dead locks
  static _mapRelated(state, resource, handled){
    if (!resource.relationships)
      return;
    Object.keys(resource.relationships).forEach(relationKey => {
      let relationships = resource.relationships[relationKey].data;
      if (!relationships)
        return;
      let {ids, apiType} = Resource._resolveIdsAndApiType(relationships);
      if (ids.length === 0 || apiType === null || handled.indexOf(apiType) !== -1)
        return;
      if (state.api[apiType] && state.api[apiType].data){
        if (!resource.included)
          resource.included = {};
        let resources = state.api[apiType].data.filter(res => ids.indexOf(res.id) !== -1);
        // Single resource or many?
        if (Array.isArray(relationships)) {
          resource.included[relationKey] = resources.map(res => new Resource(res));
          resource.included[relationKey].forEach((res) => {
            Resource._mapRelated(state, res, handled.concat(apiType));
          })
        } else if (resources.length === 1) {
          resource.included[relationKey] = new Resource(resources[0]);
          Resource._mapRelated(state, resource.included[relationKey], handled.concat(apiType));
        }
      }
    });
  }

  static _resolveIdsAndApiType(relationships){
    let ids = [];
    let apiType = null;
    if (Array.isArray(relationships)) {
      ids = relationships.map(data => data.id);
      if (relationships[0])
        apiType = relationships[0].type;
    } else {
      ids = [relationships.id];
      apiType = relationships.type;
    }
    return {ids: ids, apiType: apiType};
  }
}