import React from 'react';
import PropTypes from 'prop-types';
import {FormGroup, ControlLabel, FormControl, Button, Checkbox} from 'react-bootstrap';
import _ from 'lodash';
import ResourceFormInput from "../../../resource_form_input";

class AutoMukitDynamicInput extends React.Component {

  constructor(props){
    super(props);
    this.state = {settings: []};
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSettingInputChange = this.handleSettingInputChange.bind(this);
    this.addSettings = this.addSettings.bind(this);
    this.defaultInputs = {
      'ranges': {
        'mode': { type: 'select', placeholder: 'select mode', label: 'Uncertainty result mode', options: ['- select -', 'relative', 'absolute'] },
        'min': { type: 'number', placeholder: 'range min value', label: 'Min' },
        'max': { type: 'number', placeholder: 'range max value', label: 'Max' },
        'coverage_factor': { type: 'number', placeholder: 'coverage factor K', label: 'Coverage factor' },
        'replicate_samples_min': { type: 'number', placeholder: 'min amount of replicate samples needed', label: 'Replicate samples min' },
        'control_samples_min': { type: 'number', placeholder: 'min amount of control samples needed', label: 'Control samples min' }
      }
    };
  }

  resolveError(attr){
    return this.props.resourceForm.resolveError(attr);
  }

  resolveValue(attr){
    return this.props.resourceForm.resolveValue(attr);
  }

  resolveSettingValue(index, attr, target){
    const settings = this.props.resourceForm.resolveValue(target);
    if (settings[index])
      return settings[index][attr];
    else
      return '';
  }

  handleInputChange(ev){
    this.props.resourceForm.handleInputChange(ev);
  }

  handleSettingInputChange(ev, target){
    const name = ev.target.name.split(';')[0];
    const index = ev.target.name.split(';')[1];
    const value = ev.target.value;
    const settings = _.cloneDeep(this.props.resourceForm.resolveValue(target) || []);
    if (!settings[index])
      settings[index] = {};
    settings[index][name] = value;
    this.applySettings(settings, target);
  }

  applySettings(settings, target){
    // A bit hacky but...
    this.handleInputChange({target: {name: target, value: settings}});
  }

  settings(target){
    return this.props.resourceForm.resolveValue(target) || [];
  }

  addSettings(target){
    let settings = _.cloneDeep(this.settings(target));
    settings.push(
      Object.keys(this.defaultInputs[target]).reduce((acc, item) => ({...acc, [item]: ''}), {})
    );
    this.applySettings(settings, target);
  }

  removeSettings(index, target){
    let settings = _.cloneDeep(this.settings(target));
    settings.splice(index, 1)
    this.applySettings(settings, target);
  }

  instruments(){
    return this.props.resourceForm.resolveValue('instruments') || [];
  }

  addControlInstrument(){
    let instruments = _.cloneDeep(this.instruments());
    instruments.push({
      parent: null,
      children: []
    });
    this.handleInputChange({target: {name: 'instruments', value: instruments}});
  }

  removeControlInstrument(index){
    let instruments = _.cloneDeep(this.instruments());
    instruments.splice(index, 1);
    this.handleInputChange({target: {name: 'instruments', value: instruments}});
  }

  setControlInstrument(index, id){
    let instruments = _.cloneDeep(this.instruments());
    instruments[index].parent = id;
    this.handleInputChange({target: {name: 'instruments', value: instruments}});
  }

  setControlInstrumentChild(index, id, state){
    let instruments = _.cloneDeep(this.instruments());
    if (state && !instruments[index].children.map(c => c.toString()).includes(id)) {
      instruments[index].children.push(id);
    } else if (!state && instruments[index].children.map(c => c.toString()).includes(id)){
      const i = instruments[index].children.map(c => c.toString()).indexOf(id);
      instruments[index].children.splice(i, 1);
    }
    this.handleInputChange({target: {name: 'instruments', value: instruments}});
  }

  render(){
    const InstrumentInput = (index, instrument) => (
      <div key={`instrument-input-${index}`} className="setting-block">
        <FormControl
          componentClass="select"
          name={`instrument;${index}`}
          value={instrument.parent || ''}
          onChange={(ev) => {this.setControlInstrument(index, ev.target.value)}}
          placeholder={'- select -'}
        >
          <option>- select control instrument -</option>
          {this.props.dataInstruments.map(option => <option key={`instrument-option-${option.id}`} value={option.id}>{option.attributes.name}</option>)}
        </FormControl>
        <br/>
        <strong>Sampling instruments</strong>
        {this.props.dataInstruments.map((option, cIndex) => <Checkbox
            key={`instrument-${index}-${cIndex}`}
            checked={instrument.children.map(c => c.toString()).includes(option.id)}
            onChange={(ev) => this.setControlInstrumentChild(index, option.id, ev.target.checked)}
          >
            {option.attributes.name}
          </Checkbox>)}
        <Button
          className="setting-block-remove"
          bsStyle="default"
          onClick={() => this.removeControlInstrument(index)}
        >
          <i className="glyphicon glyphicon-trash"/>
        </Button>
      </div>
    )
    const SettingInput = (index, setting, target) => {
      return (
        <div key={`${target}-${index}`} className="setting-block">
          {Object.keys(setting).map((key) => (
            <FormGroup key={target + key + index} controlId={key + index}>
              <ControlLabel>{this.defaultInputs[target][key]['label']}</ControlLabel>
              {this.defaultInputs[target][key]['type'] !== 'select' &&
                <FormControl
                  name={`${key};${index}`}
                  type={this.defaultInputs[target][key]['type']}
                  value={this.resolveSettingValue(index, key, target)}
                  onChange={(ev) => this.handleSettingInputChange(ev, target)}
                  placeholder={this.defaultInputs[target][key]['placeholder']}
                />}
              {this.defaultInputs[target][key]['type'] === 'select' &&
                <FormControl
                  componentClass="select"
                  name={`${key};${index}`}
                  value={this.resolveSettingValue(index, key, target)}
                  onChange={(ev) => this.handleSettingInputChange(ev, target)}
                  placeholder={this.defaultInputs[target][key]['placeholder']}
                >
                  {this.defaultInputs[target][key]['options'].map(option => <option key={`${key}-option-${option}`} value={option}>{option}</option>)}
                </FormControl>
              }
            </FormGroup>
          ))}
          <Button
            className="setting-block-remove"
            bsStyle="default"
            onClick={() => this.removeSettings(index, target)}
          >
            <i className="glyphicon glyphicon-trash"/>
          </Button>
        </div>
      )
    };
    return (
      <div className="auto-mukit-input-component">
        <div className="input-group">
          <ResourceFormInput
            name="replicate-count"
            label="Replicate sample count"
            resourceForm={this.props.resourceForm}
            placeholder="minimum amount of samples needed for a replicate group"
          />
          <ResourceFormInput
            name="replicate-group-period-sec"
            label="Replicate group period (seconds)"
            resourceForm={this.props.resourceForm}
            placeholder="time period that contains samples for a replicate group"
          />
          <ResourceFormInput
            name="estimation-interval-days"
            label="Estimation interval (days)"
            resourceForm={this.props.resourceForm}
            placeholder="data chunk size in days"
          />
        </div>
        <div className="input-group">
          <h4>Control instruments</h4>
          {this.instruments().map((instrument, index) =>
            InstrumentInput(index, instrument)
          )}
          <Button bsStyle="default" className="add-settings" onClick={() => this.addControlInstrument()}>
            <i className="glyphicon glyphicon-plus"/> Add control instrument
          </Button>
        </div>
        <div className="input-group">
          <h4>Ranges</h4>
          {this.settings('ranges').map((setting, index) =>
            SettingInput(index, setting, 'ranges')
          )}
          <Button bsStyle="default" className="add-settings" onClick={() => this.addSettings('ranges')}>
            <i className="glyphicon glyphicon-plus"/> Add range
          </Button>
        </div>
      </div>
    )
  }
}

AutoMukitDynamicInput.propTypes = {
  resourceForm: PropTypes.object,
  dataInstruments: PropTypes.array
};

export default AutoMukitDynamicInput;
