import {Component} from "react";
import PropTypes from "prop-types";
import React from "react";
import Time from './time';

class DataGraphTooltip extends Component{
  payload(){
    if (this.props.active && this.props.payload)
      return this.props.payload.filter(p => !Array.isArray(p.value));
    else
      return null;
  }

  render() {
    if (!this.payload())
      return <div></div>;
    const valueLine = (p, showName) => {
      const name = showName ? `${p.name}:` : '';
      return (
        <li key={p.dataKey}>
          {`${name} ${p.value} ${p.payload['mu_' + p.dataKey] ? '(' + p.payload['mu_' + p.dataKey].join('-') + ')' : ''} ${p.payload['unit_' + p.dataKey]}`}
        </li>
      );
    };

    const showName = this.payload().length > 1;

    //console.log(payload);
    return (
      <div className="data-tooltip">
        <p className="data-tooltip-label">
          <Time format="withTz" resolution="milliseconds">{this.payload()[0].payload['ts']}</Time>
        </p>
        <ul className="data-tooltip-item-list">
          {this.payload().map(p => valueLine(p, showName))}
        </ul>
      </div>
    );
  }
}

DataGraphTooltip.propTypes = {
  type: PropTypes.string,
  payload: PropTypes.array,
  active: PropTypes.bool
};

export default DataGraphTooltip;