import React from 'react';
import ResourceForm from '../../resource_form';
import _ from 'lodash';
import ResourceFormHeader from "../../resource_form_header";
import ResourceFormFooter from "../../resource_form_footer";
import ResourceFormInput from "../../resource_form_input";
import ResourceFormCheckbox from "../../resource_form_checkbox";
import UserFormRoles from "./user_form_roles";
import { createResource, deleteResource, updateResource } from "redux-json-api";
import { withRouter } from 'react-router-dom';

class UserForm extends ResourceForm {
  static get apiType(){
    return 'users';
  }

  constructor(props){
    super(props);
    this.state.roles = [];
    this.state.removedRoles = [];
  }

  componentWillMount() {
    // Update or create?
    if (this.resourceId())
      this.props.loadResources(this.constructor.apiType + '/' + this.resourceId() + '?include=roles.user-group.organization');
  }

  roles(){
    let roles = [];
    // Extend state with resource roles (on update situation)
    if (this.resource() && this.resource().included && this.resource().included['roles']){
      this.resource().included['roles'].forEach(role => {
        if (this.state.removedRoles.indexOf(role.id) !== -1) return;
        if (this.state.roles.find(r => parseInt(r.id, 10) === parseInt(role.id, 10))) return;
        const userGroup = role.included['user-group'];
        roles.push({
          id: role.id,
          roleType: role.attributes['role-type'],
          userGroupId: userGroup.id,
          userGroupName: userGroup.included.organization.attributes.name + ' - ' + userGroup.attributes.name
        });
      });
    }
    return roles.concat(this.state.roles);
  }

  updateAttributes () {
    let attr = _.cloneDeep(this.state.attributes);
    // Do not change the password if empty
    if (attr.password === '')
      attr.delete('password');
    return attr;
  }

  saveRoles(userId){
    Promise.all(this.state.roles.map((id) => this.props.saveRole(id, userId))).then(() => {
      this.props.history.push(this.redirectAfterCreate());
    });
  }

  deleteRoles(){
    return Promise.all(this.state.removedRoles.map((id) => this.props.deleteRole(id)));
  }

  isSubmitted(){
    return false;
  }

  handleSubmit(ev){
    super.handleSubmit(ev).then((response) => {
      if (!response.id)
        return;
      if (this.state.removedRoles.length > 0)
        this.deleteRoles().then(() => this.saveRoles(response.id));
      else
        this.saveRoles(response.id);
    });
  }

  render() {
    return (
      <div className="user-form-component">
        <form>
          <ResourceFormHeader resourceForm={this}/>
          <ResourceFormInput name="name" resourceForm={this}/>
          <ResourceFormInput name="email" type="email" resourceForm={this}/>
          <ResourceFormInput name="password" type="password" resourceForm={this}/>
          <ResourceFormInput name="password-confirmation" type="password" resourceForm={this}/>
          <ResourceFormCheckbox name="admin" resourceForm={this}/>
          <UserFormRoles roles={this.roles()} resourceForm={this}/>
          <ResourceFormFooter resourceForm={this}/>
        </form>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    deleteRole: (id) => {
      const data = {
        type: 'roles',
        id: id
      };
      return dispatch(deleteResource(data));
    },
    saveRole: (role, userId) => {
      const attributes = {
        'role-type': role.roleType,
      };

      const relationships = {
        'user-group': {
          data: {
            type: 'user-groups',
            id: role.userGroupId
          }
        },
        'user': {
          data: {
            type: 'users',
            id: userId
          }
        }
      };

      if (role.id[0] === '_') {
        // CREATE
        const data = {
          type: 'roles',
          attributes: attributes,
          relationships: relationships
        };
        return dispatch(createResource(data));
      } else {
        // UPDATE
        const data = {
          type: 'roles',
          attributes: attributes,
          id: role.id,
          relationships: relationships
        };
        return dispatch(updateResource(data));
      }
    }
  };
};

export default withRouter(ResourceForm.connect(null, mapDispatchToProps)(UserForm));