import React from 'react';
import { Link } from "react-router-dom";
import ResourceList from '../../resource_list';
import Breadcrumbs from "../../lib/breadcrumbs";

class PublicDataOrganizations extends ResourceList{
  static get apiType(){
    return 'public-data-platforms';
  }

  defaultQueryParams() {
    return {
      include: ['data-instruments'],
      sort: ['name'],
      pageSize: 1000
    }
  }

  organizationNames(){
    let names = this.resources().map(r => r.attributes['organization-name']);
    return names.filter((elem, pos, arr) => elem !== null && arr.indexOf(elem) === pos);
  }

  groups(organizationName){
    const platforms = this.resources().filter(r => r.attributes['organization-name'] === organizationName);
    const groups = platforms.map(p => p.included['data-instruments'].map(di => di.attributes['public-group'])).flat();
    return groups.filter((elem, pos, arr) => elem !== null && arr.indexOf(elem) === pos);
  }

  render() {
    const platforms = (name) => {
      return (
        <div key={`org-${name}`}>
          <h1>{name}</h1>
          {this.groups(name).map(group => {
            return (
              <Link className="public-instrument-group-link" key={`${name}-public-group-${group}`} to={`/public/data/${encodeURIComponent(name)}/${encodeURIComponent(group)}`}>
                {group}
                <i className="action-icon glyphicon glyphicon-chevron-right"></i>
              </Link>
            )
          })}
        </div>
      );
    };
    return (
      <div className="public-data-organizations-component">
        <Breadcrumbs>
          <Link to={'/public'}>Home</Link>
          <Link to={'/public/data'}>Public data</Link>
        </Breadcrumbs>
        {this.organizationNames().length === 0 &&
        <span></span>}
        {this.organizationNames().map(name => platforms(name))}
      </div>
    );
  }
}

export default ResourceList.connect()(PublicDataOrganizations);