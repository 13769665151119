import React from 'react';
import PropTypes from 'prop-types';
import ResourceForm from '../../resource_form';
import AutoMukitInput from './data_analysis_inputs/auto_mukit_input';
import ResourceFormHeader from "../../resource_form_header";
import ResourceFormFooter from "../../resource_form_footer";
import ResourceFormInput from "../../resource_form_input";
import ResourceFormSelect from "../../resource_form_select";
import ManualMuInput from "./data_analysis_inputs/manual_mu_input";
import AutoMukitDynamicInput from './data_analysis_inputs/auto_mukit_dynamic_input';

const apiType = 'data-analyses';

class DataAnalysisForm extends ResourceForm {
  static get apiType() {
    return apiType;
  }

  constructor() {
    super();
    this.state.instrumentIds = [];
    this.state.dataAnalysisInstruments = [];
  }

  setDataAnalysisInstruments(newState) {
    this.setState({dataAnalysisInstruments: newState})
  }

  componentWillMount() {
    // Load instruments for AutoMukit dynamic
    this.props.loadResources('data-instruments?filter[for-data-filter]=' + this.dataPlatformId()).then((response) => {
      const body = response && response.body;
      if (body) {
        this.setState({instrumentIds: body.data.map(entity => entity.id)});
      }
    })
    // Update or create?
    if (this.resourceId())
      this.props.loadResources(this.constructor.apiType + '/' + this.resourceId() + '?include=data-platform');
  }

  dataInstruments(){
    if (this.props.resources && this.props.resources['data-instruments']) {
      return this.props.resources['data-instruments'].filter(i => (
        this.state.instrumentIds.includes(i.id)
      ));
    } else
      return [];
  }

  dataPlatformId(){
    if (this.resource() && this.resource().included && this.resource().included['data-platform'])
      return this.resource().included['data-platform'].id;
    else if (this.props.match && this.props.match.params)
      return this.props.match.params.dataPlatformId;
  }

  redirectAfterCreate() {
    return '/data-platforms/' + this.dataPlatformId() + '#data-analyses';
  }

  createRelationships(){
    return {'data-platform': {data: {type: 'data-platforms', id: this.dataPlatformId()}}};
  }

  isType(type){
    if (this.state.attributes['analysis-type'] === type)
      return true;
    else if (! this.state.attributes['analysis-type'] && this.resource() && this.resource().attributes['analysis-type'] === type)
      return true;
    else
      return false;
  }

  analysisTypes(){
    return [
      {id: 'auto_mukit', label: 'AutoMUkit - measurement uncertainty'},
      {id: 'auto_mukit_dynamic', label: 'AutoMUkit dynamic'},
      {id: 'manual_mu', label: 'Manual uncertainty definition'}
    ];
  }

  render() {
    return (
      <div className="data-analysis-form-component">
        <form style={{maxWidth: 'none'}}>
          <ResourceFormHeader resourceForm={this} label={this.resourceId() ? "Edit data analysis" : "New data analysis"}/>
          <div className='row'>
            <div className='col-sm-6'>
              <ResourceFormSelect name="analysis-type" collection={this.analysisTypes()} valueAttribute='id' labelAttribute='label' label="Type" resourceForm={this}/>
            </div>
            <div className='col-sm-6'>
              <ResourceFormInput name="name" resourceForm={this}/>
            </div>
          </div>
          {this.isType('auto_mukit') &&
          <AutoMukitInput resourceForm={this}/>}
          {this.isType('auto_mukit_dynamic') &&
          <AutoMukitDynamicInput resourceForm={this} dataInstruments={this.dataInstruments()}/>}
          {this.isType('manual_mu') &&
          <ManualMuInput resourceForm={this}/>}
          <ResourceFormFooter resourceForm={this}/>
        </form>
      </div>
    );
  }
}

DataAnalysisForm.propTypes = {
  params: PropTypes.object
};

export default ResourceForm.connect()(DataAnalysisForm);
