import React, { Component } from 'react';
import Header from "./header";
import Menu from "./menu";
import Flash from "./flash";
import Main from "./main";
import { Button, Glyphicon } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import queryString from "query-string";
import screenfull from 'screenfull';

class App extends Component {
  //componentWillMount(){
  //  if (!localStorage.getItem('jwt'))
  //    this.props.history.push('/login');
  //}

  constructor(props){
    super(props);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.toggleFullscreen = this.toggleFullscreen.bind(this);
    const params = queryString.parse(window.location.search);
    if (params.view === 'fullscreen')
      this.state = {view: 'fullscreen'};
    else
      this.state = {view: ''};
  }

  handleKeyDown(event){
    //console.log(event.key);
    if (event.key === "f" && event.ctrlKey)
      this.toggleFullscreen();
  }

  toggleFullscreen(){
    let params = queryString.parse(window.location.search);
    if (params.view === 'fullscreen'){
      delete(params.view);
      this.setState({view: ''});
      if (screenfull.enabled)
        screenfull.exit();
    } else {
      params.view = 'fullscreen';
      this.setState({view: 'fullscreen'});
      if (screenfull.enabled)
        screenfull.request();
    }
    let search = '?' + queryString.stringify(params);
    const hashParts = window.location.hash.split('#');
    if (hashParts.length > 1)
      search += '#' + hashParts.slice(-1)[0];
    window.history.replaceState(null, null, search);
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  render() {
    return (
      <div className={"app-component " + this.state.view}>
        {this.state.view !== 'fullscreen' &&
        <Button title="Turn fullscreen mode on" className="fullscreen-toggle" onClick={this.toggleFullscreen}>
          <Glyphicon glyph="fullscreen"/>
        </Button>}
        {this.state.view === 'fullscreen' &&
        <Button title="Turn fullscreen mode off" className="fullscreen-toggle" onClick={this.toggleFullscreen}>
          <Glyphicon glyph="resize-small"/>
        </Button>}
        <Header/>
        <Menu/>
        <Flash/>
        <Main/>
      </div>
    );
  }
}

export default withRouter(App);