import React from 'react';
import { Link } from 'react-router-dom';
import { Table, Button } from 'react-bootstrap';
import ResourceList from "../../resource_list"
import Confirm from 'react-confirm-bootstrap';
import ResourceListPagination from "../../resource_list_pagination";
import NewResourceLink from "../../lib/new_resource_link";

const apiType = 'user-groups';

class UserGroupList extends ResourceList {

  static get apiType(){
    return apiType;
  }

  defaultQueryParams(){
    return {include: ['organization']};
  }

  render() {
    return (
      <div className="user-group-list-component">
        <NewResourceLink to={`admin/${apiType}/new`}>New user group</NewResourceLink>
        <Table responsive hover>
          <thead>
          <tr>
            <th>Organization</th><th>Name</th><th>Instrument grp filter</th><th></th>
          </tr>
          </thead>
          <tbody>
          {this.resources().map(resource => (
            <tr key={resource.id}>
              <td>{resource.included.organization.attributes.name}</td>
              <td>{resource.attributes.name}</td>
              <td>{resource.attributes['instrument-group-filter']}</td>
              <td className="actions">
                <Link to={`admin/${apiType}/${resource.id}/edit`}>
                  <i className="action-icon glyphicon glyphicon-pencil"></i>
                </Link>
                <Confirm
                  onConfirm={() => {this.handleResourceDelete(resource.id)}}
                  body={`Are you sure you want to delete ${resource.attributes['name']}?`}
                  confirmText="Confirm delete"
                  title="Delete user group">
                  <Button className="btn-link">
                    <i className="action-icon glyphicon glyphicon-trash"></i>
                  </Button>
                </Confirm>
              </td>
            </tr>
          ))}
          </tbody>
        </Table>
        <ResourceListPagination resourceList={this}/>
      </div>
    );
  }
}

export default ResourceList.connect()(UserGroupList);