import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {Glyphicon, Button} from 'react-bootstrap';
import ResourceList from '../../resource_list';
import Confirm from 'react-confirm-bootstrap';
import Time from '../../lib/time';
import { withRouter } from "react-router-dom";
import OrderableTiles from "../../lib/orderable_tiles";

const apiType = 'sequences';

class SequenceList extends ResourceList {

  static get apiType(){
    return apiType;
  }

  defaultQueryParams() {
    return {
      filter: {
        'platform.id': this.platformId()
      },
      include: ['sequence-steps.step'],
      sort: ['index-number'],
      pageSize: 1000
    }
  }

  platformId(){
    return this.props.match.params.id;
  }

  sequenceSteps(sequence){
    if (!sequence || !sequence.included || !sequence.included['sequence-steps'])
      return [];
    else
      return sequence.included['sequence-steps'].sort((a, b) => (
        a.attributes['index-number'] - b.attributes['index-number']
      ));
  }

  confirmReorder(id, newIndex){
    const data = {
      type: 'sequences',
      id: id,
      attributes: {
        'index-number': newIndex
      }
    };
    this.props.updateResource(data).then(() => this.reloadResources());
  }

  render() {
    return (
      <div className="sequence-list-component">
        {this.resources().length > 1 && this.resources()[0].canUpdate() &&
        <span className="tile-drag-to-order-hint">Drag tiles to reorder</span>}
        <OrderableTiles onConfirmReorder={this.confirmReorder.bind(this)}>
          {this.resources().map(resource => (
            <div data-id={resource.id} data-index-number={resource.attributes['index-number']} className={"tile " + (resource.attributes.active ? '' : 'inactive')} key={"sequence-tile-" + resource.id}>
              <span className="index-number">{resource.attributes['order-number']}</span>
              <span className="sequence-name">
                {resource.attributes.name}
                {resource.attributes['start-date'] &&
                <Glyphicon glyph="time" title={"Timer: "  + Time.formatTime(resource.attributes['start-date']) + "\nRep. interval: " + (resource.attributes['interval-value'] || '') + ' '  + (resource.attributes['interval-unit'] || '')}/>}
                {resource.attributes['run-on-start'] &&
                <Glyphicon glyph="ok" title="Runs on platform start"/>}
              </span>
              <span className="steps">
                {this.sequenceSteps(resource).map(r => (
                  <span key={"sequence-step-" + r.id} className={"step " + (r.attributes.active && resource.attributes.active ? '' : 'inactive')}>
                    {r.included && r.included.step ? r.included.step.attributes.name : ''}
                  </span>
                ))}
              </span>
              <div className="actions">
                {resource.canShow() &&
                <Link to={`/platforms/${this.platformId()}/${apiType}/${resource.id}/steps`}>
                  <i className="action-icon glyphicon glyphicon-list"></i>
                </Link>}
                {resource.canUpdate() &&
                <Link to={`/platforms/${this.platformId()}/${apiType}/${resource.id}/edit`}>
                  <i className="action-icon glyphicon glyphicon-pencil"></i>
                </Link>}
                {resource.canDestroy() &&
                <Confirm
                  onConfirm={() => {this.handleResourceDelete(resource.id)}}
                  body={`Are you sure you want to delete ${resource.attributes['name']}?`}
                  confirmText="Confirm delete"
                  title="Delete sequence">
                  <Button className="btn-link">
                    <i className="action-icon glyphicon glyphicon-trash"></i>
                  </Button>
                </Confirm>}
              </div>
            </div>
          ))}
        </OrderableTiles>
        {this.props.platform && this.props.platform.canCreate('sequences') &&
        <Link to={`${this.props.match.url}/${apiType}/new`} className="new-resource-tile-button">
          Add a sequence
        </Link>}
      </div>
    );
  }
}

SequenceList.propTypes = {
  platform: PropTypes.object
};

export default withRouter(ResourceList.connect()(SequenceList));