import React from 'react';
import ResourceForm from '../../resource_form';
import ResourceFormFooter from "../../resource_form_footer";
import ResourceFormHeader from "../../resource_form_header";
import _ from 'lodash';
import RuleFormConditions from "./rule_form_conditions";
import RuleFormConsequences from "./rule_form_consequences";
import {createResource, deleteResource, updateResource} from "redux-json-api";

class RuleForm extends ResourceForm {
  static get apiType(){
    return 'rules';
  }

  static get relationships(){
    return ['conditions.instrument'];
  }

  constructor(props){
    super(props);
    this.state.sequenceStepIds = [];
    this.state.instrumentIds = [];
    this.state.conditions = [];
    this.state.removedConditions = [];
  }

  redirectAfterCreate() {
    return `/platforms/${this.platformId()}/sequences/${this.sequenceId()}/steps/${this.sequenceStepId()}/rules`;
  }

  createRelationships() {
    let rel = super.createRelationships();
    rel['sequence-step'] = {data: {type: 'sequence-steps', id: this.sequenceStepId()}};
    return rel;
  }

  conditions(){
    let conditions = [];
    // Extend state with resource conditions (on update situation)
    if (this.resource() && this.resource().included && this.resource().included['conditions']){
      this.resource().included['conditions'].forEach(condition => {
        if (this.state.removedConditions.indexOf(condition.id) !== -1) return;
        if (this.state.conditions.find(c => parseInt(c.id, 10) === parseInt(condition.id, 10))) return;
        conditions.push(_.cloneDeep(condition));
      });
    }
    return conditions.concat(this.state.conditions);
  }

  platformId(){
    return this.props.match.params.platformId;
  }

  sequenceId(){
    return this.props.match.params.sequenceId;
  }

  sequenceStepId(){
    return this.props.match.params.sequenceStepId;
  }

  componentWillMount() {
    super.componentWillMount();
    this.loadResourcesAndStoreIds(`sequence-steps?filter[platform.id]=${this.platformId()}&page[size]=1000&sort=index-number&include=sequence,step,rules`, 'sequenceStepIds');
    this.loadResourcesAndStoreIds(`instruments?filter[platform.id]=${this.platformId()}&page[size]=1000&sort=name`, 'instrumentIds');
  }

  saveConditions(id){
    Promise.all(this.state.conditions.map(c => this.props.saveCondition(c, id))).then(() => {
      this.props.history.push(this.redirectAfterCreate());
    });
  }

  deleteConditions(){
    return Promise.all(this.state.removedConditions.map(id => this.props.deleteCondition(id)));
  }

  isSubmitted(){
    return false;
  }

  handleSubmit(ev){
    super.handleSubmit(ev).then(response => {
      if (!response.id)
        return;
      if (this.state.removedConditions.length > 0)
        this.deleteConditions().then(() => this.saveConditions(response.id));
      else
        this.saveConditions(response.id);
    });
  }

  rules(){
    const rules = this.resources('sequence-steps', this.state.sequenceStepIds).filter(ss => ss.included && ss.included.rules).map(ss => ss.included.rules);
    return [].concat(...rules);
  }

  counters(){
    const consequences = [].concat(...this.rules().filter(r => r.attributes).map(r => r.attributes.consequences));
    return consequences.filter(c => c.type === 'set_counter' ||  c.type === 'add_counter').map(c => c.name).filter((el, i, a) => i === a.indexOf(el));
  }

  render() {
    return (
      <div className="rule-form-component">
        <form>
          <ResourceFormHeader resourceForm={this}/>
          <h4>Conditions</h4>
          <RuleFormConditions resourceForm={this}
                              instruments={this.resources('instruments', this.state.instrumentIds)}
                              counters={this.counters()}
                              conditions={this.conditions()}/>
          <h4>Consequences</h4>
          <RuleFormConsequences resourceForm={this}
                                counters={this.counters()}
                                sequenceSteps={this.resources('sequence-steps', this.state.sequenceStepIds)}
                                sequenceId={this.sequenceId()}/>
          <hr/>
          <ResourceFormFooter resourceForm={this}/>
        </form>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    deleteCondition: (id) => {
      const data = {
        type: 'conditions',
        id: id
      };
      return dispatch(deleteResource(data));
    },
    saveCondition: (condition, ruleId) => {
      let relationships = {
        rule: {
          data: {
            type: 'rules',
            id: ruleId
          }
        }
      };

      if (condition.relationships && condition.relationships.instrument && condition.relationships.instrument.data){
        relationships.instrument = {
          data: {
            type: 'instruments',
            id: condition.relationships.instrument.data.id
          }
        };
      }

      const attributes = _.omit(condition.attributes, ['order-number']);

      if (condition.id[0] === '_') {
        // CREATE
        const data = {
          type: 'conditions',
          attributes: attributes,
          relationships: relationships
        };
        return dispatch(createResource(data));
      } else {
        // UPDATE
        const data = {
          type: 'conditions',
          attributes: attributes,
          id: condition.id,
          relationships: relationships
        };
        return dispatch(updateResource(data));
      }
    }
  };
};

export default ResourceForm.connect(null, mapDispatchToProps)(RuleForm);