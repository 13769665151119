import React from "react";
import PropTypes from 'prop-types';
import { Checkbox } from 'react-bootstrap';
import ResourceList from "./resource_list";

const apiType = 'user-groups';

class UserGroupSelector extends ResourceList {

  static get apiType(){
    return apiType;
  }

  constructor(props){
    super(props);
    this.state.checkboxStates = {};
  }

  defaultQueryParams(){
    return {include: ['organization']};
  }

  componentWillMount(){
    super.componentWillMount();
    let url = '';
    if (this.props.platformId)
      url = `platforms/${this.props.platformId}?include=user-groups.organization`;
    else
      url = `data-platforms/${this.props.dataPlatformId}?include=user-groups.organization`;
    this.props.loadResources(url).then((resource) => {
      let states = {};
      if (resource && resource.body && resource.body.included)
        resource.body.included.forEach(ug => ug.type === "user-groups" ? states[ug.id] = true : null);
      this.setState({checkboxStates: states});
    });
  }

  userGroupsByOrganization(){
    let organizations = [];
    this.resources().forEach((ug) => {
      if (! ug.included)
        return;
      const orgName = ug.included.organization.attributes.name;
      if (organizations.indexOf(orgName) === -1)
        organizations.push(orgName);
    });
    let ret = {};
    organizations.forEach((org) => {
      ret[org] = this.resources().filter(ug => ug.included && ug.included.organization.attributes.name === org)
    });
    return ret;
  }

  resolveChecked(obj){
    if (Object.keys(this.state.checkboxStates).indexOf(obj.id) !== -1)
      return this.state.checkboxStates[obj.id];
    else
      return false;
  }

  handleCheckboxChange(ev, obj){
    const checked = ev.target.checked;
    let cloned = Object.assign({}, this.state.checkboxStates);
    cloned[obj.id] = checked;
    this.setState({checkboxStates: cloned});
    this.updateResource(cloned);
  }

  updateResource(states){
    let groupIds = [];

    for (const id in states){
      if (states[id])
        groupIds.push(id);
    }

    const ugs = groupIds.map((id) => (
      {
        type: 'user-groups',
        id: id
      }
    ));

    let type = null;
    let id = null;

    if (this.props.platformId){
      type = 'platforms';
      id = this.props.platformId;
    } else if (this.props.dataPlatformId){
      type = 'data-platforms';
      id = this.props.dataPlatformId;
    }

    const data = {
      type: type,
      id: id,
      relationships: {
        'user-groups': {
          data: ugs
        }
      }
    };

    this.props.updateResource(data);
  }

  render() {
    return (
      <div className="user-group-selector-component">
        {Object.keys(this.userGroupsByOrganization()).map((org) => (
          <div key={org}>
            <div className="organization-name">{org}</div>
            {this.userGroupsByOrganization()[org].map((obj) => (
              <div key={obj.id} className={"user-group-selector" + (this.resolveChecked(obj) ? ' checked' : '')}>
                <Checkbox checked={this.resolveChecked(obj)} onChange={(ev) => { this.handleCheckboxChange(ev, obj) }}/>
                <span className="user-group-name">{obj.attributes.name}</span>
              </div>
            ))}
          </div>
        ))}

      </div>
    )
  }
}

UserGroupSelector.propTypes = {
  platformId: PropTypes.string,
  dataPlatformId: PropTypes.string
};

export default ResourceList.connect()(UserGroupSelector);