import React, { Component } from 'react';
import PropTypes from 'prop-types';

class GaugeDigital extends Component{

  roundedValue(){
    if (this.props.value === null || isNaN(parseFloat(this.props.value)))
      return <i>&nbsp;</i>;
    return Math.ceil(this.props.value * 100) / 100;
  }

  valueIsOffRange(){
    if (this.props.rangeMin && this.props.value < this.props.rangeMin)
      return true;
    else if (this.props.rangeMax && this.props.value > this.props.rangeMax)
      return true;
    return false;
  }

  render(){
    return (
      <div className="gauge-digital-component">
        <span className="parameter">{this.props.parameter}</span>
        <span className={"value" + (this.valueIsOffRange() ? " off-range" : "")}>{this.roundedValue()}</span>
        <span className="unit">{this.props.unit}</span>
      </div>
    )
  }
}

GaugeDigital.propTypes = {
  value: PropTypes.any,
  unit: PropTypes.string,
  rangeMin: PropTypes.number,
  rangeMax: PropTypes.number,
  parameter: PropTypes.string
};

export default GaugeDigital;