import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {CartesianGrid, LineChart, Line, ResponsiveContainer, XAxis, YAxis, Tooltip} from "recharts";

class SpectrumGraph extends Component{
  data(){
    if (!this.props.value || typeof this.props.value !== 'object')
      return {};
    return Object.keys(this.props.value).map(k => ({x: k, y: this.props.value[k]}));
  }

  domain(){
    const min = this.props.valueMin !== null &&
      this.props.valueMin !== undefined &&
      this.props.valueMin !== '' ? parseInt(this.props.valueMin) : 0;
    const max = this.props.valueMax !== null &&
      this.props.valueMax !== undefined &&
      this.props.valueMax !== '' ? parseInt(this.props.valueMax) : 'auto';
    return [min, max];
  }

  xParameter(){
    const parts = this.props.parameter.split(",");
    return parts[parts.length - 1].trim();
  }

  yParameter(){
    const parts = this.props.parameter.split(",");
    return parts[0].trim();
  }

  xUnit(){
    const parts = this.props.unit.split(",");
    return parts[parts.length - 1].trim();
  }

  yUnit(){
    const parts = this.props.unit.split(",");
    return parts[0].trim();
  }

  render(){
    return (
      <div className="spectrum-graph-component">
        <ResponsiveContainer width={this.props.width || '100%'} height={this.props.height || '100%'}>
          <LineChart data={this.data()}>
            <Tooltip formatter={(value, name, props) => ([`${value}${this.yUnit()}`, this.yParameter()])}
                     labelFormatter={(label)=>`${this.xParameter()}: ${label}${this.xUnit()}`}/>
            <XAxis minTickGap={18} dataKey="x" name={this.xParameter()} unit={this.xUnit()}/>
            <YAxis domain={this.domain()} name={this.yParameter()} unit={this.yUnit()}/>
            <CartesianGrid strokeDasharray="3 3"/>
            <Line type="monotone" dataKey="y" stroke="#f00"/>
          </LineChart>
        </ResponsiveContainer>
      </div>
    )
  }
}

SpectrumGraph.propTypes = {
  value: PropTypes.any,
  parameter: PropTypes.string,
  unit: PropTypes.string,
  valueMin: PropTypes.any,
  valueMax: PropTypes.any,
  width: PropTypes.any,
  height: PropTypes.any
};

export default SpectrumGraph;