import React from 'react';
import PropTypes from 'prop-types';
import { withScriptjs, withGoogleMap, GoogleMap, Marker, Polyline } from 'react-google-maps';

const mapProps = (props) => {
  let ret = {};
  Object.keys(props).forEach(key => {
    if (props[key] !== undefined && key !== 'markers')
      ret[key] = props[key];
  });
  //console.log(ret);

  if (props.fitBounds){
    let bounds = new window.google.maps.LatLngBounds();
    props.markers.forEach((marker)=>{
      bounds.extend(marker);
    });
    ret['ref'] = (map) => {map && map.fitBounds(bounds);};
  }

  return ret;
};

const markerIcon = (marker) => {
  if (!marker.icon)
    return null;
  return {
    url: marker.icon,
    size: new window.google.maps.Size(256, 256),
    origin: new window.google.maps.Point(0, 0),
    anchor: new window.google.maps.Point(20, 40),
    scaledSize: new window.google.maps.Size(40, 40)
  }
};

const WorldMap = withScriptjs(withGoogleMap((props) =>
  <GoogleMap {...mapProps(props)}>
    {props.markers.map((marker, index) =>
      <Marker key={'marker' + index} position={{ lat: marker.lat, lng: marker.lng }} icon={markerIcon(marker)} title={marker.title} onClick={marker.onClick}/>)}
    {Object.keys(props.paths).map((key) =>
      <Polyline key={'polyline' + key} path={props.paths[key]} options={{strokeColor: '#FF0000'}}/>)}
  </GoogleMap>
));

WorldMap.propTypes = {
  defaultZoom: PropTypes.number,
  maxZoom: PropTypes.number,
  onClick: PropTypes.func,
  markers: PropTypes.array,
  paths: PropTypes.object,
  center: PropTypes.object,
  defaultCenter: PropTypes.object,
  fitBounds: PropTypes.bool,
  onDragStart: PropTypes.func,
  onZoomChanged: PropTypes.func
};

WorldMap.defaultProps = {
  googleMapURL: "https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyAJxzYOvcfXt8teAVfCuIP3VZw5IVwVbBU",
  loadingElement:  <div style={{ height: '100%' }} />,
  containerElement: <div className='world-map-component'/>,
  mapElement: <div style={{ height: '100%' }} />,
  markers: [],
  defaultZoom: 8,
  defaultCenter: {lat: 62.79493487887006, lng: 27.158203125},
  paths: {},
  fitBounds: false
};


export default WorldMap;