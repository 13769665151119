import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Table, Button } from 'react-bootstrap';
import ResourceList from "../../resource_list";
import Confirm from 'react-confirm-bootstrap';
import ResourceRow from "../../resource_row";
import Time from '../../lib/time';
import ResourceListPagination from "../../resource_list_pagination"
import NewResourceLink from "../../lib/new_resource_link"
import ajaxLoader from "../../assets/images/ajax-loader.gif";

const apiType = 'data-analysis-runs';

class DataAnalysisRunList extends ResourceList {

  static get apiType(){
    return apiType;
  }

  constructor(props) {
    super(props);
    this.checkInterval = null;
    this.reloads = 10;
  }

  componentWillMount() {
    super.componentWillMount();
    this.checkInterval = window.setInterval(() => {
      if (
          this.reloads > 0 &&
          !this.state.loading &&
          this.resources().some(r => ['pending', 'running'].includes(r.attributes['status']))
      ) {
        this.reloads -= 1;
        this.reloadResources();
      }
    }, 3000)
  }

  componentWillUnmount() {
    window.clearInterval(this.checkInterval);
  }

  analysisId(){
    return this.props && this.props.dataAnalysisId;
  }

  defaultQueryParams() {
    return {
      filter: {
        'data-analysis.id': this.analysisId()
      },
      sort: ['-started-at'],
      include: ['data-instruments']
    }
  }

  render() {
    return (
      <div className="data-analysis-run-list-component">
        <NewResourceLink to={`${this.props.match.url}/${apiType}/new`}
                         disabled={!this.props.canCreate}>
          New run
        </NewResourceLink>
        <Table responsive hover>
          <thead>
          <tr>
            <th>Run started at <Time.Zone/></th>
            <th>Run finished at</th>
            <th>Samples time range</th>
            <th>Instruments</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          {this.resources().map(resource => (
            <ResourceRow key={resource.id} redirectTo={`${this.props.match.url}/${apiType}/${resource.id}`}>
              <td><Time>{resource.attributes['started-at']}</Time></td>
              <td>
                {resource.attributes['status'] === 'finished' &&
                  Time.formatTime(resource.attributes['finished-at'])}
                {resource.attributes['status'] !== 'finished' &&
                  <span>
                    {resource.attributes['status']}
                    {['pending', 'running'].includes(resource.attributes['status']) &&
                    <img src={ajaxLoader} alt="Loading..." style={{marginLeft: 10}}/>}
                  </span>}
              </td>
              <td>
                <Time>{resource.attributes['from-time']}</Time>
                <span>&nbsp;-&nbsp;</span>
                <Time>{resource.attributes['to-time']}</Time>
              </td>
              <td>{resource.included && resource.included['data-instruments'] && resource.included['data-instruments'].map(i => i.attributes.name).join(', ')}</td>
              <td className="actions">
                <Confirm
                  onConfirm={() => {this.handleResourceDelete(resource.id)}}
                  body={`Are you sure you want to delete ${resource.attributes['started-at']}?`}
                  confirmText="Confirm delete"
                  title="Delete data analysis run">
                  <Button className="btn-link">
                    <i className="action-icon glyphicon glyphicon-trash"></i>
                  </Button>
                </Confirm>
                <i className="action-icon glyphicon glyphicon-chevron-right"></i>
              </td>
            </ResourceRow>
          ))}
          </tbody>
        </Table>
        <ResourceListPagination resourceList={this}/>
      </div>
    );
  }
}

DataAnalysisRunList.propTypes = {
  dataPlatformId: PropTypes.string,
  dataAnalysisId: PropTypes.string
};

export default withRouter(ResourceList.connect()(DataAnalysisRunList));
