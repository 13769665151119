import React from 'react';
import ResourceForm from '../../resource_form';
import ResourceFormHeader from "../../resource_form_header";
import ResourceFormFooter from "../../resource_form_footer";
import ResourceFormInput from "../../resource_form_input";
import ResourceFormCheckbox from "../../resource_form_checkbox";
import ResourceFormTypeahead from "../../resource_form_typeahead";

class DataInstrumentForm extends ResourceForm {
  static get apiType(){
    return 'data-instruments';
  }

  componentWillMount(){
    super.componentWillMount();
    this.props.loadResources('data-instruments');
  }

  publicGroups(){
    const result = this.resources('data-instruments').map(ip => ip.attributes['public-group']);
    return result.filter((elem, pos, arr) => elem !== null && arr.indexOf(elem) === pos);
  }

  dataPlatformId(){
    if (this.props.match && this.props.match.params)
      return this.props.match.params.dataPlatformId;
  }

  redirectAfterCreate() {
    return `/data-platforms/${this.dataPlatformId()}#data-instruments`;
  }

  render() {
    return (
      <div className="data-instrument-form-component">
        <form>
          <ResourceFormHeader resourceForm={this}/>
          <ResourceFormInput name="name" resourceForm={this} disabled={true}/>
          <ResourceFormCheckbox name="public" resourceForm={this}/>
          <ResourceFormTypeahead name="public-group" resourceForm={this} options={this.publicGroups()}/>
          <ResourceFormInput name="public-index-number" resourceForm={this} type="number"/>
          <ResourceFormInput name="public-name" resourceForm={this}/>
          <ResourceFormInput name="uncertainty" type="number" resourceForm={this}/>
          <ResourceFormCheckbox name="uncertainty-is-relative" resourceForm={this}/>
          <ResourceFormInput name="description" resourceForm={this} componentClass={"textarea"}/>
          <ResourceFormFooter resourceForm={this}/>
        </form>
      </div>
    );
  }
}

export default ResourceForm.connect()(DataInstrumentForm);
