import React from "react";
import ResourceForm from "./resource_form";
import ResourceFormInput from "./resource_form_input";
import ResourceFormHeader from "./resource_form_header";
import ResourceFormFooter from "./resource_form_footer";
import _ from 'lodash';

class UserAccount extends ResourceForm{
  static get apiType(){
    return 'users';
  }

  redirectAfterCreate() {
    return "/platforms";
  }

  updateAttributes () {
    let attr = _.cloneDeep(this.state.attributes);
    // Do not change the password if empty
    if (attr.password === '')
      attr.delete('password');
    return attr;
  }

  render(){
    return (
      <div className="user-account-component">
        <form>
          <ResourceFormHeader resourceForm={this} label="Edit account"/>
          <ResourceFormInput name="name" resourceForm={this}/>
          <ResourceFormInput name="email" type="email" resourceForm={this}/>
          <ResourceFormInput name="password" type="password" resourceForm={this}/>
          <ResourceFormInput name="password-confirmation" type="password" resourceForm={this}/>
          <ResourceFormFooter resourceForm={this}/>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    currentUser: state.auth.currentUser
  };
};

export default ResourceForm.connect(mapStateToProps)(UserAccount);