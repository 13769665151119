import React from 'react';
import PropTypes from 'prop-types';
import ResourceList from '../../resource_list';
import { Panel, Button } from 'react-bootstrap';
import PhaseTable from "./phase_table";
import InstrumentWidget from "./instrument_widget";
import { webSocketError } from '../../actions';
import ResourceListPagination from "../../resource_list_pagination";
import Camera from "./camera";
import queryString from "query-string";
import PlatformConnection from "../../lib/platform_connection";

const apiType = 'instruments';

class Monitoring extends ResourceList {

  static get apiType(){
    return apiType;
  }

  defaultQueryParams() {
    return {
      filter: {
        'platform.id': this.props.platformId,
      },
      include: ['instrument-alarm', 'remote-instrument-status'],
      sort: ['instrument-group', 'name'],
      pageSize: 1000
    }
  }

  constructor(props){
    super(props);
    this.state.instrumentData = [];
    this.state.currentSequenceStepId = 0;
    this.state.currentSequenceId = 0;
    this.state.currentMonitorGroup = this.resolveCurrentMonitorGroup();
    this.platformConnection = null;
  }

  componentWillMount() {
    super.componentWillMount();
    this.props.loadResources('platforms/' + this.props.platformId + '?include=remote-platform-status');
  }

  componentDidUpdate() {
    if (!this.platformConnection && this.ipAddress()){
      this.platformConnection = new PlatformConnection(
        this.props.platformId,
        this.ipAddress(),
        (params) => {
          if (params.instrumentData)
            params.instrumentData = PlatformConnection.mergeInstrumentData(params.instrumentData, this.state.instrumentData);
          this.setState(params);
        },
        this.props.webSocketError
      );
      this.platformConnection.connect();
    }
  }

  componentWillUnmount() {
    if (this.platformConnection)
      this.platformConnection.disconnect();
  }

  ipAddress() {
    const ip = this.props.platformIpAddress;
    if (!ip)
      return null;
    if (/^((?:[0-9A-Fa-f]{1,4}))((?::[0-9A-Fa-f]{1,4}))*::((?:[0-9A-Fa-f]{1,4}))((?::[0-9A-Fa-f]{1,4}))*|((?:[0-9A-Fa-f]{1,4}))((?::[0-9A-Fa-f]{1,4})){7}$/g.test(ip)) {
      //IPV6
      return '[' + ip + ']';
    } else {
      return ip;
    }
  }

  monitorGroups(){
    if (!this.resources())
      return null;
    let groups = this.resources().map(r => r.attributes['monitor-groups']).flat().filter((el, i, a) =>
      el !== null && i === a.indexOf(el)
    );
    if (groups.length > 0)
      groups.push('Default');
    return groups.reverse();
  }

  resolveCurrentMonitorGroup(){
    let params = queryString.parse(window.location.search);
    if (!params.group)
      return "Default";
    else
      return params.group;
  }

  handleMonitorGroupChange(grp){
    let params = queryString.parse(window.location.search);
    params.group = grp;
    // Update the url without triggering page refresh
    let search = '?' + queryString.stringify(params);
    const hashParts = window.location.hash.split('#');
    if (hashParts.length > 1)
      search += '#' + hashParts.slice(-1)[0];
    window.history.replaceState(null, null, search);
    this.setState({currentMonitorGroup: grp});
  }

  currentSequenceId(){
    const platform = this.resource('platforms', this.props.platformId);
    if (this.state.currentSequenceId !== 0)
      return this.state.currentSequenceId;
    else if (platform && platform.included && platform.included['remote-platform-status'])
      return parseInt(platform.included['remote-platform-status'].attributes['current-sequence-id'], 10);
    else
      return null;
  }

  currentSequenceStepId(){
    const platform = this.resource('platforms', this.props.platformId);
    if (this.state.currentSequenceStepId !== 0)
      return this.state.currentSequenceStepId;
    else if (platform && platform.included && platform.included['remote-platform-status'])
      return parseInt(platform.included['remote-platform-status'].attributes['current-sequence-step-id'], 10);
    else
      return null;
  }

  remoteAttribute(instrument, name){
    if (instrument.included && instrument.included['remote-instrument-status'])
      return instrument.included['remote-instrument-status'].attributes[name];
    else
      return null;
  }

  instrumentValue(instrument){
    const instData = this.state.instrumentData.find(i => parseInt(i.id, 10) === parseInt(instrument.id, 10));
    if (!instData)
      return this.remoteAttribute(instrument, 'value');
    else
      return instData.value;
  }

  instrumentDataTime(instrument){
    const instData = this.state.instrumentData.find(i => parseInt(i.id, 10) === parseInt(instrument.id, 10));
    if (!instData)
      return this.remoteAttribute(instrument, 'data-time');
    else
      return instData['dataTime'];
  }

  instrumentAvgDataInterval(instrument){
    const instData = this.state.instrumentData.find(i => parseInt(i.id, 10) === parseInt(instrument.id, 10));
    if (!instData)
      return this.remoteAttribute(instrument, 'avg-data-interval');
    else
      return instData['avgDataInterval'];
  }

  isCurrentMonitorGroup(groups){
    if (this.state.currentMonitorGroup === "Default")
      return true;
    else if (groups && groups !== "" && groups.find(g => g === this.state.currentMonitorGroup))
      return true;
    else
      return false;
  }

  groupInstruments(group){
    return this.resources().filter(instrument => (
      this.isCurrentMonitorGroup(instrument.attributes['monitor-groups']) &&
      instrument.attributes['instrument-group'] === group
    ));
  }

  instrumentsForWidget(widgetType, group){
    return this.groupInstruments(group).filter(instrument => (
      InstrumentWidget.resolveWidgetType(instrument) === widgetType
    ));
  }

  instrumentGroups(){
    let groups = this.resources().map(r => r.attributes['instrument-group']);
    groups = groups.filter((el, i, a) => el !== null && el !== '' && i === a.indexOf(el)); // remove empties and duplicates
    return groups;
  }

  render() {
    const instrumentWidgets = (type, group) => {
      return (
        this.instrumentsForWidget(type, group).map(instrument => (
          <InstrumentWidget key={instrument.id}
                            instrument={instrument}
                            value={this.instrumentValue(instrument)}
                            platformConnection={this.platformConnection}
                            platformIpAddress={this.ipAddress()}
                            dataTime={this.instrumentDataTime(instrument)}
                            avgDataInterval={this.instrumentAvgDataInterval(instrument)}/>
        ))
      );
    };
    const monitorInstruments = (group) => {
      if (this.groupInstruments(group).length > 0){
        return (
          <Panel key={group} className='instrument-panel'>
            {group &&
            <Panel.Heading>
              {group}
            </Panel.Heading>}
            <Panel.Body>
              {InstrumentWidget.widgetTypes().map(type => instrumentWidgets(type, group))}
            </Panel.Body>
          </Panel>
        )
      } else {
        return "";
      }
    };
    return (
      <div className="monitoring-component">
        {this.monitorGroups() &&
        <ul className="monitor-group-selector">
          {this.monitorGroups() && this.monitorGroups().map(grp => (
            <li key={grp}>
              <Button className={this.state.currentMonitorGroup === grp ? 'btn-primary' : 'btn-default'}
                      onClick={() => { this.handleMonitorGroupChange(grp) }}>{grp}</Button>
            </li>
          ))}
        </ul>}
        {this.props.showAutomationControl &&
        <PhaseTable platformId={this.props.platformId}
                    sendData={data => this.platformConnection.sendData(data)}
                    currentSequenceId={this.currentSequenceId()}
                    currentSequenceStepId={this.currentSequenceStepId()}/>}
        <Camera platformIpAddress={this.ipAddress()} platformId={parseInt(this.props.platformId, 10)}/>
        {this.instrumentGroups().length > 0 && this.instrumentGroups().map(group => monitorInstruments(group))}
        {monitorInstruments(null)}
        <ResourceListPagination resourceList={this}/>
      </div>
    );
  }
}

Monitoring.propTypes = {
  platformId: PropTypes.string,
  platformIpAddress: PropTypes.string,
  showAutomationControl: PropTypes.bool
};

const mapDispatchToProps = (dispatch) => {
  return {
    webSocketError: () => {
      setTimeout(() => document.location.reload(), 60000);
      dispatch(webSocketError("Monitoring disabled due to WebSocket error! The page will automatically reload within 60 seconds..."))
    }
  };
};

export default ResourceList.connect(null, mapDispatchToProps)(Monitoring);