import React from 'react';
import ResourceForm from '../../resource_form';
import { withRouter } from 'react-router-dom';
import ResourceFormHeader from "../../resource_form_header";
import ResourceFormFooter from "../../resource_form_footer";
import ResourceFormInput from "../../resource_form_input";
import ResourceFormSelect from "../../resource_form_select";
import ResourceFormCheckbox from "../../resource_form_checkbox";
import ResourceFormDateTime from "../../resource_form_date_time";

class SequenceForm extends ResourceForm {
  static get apiType() {
    return 'sequences';
  }

  platformId(){
    return this.props.match && this.props.match.params.platformId;
  }

  redirectAfterCreate(){
    return "/platforms/" + this.platformId() + "#sequences";
  }

  createRelationships(){
    return {platform: {data: {type: 'platforms', id: this.platformId()}}};
  }

  timerEnabled(){
    return this.resolveValue('start-date') && this.resolveValue('start-date') !== '';
  }

  render() {
    return (
      <div className="sequence-form-component">
        <form className="horizontal">
          <ResourceFormHeader resourceForm={this}/>
          <ResourceFormInput resourceForm={this} name="name"/>
          <ResourceFormCheckbox resourceForm={this} name="active"/>
          <ResourceFormCheckbox resourceForm={this} name="run-on-start" label="Run on start"/>
          <ResourceFormDateTime resourceForm={this} name="start-date" label="Timer"/>
          <ResourceFormInput resourceForm={this} name="interval-value" label="Repeat interval" type="number" disabled={!this.timerEnabled()}/>
          <ResourceFormSelect resourceForm={this} name="interval-unit" collection={['hours', 'days', 'weeks', 'months', 'years']} labelAttribute="" valueAttribute="" label="Repeat interval unit" disabled={!this.timerEnabled()}/>
          <ResourceFormFooter resourceForm={this}/>
        </form>
      </div>
    );
  }
}

export default withRouter(ResourceForm.connect()(SequenceForm));