import React from 'react';
import {Link} from "react-router-dom";
import PropTypes from 'prop-types';
import {Glyphicon} from "react-bootstrap";

class NewResourceLink extends React.Component{
  render(){
    if (this.props.disabled)
      return '';
    return(
      <Link className="new-resource-link-component" to={this.props.to}>
        <Glyphicon glyph="plus"/>
        &nbsp;
        {this.props.children}
      </Link>
    )
  }
}

NewResourceLink.propTypes = {
  to: PropTypes.string,
  disabled: PropTypes.bool
};

export default NewResourceLink;