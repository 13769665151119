import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './app';
import Config from './config';
import { asyncDispatchMiddleware } from "./middleware";

import './vendor/assets/stylesheets/fonts.css';
import './vendor/assets/stylesheets/react-datetime.css';
import './assets/stylesheets/common.css';
import './modules/admin/assets/stylesheets/admin.css';
import './modules/data/assets/stylesheets/data.css';
import './modules/platforms/assets/stylesheets/platforms.css';
import './modules/public/assets/stylesheets/public.css';

import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import { setAxiosConfig } from 'redux-json-api';
import { reducer as api } from 'redux-json-api';
import { auth, flash } from './reducers';

const reducer = combineReducers({
  api, auth, flash
});

const store = createStore(reducer, applyMiddleware(thunk, asyncDispatchMiddleware));

const jwt = localStorage.getItem('jwt');

store.dispatch(setAxiosConfig({
  baseURL: Config.jsonApiUri,
  headers: (jwt !== undefined ? {'Authorization': 'Bearer ' + jwt} : {})
}));

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);
