import React from "react";
import PropTypes from 'prop-types';
import { FormGroup, ControlLabel, HelpBlock } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
//import 'react-bootstrap-typeahead/css/Typeahead-bs4.css';

class ResourceFormTypeahead extends React.Component {
  constructor(props){
    super(props);
    this.onBlur = this.onBlur.bind(this);
    this.typeahead = null;
  }

  resolveLabel(){
    if (this.props.label)
      return this.props.label;
    return (this.props.name.charAt(0).toUpperCase() + this.props.name.slice(1)).replace('-',' ');
  }

  handleChange(values){
    if (this.props.onChange !== null){
      this.props.onChange(values);
      return;
    }

    values = values.map(v => {
      if (v && v.customOption)
        return v.label;
      else
        return v;
    });


    if (!this.props.multiple){
      if (values.length > 0)
        values = values[0];
      else
        values = '';
    }

    this.props.resourceForm.handleInputChange({target: {name: this.props.name, value: values}});
  }

  handleInputChange(value){
    if (this.props.onInputChange !== null)
      this.props.onInputChange(value);
  }

  selected(){
    const value = this.props.resourceForm.resolveValue(this.props.name);
    if (Array.isArray(value))
      return value;
    else if (value)
      return [value];
    else
      return [];
  }

  onBlur(event){
    const value = event.target.value;
    if (value){
      if (!this.props.multiple)
        this.props.resourceForm.handleInputChange({target: {name: this.props.name, value: value}});
      else {
        this.typeahead.getInstance().clear();
        const opts = this.props.resourceForm.resolveValue(this.props.name);
        this.props.resourceForm.handleInputChange({target: {name: this.props.name, value: [...opts, value]}});
      }
    }
  }

  render() {
    const form = this.props.resourceForm;
    const name = this.props.name;
    return (
      <FormGroup className="resource-form-input-component" controlId={name} validationState={form.resolveError(name) ? 'error' : null}>
        <ControlLabel>{this.resolveLabel()}</ControlLabel>
        <Typeahead ref={typeahead => this.typeahead = typeahead} onBlur={this.onBlur} allowNew={this.props.allowNew} multiple={this.props.multiple} id={"typeahead-for-" + name} clearButton selected={this.selected()} onInputChange={this.handleInputChange.bind(this)} onChange={this.handleChange.bind(this)} disabled={this.props.disabled} placeholder={this.props.placeholder} options={this.props.options}/>
        {form.resolveError(name) &&
        <HelpBlock>{form.resolveError(name).title}</HelpBlock>
        }
      </FormGroup>
    )
  }
}

ResourceFormTypeahead.propTypes = {
  resourceForm: PropTypes.object,
  name: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  onInputChange: PropTypes.func,
  onChange: PropTypes.func,
  multiple: PropTypes.bool,
  allowNew: PropTypes.bool
};

ResourceFormTypeahead.defaultProps = {
  disabled: false,
  placeholder: null,
  onInputChange: null,
  onChange: null,
  multiple: false,
  allowNew: true
};

export default ResourceFormTypeahead;