import React from 'react';
import { Link } from 'react-router-dom';
import DataList from './data_list';
import DataChart from './data_chart';
import DataPlot from './data_plot';
import DataAnalysisList from './data_analysis_list';
import { Tabs, Tab } from 'react-bootstrap';
import Breadcrumbs from "../../lib/breadcrumbs";
import UserGroupSelector from '../../user_group_selector';
import ResourceDetails from "../../resource_details";
import DataInstrumentsList from "./data_instruments_list";

class DataPlatformDetails extends ResourceDetails {
  static get apiType(){
    return 'data-platforms';
  }

  render() {
    return (
      <div className="data-platform-details-component">
        <Breadcrumbs>
          <Link to="/data-platforms">Data platforms</Link>
          {this.resource() && this.resource().attributes.name}
        </Breadcrumbs>
        <Tabs defaultActiveKey={this.resolveTab('data')} id="data-platform-details-tabs" onSelect={this.handleTabSelect} mountOnEnter={true} unmountOnExit={true}>
          <Tab eventKey='data' title="Data list">
            <DataList dataPlatformId={this.resourceId()} dataPlatformName={this.resource() && this.resource().attributes.name}></DataList>
          </Tab>
          {this.resource() && this.resource().canIndex('data-analyses') &&
          <Tab eventKey='data-analyses' title="Data analyses">
            <DataAnalysisList dataPlatformId={this.resourceId()} canCreate={this.resource() && this.resource().canCreate('data-analyses')}></DataAnalysisList>
          </Tab>}
          <Tab eventKey='data-plot' title="Plot on map">
            <DataPlot dataPlatformId={this.resourceId()}></DataPlot>
          </Tab>
          <Tab eventKey='data-chart' title="Chart">
            <DataChart dataPlatformId={this.resourceId()}></DataChart>
          </Tab>
          <Tab eventKey='data-instruments' title="Instruments">
            <DataInstrumentsList dataPlatformId={this.resourceId()}></DataInstrumentsList>
          </Tab>
          {this.props.currentUser.admin &&
          <Tab eventKey='user-groups' title="User groups">
            <UserGroupSelector dataPlatformId={this.resourceId()}></UserGroupSelector>
          </Tab>}
        </Tabs>
      </div>
    );
  }
}

export default ResourceDetails.connect()(DataPlatformDetails);