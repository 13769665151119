import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Table, Button } from 'react-bootstrap';
import ResourceList from "../../resource_list"
import Confirm from 'react-confirm-bootstrap';
import ResourceListPagination from "../../resource_list_pagination";
import NewResourceLink from "../../lib/new_resource_link";

class UserList extends ResourceList {
  static get apiType(){
    return 'users';
  }

  defaultQueryParams(){
    return {
      include: ['roles.user-group.organization']
    }
  }

  organizations(user){
    if (! user.included || !user.included['roles'])
      return [];
    let orgs = [];
    user.included['roles'].forEach(role => {
      if (! role.included && role.included['user-group'])
        return;
      if (! role.included['user-group'].included && role.included['user-group'].included['organization'])
        return;
      const org = role.included['user-group'].included['organization'].attributes.name;
      if (orgs.indexOf(org) === -1)
        orgs.push(org);
    });
    return orgs;
  }

  render() {
    return (
      <div className="user-list-component">
        <NewResourceLink to={`admin/${this.constructor.apiType}/new`}>New user</NewResourceLink>
        <Table responsive hover>
          <thead>
            <tr>
              <th>Name</th><th>Email</th><th>Organizations</th><th></th>
            </tr>
          </thead>
          <tbody>
            {this.resources().map(resource => (
              <tr key={resource.id}>
                <td>{resource.attributes.name}</td>
                <td>{resource.attributes.email}</td>
                <td>{this.organizations(resource).join(', ')}</td>
                <td className="actions">
                  <Link to={`admin/${this.constructor.apiType}/${resource.id}/edit`}>
                    <i className="action-icon glyphicon glyphicon-pencil"></i>
                  </Link>
                  <Confirm
                    onConfirm={() => {this.handleResourceDelete(resource.id)}}
                    body={`Are you sure you want to delete ${resource.attributes['name']}?`}
                    confirmText="Confirm delete"
                    title="Delete user">
                    <Button className="btn-link">
                      <i className="action-icon glyphicon glyphicon-trash"></i>
                    </Button>
                  </Confirm>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <ResourceListPagination resourceList={this}/>
      </div>
    );
  }
}

export default withRouter(ResourceList.connect()(UserList));