import React from 'react';
import PropTypes from 'prop-types';

class GaugeAnalog extends React.Component{

  constructor(props){
    super(props);
    this.range = Math.PI * 1.45;
    this._frameId = null;
    this.lastAnimationTime = null;
    this.loop = this.loop.bind(this);
    this.state = {currentNeedleAngle: this.needleAngleForValue(this.minValue())};
  }

  componentWillUnmount() {
    this.stopLoop();
  }

  componentWillMount() {
    this.startLoop();
  }

  componentWillReceiveProps(nextProps, nextContext){
    this.startLoop();
  }

  startLoop() {
    if( this._frameId === null ) {
      this._frameId = window.requestAnimationFrame( this.loop );
    }
  }

  loop() {
    const now = Date.now();
    const target = this.needleAngleForValue(this.props.value);

    if (this.lastAnimationTime === null)
      this.lastAnimationTime = now;

    const speed = Math.max(Math.abs(this.state.currentNeedleAngle - target), 0.05);
    const d = (now - this.lastAnimationTime) * 0.005 * speed;
    this.lastAnimationTime = now;

    // perform loop work here
    if (this.state.currentNeedleAngle < target)
      this.setState({currentNeedleAngle: Math.min(target, this.state.currentNeedleAngle + d)});
    else if (this.state.currentNeedleAngle > target)
      this.setState({currentNeedleAngle: Math.max(target, this.state.currentNeedleAngle - d)});

    // Set up next iteration of the loop
    if (this.state.currentNeedleAngle === target)
      this.stopLoop();
    else
      this._frameId = window.requestAnimationFrame( this.loop );
  }

  stopLoop() {
    if (this._frameId !== null){
      window.cancelAnimationFrame( this._frameId );
      this._frameId = null;
      this.lastAnimationTime = null;
    }
    // Note: no need to worry if the loop has already been cancelled
    // cancelAnimationFrame() won't throw an error
  }

  minValue(){
    return this.props.markerValues[0];
  }

  maxValue(){
    return this.props.markerValues[this.props.markerValues.length - 1];
  }

  needleAngleForValue(value){
    let scale = (value - this.minValue()) / (this.maxValue()  - this.minValue());
    scale = Math.max(scale, 0);
    scale = Math.min(scale, 1);
    return scale * this.range - this.range * 0.5 - Math.PI * 0.5;
  }

  needlePoints(angle){
    const x1 = Math.cos(angle) * this.props.size * 0.35 + this.props.size * 0.5;
    const y1 = Math.sin(angle) * this.props.size * 0.35 + this.props.size * 0.5;
    const x2 = Math.cos(angle + Math.PI * 0.5) * this.props.size * 0.025 + this.props.size * 0.5;
    const y2 = Math.sin(angle + Math.PI * 0.5) * this.props.size * 0.025 + this.props.size * 0.5;
    const x3 = Math.cos(angle - Math.PI * 0.5) * this.props.size * 0.025 + this.props.size * 0.5;
    const y3 = Math.sin(angle - Math.PI * 0.5) * this.props.size * 0.025 + this.props.size * 0.5;
    return [Math.round(x1), Math.round(y1), Math.round(x2), Math.round(y2), Math.round(x3), Math.round(y3)];
  }

  markerLine(value, length){
    const angle = this.needleAngleForValue(value);
    const x1 = Math.cos(angle) * this.props.size * 0.485 + this.props.size * 0.5;
    const y1 = Math.sin(angle) * this.props.size * 0.485 + this.props.size * 0.5;
    const x2 = Math.cos(angle) * this.props.size * (0.485 - length * this.props.size * 0.00025) + this.props.size * 0.5;
    const y2 = Math.sin(angle) * this.props.size * (0.485 - length * this.props.size * 0.00025) + this.props.size * 0.5;
    return {x1: x1, y1: y1, x2: x2, y2: y2};
  }

  markerTextPosition(value){
    const angle = this.needleAngleForValue(value);
    const x = Math.cos(angle) * this.props.size * 0.4 + this.props.size * 0.5;
    const y = Math.sin(angle) * this.props.size * 0.4 + this.props.size * 0.54;
    return {x: x, y: y};
  }

  markerTextAnchor(value){
    const angle = this.needleAngleForValue(value);
    if (angle < -2.25)
      return 'start';
    else if (angle > -1.1)
      return 'end';
    else
      return 'middle';
  }

  subMarkerValues(){
    let values = [];
    for (let i = 0; i < this.props.markerValues.length - 1; i ++){
      values.push((this.props.markerValues[i + 1] - this.props.markerValues[i]) * 0.5 + this.props.markerValues[i]);
    }
    return values;
  }

  redlineValues(){
    let values = [];
    const d = (this.maxValue() - this.minValue()) / 100;
    for (let v = this.minValue(); v < this.maxValue(); v += d){
      if (this.props.rangeMin != null && v < this.props.rangeMin)
        values.push(v);
      else if (this.props.rangeMax != null && v > this.props.rangeMax)
        values.push(v);
    }
    return values;
  }

  roundedValue(){
    if (this.props.value === null || isNaN(parseFloat(this.props.value)))
      return <i>&nbsp;</i>;
    return Math.ceil(this.props.value * 100) / 100;
  }

  valueIsOffRange(){
    if (this.props.rangeMin && this.props.value < this.props.rangeMin)
      return true;
    else if (this.props.rangeMax && this.props.value > this.props.rangeMax)
      return true;
    return false;
  }

  render(){
    return (
      <svg height={this.props.size} width={this.props.size}>
        <circle cx={this.props.size * 0.5} cy={this.props.size * 0.5} r={this.props.size * 0.49} stroke="#666" strokeWidth="2" fill="white" />
        <rect width={this.props.size * 0.4} height={this.props.size * 0.12} x={this.props.size * 0.3} y={this.props.size * 0.6}/>
        <text fontSize={this.props.size * 0.09} fill={this.valueIsOffRange() ? "#dd0000" : "#00dd00"} style={{fontWeight: 'bold'}} textAnchor="middle" x={this.props.size * 0.5} y={this.props.size * 0.691}>{this.roundedValue()}</text>
        <text fontSize={this.props.size * 0.06} fill="#aaa" textAnchor="middle" x={this.props.size * 0.5} y={this.props.size * 0.35}>{this.props.parameter}</text>
        {this.redlineValues().map(v => (
          <line key={'redline' + v} x1={this.markerLine(v, 0.8).x1} y1={this.markerLine(v, 0.8).y1} x2={this.markerLine(v, 0.8).x2} y2={this.markerLine(v, 0.8).y2} stroke="red" strokeWidth="3"/>
        ))}
        {this.props.markerValues.map(v => (
          <line key={'marker' + v} x1={this.markerLine(v, 1).x1} y1={this.markerLine(v, 1).y1} x2={this.markerLine(v, 1).x2} y2={this.markerLine(v, 1).y2} stroke="black" strokeWidth="2"/>
        ))}
        {this.subMarkerValues().map(v => (
          <line key={'submarker' + v} x1={this.markerLine(v, 0.8).x1} y1={this.markerLine(v, 0.8).y1} x2={this.markerLine(v, 0.8).x2} y2={this.markerLine(v, 0.8).y2} stroke="black" strokeWidth="1"/>
        ))}
        {this.props.markerValues.map(v => (
          <text key={'label' + v} textAnchor={this.markerTextAnchor(v)} x={this.markerTextPosition(v).x} y={this.markerTextPosition(v).y}>{v}</text>
        ))}
        <polygon points={this.needlePoints(this.state.currentNeedleAngle)} fill="#f00" stroke="#600" strokeWidth="1" />
        <circle cx={this.props.size * 0.5} cy={this.props.size * 0.5} r={this.props.size * 0.05} stroke="black" strokeWidth="1" fill="grey" />
        <text textAnchor="middle" x={this.props.size * 0.5} y={this.props.size * 0.87}>{this.props.unit}</text>
      </svg>
    )
  }
}

GaugeAnalog.propTypes = {
  size: PropTypes.number,
  value: PropTypes.any,
  markerValues: PropTypes.array,
  unit: PropTypes.string,
  rangeMin: PropTypes.number,
  rangeMax: PropTypes.number,
  parameter: PropTypes.string
};

export default GaugeAnalog;