import React from 'react';
import PropTypes from 'prop-types';
import {Link, withRouter} from "react-router-dom";
import { Table } from 'react-bootstrap';
import ResourceList from '../../resource_list';
import ResourceListPagination from "../../resource_list_pagination";

const apiType = 'data-instruments';

class DataInstrumentsList extends ResourceList {

  static get apiType(){
    return apiType;
  }

  defaultQueryParams() {
    return {
      filter: {
        'data-platform.id': this.props.dataPlatformId,
      },
      sort: ['name']
    }
  }

  render() {
    return (
      <div className="data-instruments-list-component">
        <Table responsive hover>
          <thead>
          <tr>
            <th>Name</th>
            <th>Public</th>
            <th>Public group</th>
            <th>Public name</th>
            <th>Public index</th>
            <th>Uncertainty</th>
            <th>Description</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          {this.resources().map(resource => (
            <tr key={resource.id}>
              <td>{resource.attributes['name']}</td>
              <td>{resource.attributes['public'] ? 'Yes' : 'No'}</td>
              <td>{resource.attributes['public-group']}</td>
              <td>{resource.attributes['public-name']}</td>
              <td>{resource.attributes['public-index-number']}</td>
              <td>
                {resource.attributes['uncertainty'] !== null && <>
                  {resource.attributes['uncertainty']}
                  &nbsp;
                  {resource.attributes['uncertainty-is-relative'] ? '%' : resource.attributes['unit']}
                </>}
              </td>
              <td>{resource.attributes['description']}</td>
              <td className="actions">
                {resource.canUpdate() &&
                <Link to={`${this.props.match.url}/data-instruments/${resource.id}/edit`}>
                  <i className="action-icon glyphicon glyphicon-pencil"></i>
                </Link>}
              </td>
            </tr>
          ))}
          </tbody>
        </Table>
        <ResourceListPagination resourceList={this}/>
      </div>
    );
  }
}

DataInstrumentsList.propTypes = {
  dataPlatformId: PropTypes.string
};

export default withRouter(ResourceList.connect()(DataInstrumentsList));
