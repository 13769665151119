import React from 'react';
import ResourceForm from '../../resource_form';
import _ from 'lodash';
import ResourceFormHeader from "../../resource_form_header";
import ResourceFormFooter from "../../resource_form_footer";
import ResourceFormInput from "../../resource_form_input";
import ResourceFormTypeahead from "../../resource_form_typeahead";
import InstrumentFormInputs from './instrument_form_inputs';

class InstrumentForm extends ResourceForm {
  static get apiType(){
    return 'instruments';
  }

  constructor(props){
    super(props);
    this.state.presetIds = [];
    this.state.instrumentGroups = [];
    this.state.monitorGroups = [];
  }

  platformId(){
    return this.props.match && this.props.match.params.platformId;
  }

  componentWillMount() {
    super.componentWillMount();
    this.props.loadResources('platforms/' + this.platformId() + '?include=remote-platform-instrument-info');
    this.loadInstrumentGroups();
    this.loadResourcesAndStoreIds('instrument-presets?page[size]=1000&sort=instrument-type', 'presetIds');
  }

  loadInstrumentGroups(){
    this.props.loadResources('instruments?page[size]=1000&filter[platform.id]=' + this.platformId()).then((response) => {
      let instrumentGroups = response.body.data.map(entity => entity.attributes['instrument-group']);
      instrumentGroups = instrumentGroups.filter(g => g !== null && g !== undefined && g !== ""); // remove nulls
      instrumentGroups = instrumentGroups.filter((el, i, a) => i === a.indexOf(el)); // remove duplicates
      let monitorGroups = response.body.data.map(entity => entity.attributes['monitor-groups']).flat();
      monitorGroups = monitorGroups.filter(g => g !== null && g !== undefined && g !== ""); // remove nulls
      monitorGroups = monitorGroups.filter((el, i, a) => i === a.indexOf(el)); // remove duplicates
      this.setState({instrumentGroups, monitorGroups});
    }).catch((errors) => {
      console.log("Unable to load instrument groups");
      console.log(errors);
    });
  }

  platform(){
    return this.resource('platforms', this.platformId());
  }

  freePins(){
    if (!this.platform() || !this.platform().included || !this.platform().included['remote-platform-instrument-info'])
      return [];
    return this.platform().included['remote-platform-instrument-info'].attributes['free-pins'] || [];
  }

  instrumentTypes(){
    const result = this.resources('instrument-presets', this.state.presetIds).map(ip => ip.attributes['instrument-type']);
    return result.filter((elem, pos, arr) => arr.indexOf(elem) === pos);
  }

  redirectAfterCreate() {
    return `/platforms/${this.platformId()}`;
  }

  createRelationships(){
    return {platform: {data: {type: 'platforms', id: this.platformId()}}};
  }

  resolveCharacter(instrumentType, key){
    const presets = this.resources('instrument-presets', this.state.presetIds);
    const availableType = presets.find(ip => ip.attributes['instrument-type'] === instrumentType);
    if (availableType)
      return availableType.attributes[key];
    else
      return null;
  }

  handleInstrumentTypeChange(value){
    if (Array.isArray(value))
      value = value[0];
    //console.log(value);
    const attributes = _.cloneDeep(this.state.attributes);
    attributes['instrument-type'] = value || null;
    attributes['parameter'] = this.resolveCharacter(value, 'parameter');
    attributes['unit'] = this.resolveCharacter(value, 'unit');
    this.setState({attributes: attributes});
  }

  render() {
    return (
      <div className="instrument-form-component">
        <form className="horizontal">
          <ResourceFormHeader resourceForm={this}/>
          <ResourceFormInput name="name" resourceForm={this}/>
          <ResourceFormTypeahead name="instrument-type" options={this.instrumentTypes()} label="Type" disabled={this.resourceId() ? true : false} resourceForm={this} allowNew={false} onChange={this.handleInstrumentTypeChange.bind(this)}/>
          <ResourceFormInput name="parameter" resourceForm={this} disabled={true}/>
          <ResourceFormInput name="unit" resourceForm={this} disabled={true}/>
          <ResourceFormTypeahead name="instrument-group" resourceForm={this} options={this.state.instrumentGroups}/>
          <ResourceFormTypeahead multiple name="monitor-groups" resourceForm={this} options={this.state.monitorGroups}/>
          <InstrumentFormInputs resourceForm={this} freePins={this.freePins()}/>
          <ResourceFormFooter resourceForm={this}/>
        </form>
      </div>
    );
  }
}

export default ResourceForm.connect()(InstrumentForm);
