import React from 'react';
import PropTypes from 'prop-types';
import { Glyphicon, Table } from 'react-bootstrap';
import ResourceList from './resource_list';
import ResourceListPagination from "./resource_list_pagination";
import Time from './lib/time';
import ChangeLogFilter from "./change_log_filter";

const apiType = 'versions';

class ChangeLog extends ResourceList {
  static get apiType(){
    return apiType;
  }

  defaultQueryParams() {
    let params = {
      filter: {
        'platform.id': this.props.platformId,
      },
      sort: ['-created-at']
    };
    if (this.props.itemId && this.props.itemType){
      params.filter.item = this.props.itemType + '-' + this.props.itemId;
    }
    return params;
  }

  constructor(props){
    super(props);
    this.state.details = [];
  }

  toggleDetails(id){
    if (this.state.details.indexOf(id) !== -1)
      this.setState({details: this.state.details.filter(sid => sid !== id)});
    else
      this.setState({details: this.state.details.concat(id)});
  }

  onFilterChange(params){
    this.loadResources({filter: params, currentPage: 1});
  }

  filterShouldBeOpen(){
    let threshold = 1;
    if (this.props.itemId && this.props.itemType)
      threshold += 1;
    return Object.keys(this.state.queryParams.filter).length > threshold;
  }

  render() {
    const formattedValue = (value, key) => {
      let type = 'default';
      if (key === 'updated_at' || key === 'created_at')
        type = 'time';
      return (
        <span>
          {type === 'time' &&
          <Time>{value}</Time>}
          {type === 'default' && value}
        </span>
      )
    };
    const changes = (resource) => {
      const data = JSON.parse(resource.attributes['object-changes']);
      return (
        <dl className="dl-horizontal">
        {Object.keys(data).map(key => (
          <React.Fragment key={key}>
            <dt className="attribute">{key}:&nbsp;</dt>
            <dd>
              <span className="old-value">{formattedValue(data[key][0], key)}</span>
              {resource.attributes['event'] === 'update' &&
              <Glyphicon glyph="arrow-right"/>}
              <span className="new-value">{formattedValue(data[key][1], key)}</span>
            </dd>
          </React.Fragment>
        ))}
        </dl>
      )
    };
    return (
      <div className="change-log-component">
        <ChangeLogFilter filterParams={this.state.queryParams.filter} onChange={this.onFilterChange.bind(this)} showInitially={this.filterShouldBeOpen()}/>
        <Table responsive hover>
          <thead>
          <tr>
            <th>Time <Time.Zone/></th>
            <th>User</th>
            <th>Event</th>
            {!this.state.queryParams.filter.item &&
            <React.Fragment>
              <th>Subject type</th>
              <th>Subject name</th>
            </React.Fragment>}
            <th></th>
          </tr>
          </thead>
          {this.resources().map(resource => (
            <tbody key={resource.id} className={this.state.details.indexOf(resource.id) !== -1 ? "active" : ""}>
              <tr className="object-row" onClick={() => {this.toggleDetails(resource.id)}}>
                <td><Time>{resource.attributes['created-at']}</Time></td>
                <td>{resource.attributes['whodunnit']}</td>
                <td><span className={resource.attributes['event']}>{resource.attributes['event']}</span></td>
                {!this.state.queryParams.filter.item &&
                  <React.Fragment>
                  <td>{resource.attributes['item-type']}</td>
                  <td>{resource.attributes['item-name']}</td>
                  </React.Fragment>}
                <td className="actions">
                  {this.state.details.indexOf(resource.id) !== -1 &&
                  <i className="action-icon glyphicon glyphicon-chevron-up"></i>}
                  {this.state.details.indexOf(resource.id) === -1 &&
                  <i className="action-icon glyphicon glyphicon-chevron-right"></i>}
                </td>
              </tr>
              <tr className="object-changes">
                <td colSpan={this.state.queryParams.filter.item ? 4 : 6}>
                  <div className="object-changes-container">
                    {changes(resource)}
                  </div>
                </td>
              </tr>
            </tbody>
          ))}
        </Table>
        <ResourceListPagination resourceList={this}/>
      </div>
    );
  }
}

ChangeLog.propTypes = {
  platformId: PropTypes.any,
  itemId: PropTypes.any,
  itemType: PropTypes.any
};

export default ResourceList.connect()(ChangeLog);