import React from 'react';
import ResourceForm from '../../resource_form';
import ResourceFormInput from "../../resource_form_input";
import ResourceFormFooter from "../../resource_form_footer";
import ResourceFormHeader from "../../resource_form_header";

class MediaAccountForm extends ResourceForm {
  static get apiType(){
    return 'media-accounts';
  }

  render() {
    return (
      <div className="media-account-form-component">
        <form>
          <ResourceFormHeader resourceForm={this}/>
          <ResourceFormInput name='name' resourceForm={this}/>
          <ResourceFormInput name="password" type="password" resourceForm={this}/>
          <ResourceFormInput name="password-confirmation" type="password" resourceForm={this}/>
          <ResourceFormFooter resourceForm={this}/>
        </form>
      </div>
    );
  }
}

export default ResourceForm.connect()(MediaAccountForm);