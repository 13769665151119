import React from 'react';
import ResourceForm from '../../resource_form';
import { Button } from 'react-bootstrap';
import { Link, Redirect, withRouter } from 'react-router-dom';
import ResourceFormHeader from "../../resource_form_header";
import ResourceFormInput from "../../resource_form_input";
import ResourceFormCheckbox from "../../resource_form_checkbox";

class InstrumentAlarmForm extends ResourceForm {
  static get apiType() {
    return 'instrument-alarms';
  }

  platformId(){
    return this.props.match && this.props.match.params.platformId;
  }

  instrumentId(){
    return this.props.match && this.props.match.params.instrumentId;
  }

  redirectAfterCreate() {
    return `/platforms/${this.platformId()}#instruments`;
  }

  createRelationships(){
    return {instrument: {data: {type: 'instruments', id: this.instrumentId()}}};
  }

  render() {
    return (
      <div className="instrument-alarm-form-component">
        <form>
          <ResourceFormHeader resourceForm={this}/>
          <ResourceFormInput resourceForm={this} name="range-value-min" label="Range min value"/>
          <ResourceFormInput resourceForm={this} name="range-value-max" label="Range max value"/>
          <ResourceFormInput resourceForm={this} name="alarm-threshold-time-mins" label="Off-range threshold time (minutes to alarm)"/>
          <ResourceFormCheckbox resourceForm={this} name="trend-alarm" label="Trend alarm"/>
          <ResourceFormInput resourceForm={this} name="email" label="Email for receiving alarm"/>
          <Link to={"/platforms/" + this.platformId() + "/details#instruments"} className="cancel-button">Cancel</Link>
          <Button className="pull-right" bsStyle="primary" onClick={this.handleSubmit}>
            {this.resourceId() &&
            <span>Save changes</span>
            }
            {!this.resourceId() &&
            <span>Set alarm</span>
            }
          </Button>
          &nbsp;
          {this.resourceId() &&
          <Button style={{marginRight: '5px'}} className="pull-right" bsStyle="danger" title="Remove alarm" onClick={this.handleDelete}><i className="glyphicon glyphicon-trash"></i></Button>
          }
          {this.isSubmitted() &&
          <Redirect to={this.redirectAfterCreate()}/>}
        </form>
      </div>
    );
  }
}

export default withRouter(ResourceForm.connect()(InstrumentAlarmForm));