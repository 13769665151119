import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { readEndpoint, deleteResource, updateResource } from 'redux-json-api';
import _ from 'lodash';
import queryString from 'query-string';
import Resource from "./lib/resource";

export default class ResourceList extends React.Component {

  static get apiType() {
    // Override to set JSON API endpoint
    return null;
  }
  
  // Override this to define base query that cannot change == will not be in url queryParams
  // Options: 'filter', 'include', 'sort', 'pageSize', 'currentPage'
  defaultQueryParams() {
    return {};
  }

  urlQueryParams() {
    let params = queryString.parse(window.location.search);
    if (params.filter)
      params.filter = JSON.parse(params.filter);
    if (params.sort)
      params.sort = JSON.parse(params.sort);
    return params;
  }

  constructor(props){
    super(props);

    this.handleResourceDelete = this.handleResourceDelete.bind(this);
    this.setPage = this.setPage.bind(this);
    this.state = {loading: false, resourceIds: [], recordCount: null, queryParams: {pageSize: 50, currentPage: 1, filter: {}, include: [], sort: []}}
  }

  setPage(page){
    let params = this.urlQueryParams();
    params.currentPage = page;
    this.loadResources(params);
  }

  currentPage(){
    return parseInt(this.state.queryParams.currentPage, 10);
  }

  pageCount(){
    if (this.state.recordCount == null)
      return null;
    return Math.ceil(this.state.recordCount / this.state.queryParams.pageSize);
  }

  handleResourceDelete(ev){
    // NOTE! ev can be either event from click OR id string
    // NOTE! If id is parsed as int, then resource WILL NOT BE REMOVED from redux store
    let id;

    if (ev.target !== undefined)
      id = ev.target.dataset.id;
    else
      id = ev;

    return this.props.deleteResource(this.constructor.apiType, id);
  }

  componentWillMount(){
    this.loadResources(this.urlQueryParams());
  }

  updateUrlQueryParams(queryParams){
    let params = Object.assign({}, queryParams);
    if (params.filter)
      params.filter = JSON.stringify(params.filter);
    if (params.sort)
      params.sort = JSON.stringify(params.sort);
    // Update the url without triggering page refresh
    let search = '?' + queryString.stringify(params);
    const hashParts = window.location.hash.split('#');
    if (hashParts.length > 1)
      search += '#' + hashParts.slice(-1)[0];
    window.history.replaceState(null, null, search);
  }

  setQueryParams(params){
    let queryParams = _.cloneDeep(this.state.queryParams);

    ['filter', 'include', 'sort', 'pageSize', 'currentPage'].forEach((key) => {
      if (params[key])
        queryParams[key] = params[key];
    });

    _.merge(queryParams, this.defaultQueryParams());

    this.setState({queryParams});
    return queryParams;
  }

  buildQuery(endPoint, queryParams){
    let filterStr = '';
    let includeStr = '';
    let sortStr = '';
    let pageNumberStr = 'page[number]=' + queryParams.currentPage;
    let pageSizeStr = 'page[size]=' + queryParams.pageSize;

    // Filters
    if (queryParams.filter){
      let filterArray = [];
      Object.keys(queryParams.filter).forEach((key) => {
        filterArray.push('filter[' + key + ']=' + encodeURIComponent(queryParams.filter[key]));
      });
      filterStr = filterArray.join('&');
    }

    // Includes
    if (queryParams.include && queryParams.include.length > 0){
      includeStr = 'include=' + queryParams.include.join(',');
    }

    // Sort
    if (queryParams.sort && queryParams.sort.length > 0){
      sortStr = 'sort=' + queryParams.sort.join(',');
    }

    const opts = [filterStr, includeStr, sortStr, pageNumberStr, pageSizeStr];
    return endPoint + '?' + opts.filter(str => str !== '').join('&');
  }

  loadResources(params = {}){
    this.updateUrlQueryParams(params);

    let queryParams = this.setQueryParams(params);

    const query = this.buildQuery(this.constructor.apiType, queryParams);

    this.setState({resourceIds: [], loading: true});
    return this.props.loadResources(query).then((response) => {
      const body = response && response.body;
      let recordCount = null;
      if (body.meta && body.meta['record-count'])
        recordCount = body.meta['record-count'];
      this.setState({resourceIds: body.data.map(entity => entity.id), recordCount: recordCount, loading: false});
    }).catch((errors) => {
      this.setState({loading: false})
      console.log("Unable to load JSON-API resources: " + query);
      console.log(errors);
    });
  }

  reloadResources(){
    this.loadResources(this.urlQueryParams());
  }

  resource(apiType, id){
    if (!this.props.resources)
      return null;
    if (this.props.resources[apiType])
      return(this.props.resources[apiType].find((res) => { return res.id === id }));
    else
      return null;
  }

  resources(){
    if (!this.props.resources || !this.props.resources[this.constructor.apiType])
      return [];
    return this.state.resourceIds.map(id => this.props.resources[this.constructor.apiType].find(r => r.id === id)).filter(r => r);
  }

  static mapStateToProps(state){
    return Resource.mapStateToResources(state);
  }

  static mapDispatchToProps = (dispatch) => {
    return {
      loadResources: (url) => {
        return dispatch(readEndpoint(url));
      },
      updateResource: (data) => {
        return dispatch(updateResource(data));
      },
      deleteResource: (apiType, id) => {
        const data = {
          type: apiType,
          id: id
        };
        return dispatch(deleteResource(data));
      }
    }
  };

  static connect(stateToProps = null, dispatchToProps = null){
    let mapState = null;
    let mapDispatch = null;
    if (!stateToProps)
      mapState = ResourceList.mapStateToProps;
    else
      mapState = (dispatch) => {
        let props = ResourceList.mapStateToProps(dispatch);
        return Object.assign(props, stateToProps(dispatch));
      };
    if (!dispatchToProps)
      mapDispatch = ResourceList.mapDispatchToProps;
    else
      mapDispatch = (dispatch) => {
        let props = ResourceList.mapDispatchToProps(dispatch);
        return Object.assign(props, dispatchToProps(dispatch));
      };
    return connect(mapState, mapDispatch);
  }
}

ResourceList.propTypes = {
  resources: PropTypes.object
};
