import React from 'react';
import ResourceList from '../../resource_list';
import WorldMap from '../../lib/world_map';
import { instrumentMapIcon } from './instrument_icon';
import { withRouter } from 'react-router-dom';
import {webSocketError} from "../../actions";
import PlatformConnection from "../../lib/platform_connection";

const apiType = 'platforms';

class PlatformMap extends ResourceList {

  static get apiType(){
    return apiType;
  }

  defaultQueryParams() {
    return {
      include: ['instruments']
    }
  }

  constructor(props){
    super(props);
    this.mapInteracted = this.mapInteracted.bind(this);
    this.state.loadedStatuses = [];
    this.state.instrumentData = [];
    this.state.mapInteraction = false;
    this.platformConnection = null;
  }

  loadRemoteStatuses(){
    if (this.state && this.state.resourceIds){
      this.state.resourceIds.forEach((id) => {
        setTimeout(()=>{this.loadRemoteStatus(id)}, Math.random() * 5000)
      });
    }
  }

  loadRemoteStatus(id){
    this.props.loadResources('remote-platform-statuses/' + id).then((status) => {
      if (status && status.body && status.body.data)
        this.setState({loadedStatuses: [...this.state.loadedStatuses, status.body.data.id]});
    });
  }

  componentWillMount(){
    this.loadResources().then(() => {
      this.loadRemoteStatuses();
    });
  }

  componentDidMount() {
    this.intervalHandle = setInterval(this.loadRemoteStatuses.bind(this), 15000);
  }

  componentDidUpdate() {
    if (!this.platformConnection && this.ipAddresses().length > 0){
      this.platformConnection = new PlatformConnection(
        this.ids(),
        this.ipAddresses(),
        (params) => {
          if (params.instrumentData)
            params.instrumentData = PlatformConnection.mergeInstrumentData(params.instrumentData, this.state.instrumentData);
          this.setState(params);
        },
        this.props.webSocketError
      );
      this.platformConnection.connect();
    }
  }

  componentWillUnmount() {
    if (this.platformConnection)
      this.platformConnection.disconnect();
    if (this.intervalHandle)
      clearInterval(this.intervalHandle);
  }

  remoteStatus(resource){
    if (this.state.loadedStatuses === null || this.state.loadedStatuses.indexOf(resource.id) === -1)
      return null;
    if (!this.props.resources || !this.props.resources['remote-platform-statuses'])
      return null;
    const status = this.props.resources['remote-platform-statuses'].find(s => parseInt(s.id, 10) === parseInt(resource.id, 10));
    if (status)
      return status.attributes['status'];
    else
      return null;
  }

  markers(){
    let markers = [];
    this.resources().forEach(resource =>{
      if (!resource.included || !resource.included['instruments'])
        return;
      resource.included['instruments'].forEach(instrument => {
        if (instrument.attributes.parameter !== 'Coordinates')
          return;

        let instrumentData = this.state.instrumentData.find(i => parseInt(i.id, 10) === parseInt(instrument.id, 10));
        let location = null;

        if (instrumentData)
          location = instrumentData.value;
        else
          location = instrument.attributes['default-value'];

        if (!location)
          return;

        let parts = location.split(',');
        let lat = parseFloat(parts[0]);
        let lng = parseFloat(parts[1]);

        if (isNaN(lat) || isNaN(lng))
          return;

        let status = this.remoteStatus(resource);
        let statusLabel;

        switch(status){
          case 'ok':
            statusLabel = 'Online';
            break;
          case 'offline':
            statusLabel = 'Offline';
            break;
          case 'ip_address_missing':
            statusLabel = 'No IP address';
            break;
          case 'data_integrity_error':
            statusLabel = 'Data';
            break;
          default:
            statusLabel = 'loading...';
        }

        const title = [
          'Platform: ' + resource.attributes.name,
          'Status: ' + statusLabel,
          instrument.attributes.name + ': ' + location + ' ' + instrument.attributes.unit
        ];

        const onClick = () => {
          //status === 'ok' ? this.props.history.push('/platforms/' + resource.id + '/details') : null;
          if (status === 'ok' && resource.canShow())
            this.props.history.push('/platforms/' + resource.id + '/details');
        };

        markers.push({
          lat: lat,
          lng: lng,
          icon: instrumentMapIcon(instrument, status),
          title: title.join("\n"),
          onClick: onClick
        });
      });
    });
    return markers;
  }

  ipAddress(platform) {
    const ip = platform.attributes['ip-address'];
    if (!ip)
      return null;
    if (/^((?:[0-9A-Fa-f]{1,4}))((?::[0-9A-Fa-f]{1,4}))*::((?:[0-9A-Fa-f]{1,4}))((?::[0-9A-Fa-f]{1,4}))*|((?:[0-9A-Fa-f]{1,4}))((?::[0-9A-Fa-f]{1,4})){7}$/g.test(ip)){
      //IPV6
      return '[' + ip + ']';
    } else {
      return ip;
    }
  }

  ipAddresses(){
    return this.resources().map(r => this.ipAddress(r)).filter(ip => ip);
  }

  ids(){
    return this.resources().map(r => r.id).filter(id => id);
  }

  shouldFitBounds(){
    return !this.state.mapInteraction && this.markers().length > 1;
  }

  mapInteracted(){
    if (!this.state.mapInteraction)
      this.setState({mapInteraction: true});
  }

  render() {
    return (
      <div className="platform-map-component">
        <WorldMap markers={this.markers()} fitBounds={this.shouldFitBounds()} onDragStart={this.mapInteracted} onZoomChanged={this.mapInteracted}/>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    webSocketError: () => {
      dispatch(webSocketError("Monitoring disabled due to WebSocket error! Reload the page to try to reconnect."))
    }
  }
};

export default withRouter(ResourceList.connect(null, mapDispatchToProps)(PlatformMap));