import React from 'react';
import PropTypes from 'prop-types';
import ResourceFormSelect from "../../resource_form_select";
import ResourceFormInput from "../../resource_form_input";
import WorldMap from "../../lib/world_map";
import {ControlLabel, FormControl, FormGroup} from "react-bootstrap";
import _ from 'lodash';
import ResourceFormCheckbox from "../../resource_form_checkbox";

class InstrumentFormInputs extends React.Component{

  constructor(props){
    super(props);
    this.handleTrendIntervalChange = this.handleTrendIntervalChange.bind(this);
    this.handleCharacteristicChange = this.handleCharacteristicChange.bind(this);
    this.oldType = null;
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.isNew() && this.oldType !== this.form().state.attributes['instrument-type']){
      this.updateWidgetType();
      this.oldType = this.form().state.attributes['instrument-type'];
    }
  }

  // All available widget types
  widgets(){
    return ['',
      'gauge_digital',
      'gauge_analog',
      'slider',
      'relay',
      'positioning',
      'compass',
      'g_force',
      'chat',
      'spectrum_graph'
    ];
  }

  // Resolve default widget using parameter etc.
  resolveWidget(){
    if (this.formValue('instrument-type') === '')
      return '';
    if (this.formValue('parameter') === 'Direction')
      return 'compass';
    else if (this.formValue('parameter') === 'Acceleration')
      return 'g_force';
    else if (this.formValue('parameter') === 'Coordinates')
      return 'positioning';
    else if (this.formValue('parameter') === 'Binary')
      return 'relay';
    else if (this.formValue('parameter') === 'Spectrum')
      return 'spectrum_graph';
    else
      return 'gauge_digital';
  }

  updateWidgetType(){
    let attributes = _.cloneDeep(this.form().state.attributes);
    let characteristics = attributes['characteristics'] || {};
    characteristics['type'] = this.resolveWidget();
    this.setDefaultWidgetCharacteristics(characteristics['type'], characteristics);
    attributes.characteristics = characteristics;
    this.form().setState({attributes: attributes});
  }

  isNew(){
    return this.form() && !this.form().resourceId();
  }

  form(){
    return this.props.resourceForm;
  }

  formValue(key){
    return this.form() && this.form().resolveValue(key);
  }

  characteristic(key){
    if (!this.formValue('characteristics'))
      return null;
    else {
      const v = this.formValue('characteristics')[key];
      if (key === 'type' && v === 'analog')
        return 'gauge_analog';
      else if (key === 'type' && v === 'digital')
        return 'gauge_digital';
      else
        return v;
    }
  }

  handleMapClick(event){
    const attributes = _.cloneDeep(this.form().state.attributes);
    attributes['default-value'] = event.latLng.lat() + ',' + event.latLng.lng();
    this.form().setState({attributes});
  }

  worldMapCenter(){
    if (this.worldMapMarkers().length > 0)
      return this.worldMapMarkers()[0];
    else
      return undefined;
  }

  worldMapMarkers(){
    if (this.formValue('default-value')) {
      const coords = this.formValue('default-value').split(',');
      return [{
        lat: parseFloat(coords[0]),
        lng: parseFloat(coords[1])
      }];
    } else
      return [];
  }

  renderInput(){
    if (this.characteristic('type') === 'positioning' && this.formValue('instrument-type') === 'Manual positioning')
      return this.positioningInput();
    else if (this.characteristic('type') === 'gauge_analog' || this.characteristic('type') === 'gauge_digital')
      return this.gaugeInput();
    else if (this.characteristic('type') === 'slider')
      return this.sliderInput();
    else if (this.characteristic('type') === 'g_force')
      return this.gForceInput();
    else if (this.characteristic('type') === 'spectrum_graph')
      return this.spectrumGraphInput();
    else
      return <div></div>;
  }

  isSystemInstrument(){
    return this.formValue('instrument-type') &&
      (this.formValue('instrument-type').indexOf('SYSTEM') !== -1 ||
      this.formValue('instrument-type') === 'Manual positioning');
  }

  trendIntervalValue(){
    let value = this.formValue('trend-interval-ms');
    if (value !== undefined && value !== null && value !== '')
      return parseInt(value) / (60 * 60000);
    else
      return '';
  }

  handleTrendIntervalChange(ev){
    const value = ev.target.value;
    const attributes = _.cloneDeep(this.form().state.attributes);
    if (value && value !== '')
      attributes['trend-interval-ms'] = parseFloat(value) * 60 * 60000;
    else
      attributes['trend-interval-ms'] = null;
    console.log(attributes);
    this.form().setState({attributes: attributes});
  }

  setDefaultWidgetCharacteristics(type, characteristics){
    if(type === 'g_force') {
      characteristics['value_max'] = 1;
      characteristics['value_steps'] = 2;
    } else if(type === 'gauge_analog'){
      characteristics['value_min'] = 0;
      characteristics['value_max'] = 100;
      characteristics['value_steps'] = 10;
    } else if (type === 'slider'){
      characteristics['value_min'] = 0;
      characteristics['value_max'] = 1023;
      characteristics['value_steps'] = 0;
      characteristics['value_scale'] = 0;
    }
  }

  handleCharacteristicChange(ev){
    const name = ev.target.name;
    const value = ev.target.value;
    const attributes = _.cloneDeep(this.form().state.attributes);
    //let characteristics = attributes['characteristics'] || {};
    let characteristics = this.formValue('characteristics') || {};
    characteristics[name] = value;
    // Set default values
    if (name === 'type'){
      this.setDefaultWidgetCharacteristics(value, characteristics);
    }
    attributes['characteristics'] = characteristics;
    this.form().setState({attributes: attributes});
  }

  inputLabel(key){
    return (key.charAt(0).toUpperCase() + key.slice(1)).replace('-',' ').replace('_',' ');
  }

  characteristicInput(name, klass = 'input', options = [], type = 'text', label = null){
    return (
      <FormGroup className="characteristic-input" controlId={name}>
        <ControlLabel>{label || this.inputLabel(name)}</ControlLabel>
        {klass === 'select' &&
        <FormControl componentClass="select" name={name} value={this.characteristic(name) !== null ? this.characteristic(name) : ''} onChange={this.handleCharacteristicChange}>
          {options.map(opt => <option key={"char-opt-" + opt} value={opt}>{this.inputLabel(opt)}</option>)}
        </FormControl>}
        {klass === 'input' &&
        <FormControl componentClass="input" type={type} name={name} value={this.characteristic(name) !== null ? this.characteristic(name) : ''} onChange={this.handleCharacteristicChange}/>}
      </FormGroup>
    );
  }

  spectrumGraphInput(){
    return (
      <div key="spectrum-graph-input">
        {this.characteristicInput('value_min', 'input', null, 'number')}
        {this.characteristicInput('value_max', 'input', null, 'number')}
      </div>
    )
  }

  sliderInput(){
    return (
      <div key="slider-input">
        {this.characteristicInput('value_min', 'input', null, 'number')}
        {this.characteristicInput('value_max', 'input', null, 'number')}
        {this.characteristicInput('value_steps', 'input', null, 'number')}
        {this.characteristicInput('value_scale', 'input', null, 'number')}
      </div>
    )
  }

  gaugeInput(){
    return (
      <div key="gauge-input">
        {this.characteristic('type') === 'gauge_analog' &&
        <div className="analog-gauge-input">
          {this.characteristicInput('value_min', 'input', null, 'number')}
          {this.characteristicInput('value_max', 'input', null, 'number')}
          {this.characteristicInput('value_steps', 'input', null, 'number')}
        </div>}
      </div>
    )
  }

  gForceInput(){
    return (
      <div key="g-force-input">
        {this.characteristicInput('value_max', 'input', null, 'number')}
        {this.characteristicInput('value_steps', 'input', null, 'number')}
      </div>
    )
  }

  positioningInput(){
    return (
      <div key="positioning">
        <ControlLabel>
          Location
        </ControlLabel>
        <WorldMap onClick={this.handleMapClick.bind(this)} markers={this.worldMapMarkers()} defaultCenter={this.worldMapCenter()}/>
        <ResourceFormInput name="default-value" label="Lat, Long" resourceForm={this.form()}/>
      </div>
    );
  }

  virtualInput(){
    return (
      <div key="virtual-input">
        <ResourceFormInput name="fetch-url" resourceForm={this.form()}/>
        <ResourceFormInput name="fetch-interval-sec" resourceForm={this.form()} label="Fetch interval (sec)"/>
        <ResourceFormInput name="fetch-reg-exp" resourceForm={this.form()} label="Fetch regular exp." placeholder="e.g. '\<tag\>(.*)\<\/tag\>'"/>
      </div>
    );
  }

  render(){
    return (
      <div className='instrument-form-inputs-component'>
        {this.formValue('instrument-type') !== '' && !this.isSystemInstrument() &&
        <ResourceFormCheckbox name="virtual" resourceForm={this.form()} disabled={!this.isNew()}/>}
        {this.formValue("virtual") && this.virtualInput()}
        {this.formValue('instrument-type') !== '' && !this.isSystemInstrument() && !this.formValue("virtual") &&
        <ResourceFormSelect name="pins" collection={this.props.freePins} valueAttribute='' labelAttribute='' resourceForm={this.form()} label={"Device ID"} disabled={this.isNew() ? false : true}/>}
        <ResourceFormInput name="value-formula" placeholder={this.formValue('value-formula') ? "" : "e.g. 'value * 2'"} resourceForm={this.form()}/>
        <ResourceFormInput name="trend-length" resourceForm={this.form()} type="number"/>
        <ResourceFormInput name="trend-interval-ms" resourceForm={this.form()} type="number" label="Trend interval (hours)" onChange={this.handleTrendIntervalChange} value={this.trendIntervalValue()}/>
        {this.characteristicInput("type", "select", this.widgets(), null, 'Widget')}
        {this.renderInput()}
      </div>
    );
  }
}

InstrumentFormInputs.propTypes = {
  freePins: PropTypes.array,
  resourceForm: PropTypes.object
};

export default InstrumentFormInputs;