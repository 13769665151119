import React from 'react';
import PropTypes from 'prop-types';
import {Panel, Button} from 'react-bootstrap';
import ResourceList from './resource_list';
import ResourceListPagination from "./resource_list_pagination";
import Time from './lib/time';
import NewResourceLink from "./lib/new_resource_link";
import {Link} from "react-router-dom";
import Confirm from "react-confirm-bootstrap";

const apiType = 'diary-entries';

class Diary extends ResourceList {
  static get apiType(){
    return apiType;
  }

  defaultQueryParams() {
    let params = {
      filter: {},
      sort: ['-created-at']
    };
    if (this.props.itemId && this.props.itemType === "Instrument"){
      params.filter['instrument.id'] = this.props.itemId;
    }
    return params;
  }

  render() {
    return (
      <div className="diary-component">
        <NewResourceLink to={this.props.baseUrl + "/new"}>New entry</NewResourceLink>
        {this.resources().map(resource => (
          <Panel>
            <Panel.Heading>
              <Time className="created-at">{resource.attributes['created-at']}</Time>
              {resource.attributes['updated-at'] && resource.attributes['updated-at'] !== resource.attributes['created-at'] &&
              <span className="updated-at">(updated: <Time>{resource.attributes['updated-at']}</Time>)</span>}
              &nbsp;-&nbsp;
              <span className="author">{resource.attributes['author']}</span>
              <div className="pull-right">
                {resource.canUpdate() &&
                <Link to={`${this.props.baseUrl}/${resource.id}/edit`}>
                  <i className="action-icon glyphicon glyphicon-pencil"></i>
                </Link>}
                {resource.canDestroy() &&
                <Confirm
                  onConfirm={() => {this.handleResourceDelete(resource.id)}}
                  body={`Are you sure you want to delete the entry?`}
                  confirmText="Confirm delete"
                  title="Delete platform">
                  <Button className="btn-link">
                    <i className="action-icon glyphicon glyphicon-trash"></i>
                  </Button>
                </Confirm>}
              </div>
            </Panel.Heading>
            <Panel.Body>
              {resource.attributes['content'].split("\n").map(line => <span>{line}<br/></span>)}
            </Panel.Body>
          </Panel>
        ))}
        <ResourceListPagination resourceList={this}/>
      </div>
    );
  }
}

Diary.propTypes = {
  itemId: PropTypes.any,
  itemType: PropTypes.any,
  baseUrl: PropTypes.string
};

export default ResourceList.connect()(Diary);