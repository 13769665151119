import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Table, Button } from 'react-bootstrap';
import ResourceList from '../../resource_list';
import { updateResource } from 'redux-json-api';
import ajaxLoader from '../../assets/images/ajax-loader.gif';
import Confirm from 'react-confirm-bootstrap';
import ResourceListPagination from "../../resource_list_pagination";
import ResourceRow from "../../resource_row";
import NewResourceLink from "../../lib/new_resource_link";

const apiType = 'platforms';

class PlatformList extends ResourceList {

  static get apiType(){
    return apiType;
  }

  constructor(props){
    super(props);
    this.handleRemoteReset = this.handleRemoteReset.bind(this);
    this.state.loadedStatuses = [];
  }

  loadRemoteStatuses(){
    if (this.state && this.state.resourceIds){
      this.state.resourceIds.forEach((id) => {
        setTimeout(()=>{this.loadRemoteStatus(id)}, Math.random() * 5000)
      });
    }
  }

  loadRemoteStatus(id){
    this.props.loadResources('remote-platform-statuses/' + id).then((status) => {
      if (status && status.body && status.body.data)
        this.setState({loadedStatuses: [...this.state.loadedStatuses, status.body.data.id]});
    });
  }

  componentWillMount(){
    this.loadResources().then(() => {
      this.loadRemoteStatuses();
    });
  }

  componentDidMount() {
    this.intervalId = setInterval(this.loadRemoteStatuses.bind(this), 15000);
  }

  componentWillUnmount(){
    clearInterval(this.intervalId);
  }

  remoteStatus(resource){
    const remote = this.remotePlatformStatus(resource);
    return remote ? remote.attributes['status'] : null;
  }

  remoteVersion(resource){
    const remote = this.remotePlatformStatus(resource);
    if (remote && remote.attributes && remote.attributes['version'] && typeof remote.attributes['version'] === 'string'){
      const parts = remote.attributes['version'].split('/');
      return parts[parts.length - 1];
    } else if (remote) {
      return "unknown";
    } else
      return null;
  }

  remotePlatformStatus(resource){
    if (this.state.loadedStatuses === null || this.state.loadedStatuses.indexOf(resource.id) === -1)
      return null;
    if (!this.props.resources || !this.props.resources['remote-platform-statuses'])
      return null;
    return this.props.resources['remote-platform-statuses'].find(s => parseInt(s.id, 10) === parseInt(resource.id, 10));
  }

  handleRemoteReset(ev){
    const id = ev.target.dataset['id'];
    this.setState({loadedStatuses: this.state.loadedStatuses.filter(sid => parseInt(id, 10) !== parseInt(sid, 10))});
    this.props.resetRemotePlatform(id).then(platform => this.loadRemoteStatus(platform.data.id));
  }

  canCreateNew(){
    return this.props.currentUser && this.props.currentUser.permissions.platforms.create;
  }

  render() {
    const platformVersion = (resource) => {
      const version = this.remoteVersion(resource);
      return (
        <span className='remote-platform-version'>
          {version === null &&
          <img src={ajaxLoader} alt="loading..."/>
          }
          {version !== null &&
          <span>{version}</span>
          }
        </span>
      )
    };
    const platformStatus = (resource) => {
      const status = this.remoteStatus(resource);
      return (
        <span className='remote-platform-status'>
          {status === null &&
            <img src={ajaxLoader} alt="loading..."/>
          }
          {status === 'ok' &&
            <i className='glyphicon glyphicon-check'>&nbsp;</i>
          }
          {status !== 'ok' && status !== null &&
            <i className='glyphicon glyphicon-alert'>&nbsp;</i>
          }
          {status === 'ok' &&
            <span>Online</span>
          }
          {status === 'offline' &&
            <span>Offline</span>
          }
          {status === 'ip_address_missing' &&
            <span>No ip address</span>
          }
          {status === 'data_integrity_error' &&
            <span>
              <span>Data integrity error</span>
              <button data-id={resource.id} onClick={this.handleRemoteReset}>Fix</button>
            </span>
          }
        </span>
      )
    };
    return (
      <div className="platform-list-component">
        <NewResourceLink to={`/${apiType}/new`} disabled={!this.canCreateNew()}>New platform</NewResourceLink>
        <Table responsive hover>
          <thead>
          <tr>
            <th>Name</th>
            <th>Serial</th>
            <th>IP</th>
            <th>Access key</th>
            <th>Status</th>
            <th>Version</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          {this.resources().map(resource => (
            <ResourceRow key={resource.id} redirectTo={this.remoteStatus(resource) === 'ok' && resource.canShow() && `/${apiType}/${resource.id}`}>
              <td>{resource.attributes['name']}</td>
              <td>{resource.attributes['serial-number']}</td>
              <td>{resource.attributes['ip-address']}</td>
              <td>{resource.attributes['access-key']}</td>
              <td>{platformStatus(resource)}</td>
              <td>{platformVersion(resource)}</td>
              <td className="actions">
                {resource.canUpdate() &&
                <Link to={`/${apiType}/${resource.id}/edit`}>
                  <i className="action-icon glyphicon glyphicon-pencil"></i>
                </Link>}
                {resource.canDestroy() &&
                <Confirm
                  onConfirm={() => {this.handleResourceDelete(resource.id)}}
                  body={`Are you sure you want to delete ${resource.attributes['name']}?`}
                  confirmText="Confirm delete"
                  title="Delete platform">
                  <Button className="btn-link">
                    <i className="action-icon glyphicon glyphicon-trash"></i>
                  </Button>
                </Confirm>}
                {this.remoteStatus(resource) === 'ok' && resource.canShow() &&
                <i className="action-icon glyphicon glyphicon-chevron-right"></i>}
              </td>
            </ResourceRow>
          ))}
          </tbody>
        </Table>
        <ResourceListPagination resourceList={this}/>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.auth.currentUser
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetRemotePlatform: (platformId) => {
      const data = {
        type: apiType,
        id: platformId,
        attributes: {
          'reset-remote-db': true
        }
      };
      return dispatch(updateResource(data));
    }
  };
};

export default withRouter(ResourceList.connect(mapStateToProps, mapDispatchToProps)(PlatformList));