import React from 'react';
import PropTypes from 'prop-types';
import {FormGroup, ControlLabel, FormControl, Button, Tooltip, OverlayTrigger} from 'react-bootstrap';
import _ from 'lodash';

class ManualMuInput extends React.Component {

  constructor(props) {
    super(props);
    this.state = {settings: []};
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSettingInputChange = this.handleSettingInputChange.bind(this);
    this.addSettings = this.addSettings.bind(this);
    this.defaultInputs = {
      'ranges': {
        'mode': {
          type: 'select',
          placeholder: 'select mode',
          label: 'Uncertainty result mode',
          options: ['- select -', 'relative', 'absolute']
        },
        'min': {type: 'number', placeholder: 'range min value', label: 'Min'},
        'max': {type: 'number', placeholder: 'range max value', label: 'Max'},
        'expandedUncertainty': {type: 'number', placeholder: 'expanded uncertainty value', label: 'Uncertainty'}
      }
    };
  }

  resolveError(attr) {
    return this.props.resourceForm.resolveError(attr);
  }

  resolveValue(attr) {
    return this.props.resourceForm.resolveValue(attr);
  }

  resolveSettingValue(index, attr, target) {
    const settings = this.props.resourceForm.resolveValue(target);
    if (settings[index])
      return settings[index][attr];
    else
      return '';
  }

  handleInputChange(ev) {
    this.props.resourceForm.handleInputChange(ev);
  }

  handleSettingInputChange(ev, target) {
    const name = ev.target.name.split(';')[0];
    const index = ev.target.name.split(';')[1];
    const value = ev.target.value;
    const settings = _.cloneDeep(this.props.resourceForm.resolveValue(target) || []);
    if (!settings[index])
      settings[index] = {};
    settings[index][name] = value;
    this.applySettings(settings, target);
  }

  applySettings(settings, target) {
    // A bit hacky but...
    this.handleInputChange({target: {name: target, value: settings}});
  }

  settings(target) {
    return this.props.resourceForm.resolveValue(target) || [];
  }

  addSettings(target) {
    let settings = _.cloneDeep(this.settings(target));
    settings.push(
      Object.keys(this.defaultInputs[target]).reduce((acc, item) => ({...acc, [item]: ''}), {})
    );
    this.applySettings(settings, target);
  }

  removeSettings(index, target) {
    let settings = _.cloneDeep(this.settings(target));
    settings.splice(index, 1)
    this.applySettings(settings, target);
  }

  render() {
    const helpOverlays = {
      'expandedUncertainty':
        <Tooltip title="Popover right" id="expandedUncertaintyTooltip">
          If <i>Uncertainty result mode</i> is <i>relative</i> then give uncertainty value as percentage (100 = 100%
          of <i>Value</i>).
        </Tooltip>
    }

    const SettingInput = (index, setting, target) => {
      return (
        <div key={`${target}-${index}`} className="setting-block">
          {Object.keys(setting).map((key) => (
            <FormGroup key={target + key + index} controlId={key + index}>
              <ControlLabel>{this.defaultInputs[target][key]['label']}</ControlLabel>
              {helpOverlays[key] &&
                <OverlayTrigger trigger='click' placement='right' overlay={helpOverlays[key]}>
                  <Button bsStyle="link">?</Button>
                </OverlayTrigger>
              }
              {this.defaultInputs[target][key]['type'] !== 'select' &&
                <FormControl
                  name={`${key};${index}`}
                  type={this.defaultInputs[target][key]['type']}
                  value={this.resolveSettingValue(index, key, target)}
                  onChange={(ev) => this.handleSettingInputChange(ev, target)}
                  placeholder={this.defaultInputs[target][key]['placeholder']}
                />}
              {this.defaultInputs[target][key]['type'] === 'select' &&
                <FormControl
                  componentClass="select"
                  name={`${key};${index}`}
                  value={this.resolveSettingValue(index, key, target)}
                  onChange={(ev) => this.handleSettingInputChange(ev, target)}
                  placeholder={this.defaultInputs[target][key]['placeholder']}
                >
                  {this.defaultInputs[target][key]['options'].map(option => <option key={`${key}-option-${option}`} value={option}>{option}</option>)}
                </FormControl>
              }
            </FormGroup>
          ))}
          <Button
            className="setting-block-remove"
            bsStyle="default"
            onClick={() => this.removeSettings(index, target)}
          >
            <i className="glyphicon glyphicon-trash"/>
          </Button>
        </div>
      )
    };
    return (
      <div className="manual-mu-input-component">
        <div className="input-group">
          <h4>Ranges</h4>
          {this.settings('ranges').map((setting, index) =>
            SettingInput(index, setting, 'ranges')
          )}
          <Button bsStyle="default" className="add-settings" onClick={() => this.addSettings('ranges')}>
            <i className="glyphicon glyphicon-plus"/> Add range
          </Button>
        </div>
      </div>
    )
  }
}

ManualMuInput.propTypes = {
  resourceForm: PropTypes.object
};

export default ManualMuInput;
