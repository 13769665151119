import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, ControlLabel, Panel, Button, Checkbox } from 'react-bootstrap';
import DateTime from 'react-datetime';
import _ from 'lodash';
import moment from 'moment';
import Resource from "./lib/resource";
import {connect} from "react-redux";
import {readEndpoint} from "redux-json-api";
import {Typeahead} from "react-bootstrap-typeahead";

class ChangeLogFilter extends React.Component {

  constructor(props) {
    super(props);
    this.state = {showFilter: this.props.showInitially};
    this.state.filterParams = _.cloneDeep(this.props.filterParams);
  }

  componentWillMount(){
    this.props.loadResources('users?limit=1000');
    //this.props.loadResources('data-platforms/' + this.props.dataPlatformId + '?include=data-phases,data-instruments,data-groups');
  }

  users(){
    return this.props.resources['users'].map(u => ({id: u.id, label: u.attributes.name + ' <' + u.attributes.email + '>'}));
  }

  handleTimeFilter(key, momentTime){
    let filter = _.cloneDeep(this.state.filterParams);
    if (typeof momentTime === "object")
      filter[key] = momentTime.format();
    else
      delete(filter[key]);
    this.setState({filterParams: filter});
  }

  handleCreatedAfterChange(momentTime) {
    this.handleTimeFilter('created-after', momentTime);
  }

  handleCreatedBeforeChange(momentTime) {
    this.handleTimeFilter('created-before', momentTime);
  }

  resolveDefaultFilterValue(key, value = null){
    const exists = this.state.filterParams && this.state.filterParams[key];
    if (key === 'whodunnit'){
      if (!exists)
        return [];
      const user = this.users().find(u => parseInt(u.id) === parseInt(this.state.filterParams[key]));
      return user ? [user] : [];
    } else if (key === 'event'){
      return exists ? this.state.filterParams[key].indexOf(value) !== -1 : '';
    } else if (key === 'created-after' || key === 'created-before'){
      return exists ? moment(this.state.filterParams[key]).format('MM/DD/YYYY HH:mm') : '';
    } else if (exists)
      return this.state.filterParams[key];
    else
      return '';
  }

  handleEventChange(ev){
    this.handleCheckboxChange(ev, 'event');
  }

  handleUserChange(obj){
    let filter = _.cloneDeep(this.state.filterParams);
    if (obj && obj.length > 0)
      filter['whodunnit'] = obj[0].id;
    else
      delete(filter['whodunnit']);
    this.setState({filterParams: filter});
  }

  handleCheckboxChange(ev, key){
    const id = ev.target.value;
    let filter = _.cloneDeep(this.state.filterParams);
    if (!filter[key])
      filter[key] = [];
    if (ev.target.checked)
      filter[key].push(id);
    else
      filter[key] = filter[key].filter(did => {
        if (isNaN(id))
          return did !== id;
        else
          return parseInt(did, 10) !== parseInt(id, 10);
      });
    if (filter[key].length === 0)
      delete(filter[key]);
    this.setState({filterParams: filter});
  }

  applyChanges(){
    this.props.onChange(this.state.filterParams);
  }

  render() {
    return (
      <div className="change-log-filter-component">
        <Button className="filter-toggle" onClick={() => this.setState({ showFilter: !this.state.showFilter })}>
          {!this.state.showFilter &&
          <span><i className="glyphicon glyphicon-filter"></i> Filter</span>}
          {this.state.showFilter &&
          <span><i className="glyphicon glyphicon-remove"></i> Hide filter</span>}
        </Button>
        <Panel onToggle={()=>(null)} expanded={this.state.showFilter} className={this.state.showFilter ? "" : "non-expanded"}>
          <Panel.Collapse>
            <Panel.Body>
              <FormGroup controlId="created-at">
                <ControlLabel>Logged after</ControlLabel>
                <DateTime name="created-after" onChange={this.handleCreatedAfterChange.bind(this)} defaultValue={this.resolveDefaultFilterValue("created-after")} timeFormat="HH:mm"/>
                <ControlLabel>Logged before</ControlLabel>
                <DateTime name="created-before" onChange={this.handleCreatedBeforeChange.bind(this)} defaultValue={this.resolveDefaultFilterValue("created-before")} timeFormat="HH:mm"/>
              </FormGroup>
              <FormGroup controlId="event">
                <ControlLabel>Event</ControlLabel>
                {['create', 'update', 'destroy'].map(event => {
                  return (<Checkbox checked={this.resolveDefaultFilterValue('event', event)} onChange={this.handleEventChange.bind(this)} key={event} value={event}>
                    {event}
                  </Checkbox>);
                })}
              </FormGroup>
              <FormGroup controlId="user" className="user-filter">
                <ControlLabel>User</ControlLabel>
                <Typeahead clearButton selected={this.resolveDefaultFilterValue('whodunnit')} onChange={this.handleUserChange.bind(this)} options={this.users()}/>
              </FormGroup>
              <div>
                <Button bsStyle="primary" onClick={this.applyChanges.bind(this)}>Apply filter</Button>
              </div>
            </Panel.Body>
          </Panel.Collapse>
        </Panel>
      </div>
    );
  }
}

ChangeLogFilter.propTypes = {
  filterParams: PropTypes.object,
  onChange: PropTypes.func,
  excludeParameters: PropTypes.array,
  showInitially: PropTypes.bool
};

ChangeLogFilter.defaultProps = {
  excludeParameters: []
};

const mapStateToProps = (state) => {
  return Resource.mapStateToResources(state, 'users');
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadResources: (url) => {
      return dispatch(readEndpoint(url))
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeLogFilter);