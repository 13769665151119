import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

class Relay extends Component{

  constructor(props){
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.state = {buttonEnabled: true};
  }

  isOn(){
    return this.props.value;
  }

  handleClick(){
    this.setState({buttonEnabled: false});
    setTimeout(()=>{this.setState({buttonEnabled: true})}, 1000);
    this.props.onToggle(!this.props.value ? 1 : 0);
  }

  render(){
    return (
      <div className="relay-component">
        <div className="power-indicator">
          {this.isOn() &&
          <div className="power-on"><i title="Power On"/></div>}
          {!this.isOn() &&
          <div className="power-off"><i title="Power Off"/></div>}
        </div>
        <Button onClick={this.handleClick} disabled={!this.state.buttonEnabled} bsStyle="default">
          {this.isOn() ? 'Switch OFF' : 'Switch ON'}
        </Button>
      </div>
    );
  }
}

Relay.propTypes = {
  value: PropTypes.bool,
  onToggle: PropTypes.func
};

export default Relay;
