import React from 'react';
import PropTypes from 'prop-types';

class Compass extends React.Component{

  constructor(props){
    super(props);
    this.range = Math.PI * 2;
    this._frameId = null;
    this.lastAnimationTime = null;
    this.loop = this.loop.bind(this);
    this.state = {currentNeedleAngle: this.needleAngleForValue(this.minValue())};
  }

  componentWillUnmount() {
    this.stopLoop();
  }

  componentWillMount() {
    this.startLoop();
  }

  componentWillReceiveProps(nextProps, nextContext){
    this.startLoop();
  }

  startLoop() {
    if( this._frameId === null ) {
      this._frameId = window.requestAnimationFrame( this.loop );
    }
  }

  loop() {
    const now = Date.now();
    const target = this.needleAngleForValue(this.props.value);

    if (this.lastAnimationTime === null)
      this.lastAnimationTime = now;

    const speed = Math.max(Math.abs(this.state.currentNeedleAngle - target), 0.05);
    const d = (now - this.lastAnimationTime) * 0.005 * speed;
    this.lastAnimationTime = now;

    // perform loop work here
    if (this.state.currentNeedleAngle < target)
      this.setState({currentNeedleAngle: Math.min(target, this.state.currentNeedleAngle + d)});
    else if (this.state.currentNeedleAngle > target)
      this.setState({currentNeedleAngle: Math.max(target, this.state.currentNeedleAngle - d)});

    // Set up next iteration of the loop
    if (this.state.currentNeedleAngle === target)
      this.stopLoop();
    else
      this._frameId = window.requestAnimationFrame( this.loop );
  }

  stopLoop() {
    if (this._frameId !== null){
      window.cancelAnimationFrame( this._frameId );
      this._frameId = null;
      this.lastAnimationTime = null;
    }
    // Note: no need to worry if the loop has already been cancelled
    // cancelAnimationFrame() won't throw an error
  }

  minValue(){
    return 0;
  }

  maxValue(){
    return 360;
  }

  needleAngleForValue(value){
    let scale = (value - this.minValue()) / (this.maxValue()  - this.minValue());
    scale = Math.max(scale, 0);
    scale = Math.min(scale, 1);
    return scale * this.range - Math.PI * 0.5;
  }

  needlePoints(angle){
    const length = 0.35;
    const x1 = Math.cos(angle) * this.props.size * length + this.props.size * 0.5;
    const y1 = Math.sin(angle) * this.props.size * length + this.props.size * 0.5;
    const x2 = Math.cos(angle + Math.PI * 0.5) * this.props.size * 0.025 + this.props.size * 0.5;
    const y2 = Math.sin(angle + Math.PI * 0.5) * this.props.size * 0.025 + this.props.size * 0.5;
    const x3 = Math.cos(angle - Math.PI * 0.5) * this.props.size * 0.025 + this.props.size * 0.5;
    const y3 = Math.sin(angle - Math.PI * 0.5) * this.props.size * 0.025 + this.props.size * 0.5;
    return [Math.round(x1), Math.round(y1), Math.round(x2), Math.round(y2), Math.round(x3), Math.round(y3)];
  }

  markerLine(value, length){
    const angle = this.needleAngleForValue(value);
    const x1 = Math.cos(angle) * this.props.size * 0.485 + this.props.size * 0.5;
    const y1 = Math.sin(angle) * this.props.size * 0.485 + this.props.size * 0.5;
    const x2 = Math.cos(angle) * this.props.size * (0.485 - length * this.props.size * 0.00025) + this.props.size * 0.5;
    const y2 = Math.sin(angle) * this.props.size * (0.485 - length * this.props.size * 0.00025) + this.props.size * 0.5;
    return {x1: x1, y1: y1, x2: x2, y2: y2};
  }

  markerTextPosition(value){
    const angle = this.needleAngleForValue(value);
    let x = Math.cos(angle) * this.props.size * 0.4 + this.props.size * 0.5;
    let y = Math.sin(angle) * this.props.size * 0.4 + this.props.size * 0.5;
    if (angle < 0) {
      y += this.props.size * 0.059;
    } else if (angle < 0.1) {
      y += this.props.size * 0.059 * 0.5;
      x -= this.props.size * 0.015;
    } else if (angle > 2) {
      y += this.props.size * 0.059 * 0.5;
      x += this.props.size * 0.03;
    }
    return {x: x, y: y};
  }

  markerTextAnchor(value){
    //const angle = this.needleAngleForValue(value);
    return 'middle';
  }

  roundedValue(){
    if (this.props.value === null || isNaN(parseFloat(this.props.value)))
      return <i>&nbsp;</i>;
    return Math.ceil(this.props.value * 100) / 100;
  }

  labels(){
    return {0: 'N', 90: 'E', 180: 'S', 270: 'W'};
  }

  ticks() {
    let ret = [];
    for (let i = 0; i < 360; i += 90 / 20)
      ret.push(i);
    return ret;
  }


  render(){
    return (
      <svg height={this.props.size} width={this.props.size}>
        <circle cx={this.props.size * 0.5} cy={this.props.size * 0.5} r={this.props.size * 0.49} stroke="#666" strokeWidth="2" fill="white" />
        <text fontSize={this.props.size * 0.055} fill="#aaa" textAnchor="end" x={this.props.size * 0.95} y={this.props.size * 0.98}>{this.roundedValue()}&deg;</text>
        {this.ticks().map(v => (
          <line key={'tick' + v} x1={this.markerLine(v, 0.5).x1} y1={this.markerLine(v, 0.5).y1} x2={this.markerLine(v, 0.5).x2} y2={this.markerLine(v, 0.5).y2} stroke="#999" strokeWidth="1"/>
        ))}
        {[0, 90, 180, 270].map(v => (
          <line key={'marker' + v} x1={this.markerLine(v, 1).x1} y1={this.markerLine(v, 1).y1} x2={this.markerLine(v, 1).x2} y2={this.markerLine(v, 1).y2} stroke="#333" strokeWidth="3"/>
        ))}
        {[45, 135, 225, 315].map(v => (
          <line key={'submarker' + v} x1={this.markerLine(v, 0.8).x1} y1={this.markerLine(v, 0.8).y1} x2={this.markerLine(v, 0.8).x2} y2={this.markerLine(v, 0.8).y2} stroke="#666" strokeWidth="2"/>
        ))}
        {Object.keys(this.labels()).map(v => (
          <text fontSize={this.props.size * 0.09} key={'label' + v} textAnchor={this.markerTextAnchor(v)} x={this.markerTextPosition(v).x} y={this.markerTextPosition(v).y}>{this.labels()[v]}</text>
        ))}
        <polygon points={this.needlePoints(this.state.currentNeedleAngle)} fill="#f00" stroke="#600" strokeWidth="1" />
        <polygon points={this.needlePoints(this.state.currentNeedleAngle + Math.PI)} fill="#333" stroke="#000" strokeWidth="1" />
        <circle cx={this.props.size * 0.5} cy={this.props.size * 0.5} r={this.props.size * 0.05} stroke="black" strokeWidth="1" fill="grey" />
      </svg>
    )
  }
}

Compass.propTypes = {
  size: PropTypes.number,
  value: PropTypes.number
};

export default Compass;