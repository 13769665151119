import React from 'react';
import WebSocketWrapper from '../../lib/web_socket_wrapper';
import Config from '../../config';
import { webSocketError } from '../../actions';
import ResourceDetails from "../../resource_details";
import {Button, Panel, FormControl} from "react-bootstrap";
import { withRouter } from 'react-router-dom';

class PlatformAdmin extends ResourceDetails {

  static get apiType(){
    return "platforms";
  }

  constructor(props){
    super(props);
    this.state = {release: null, releaseSubmitted: false};
  }

  componentWillMount() {
    this.props.loadResources(this.constructor.apiType + '/' + this.resourceId() + '?include=remote-platform-status');
  }

  componentDidUpdate() {
    if (!this.ws && this.ipAddress())
      this.initWebSocket();
  }

  componentWillUnmount() {
    if (this.ws)
      this.ws.close();
  }

  initWebSocket(){
    const jwt = localStorage.getItem('jwt');
    const url = Config.webSocketUri + '?platformId=' + this.resourceId() + '&jwt=' + jwt + '&ip=' + this.ipAddress();
    const params = {
      reconnect: false,
      url: url,
      onMessage: this.handleData.bind(this),
      onError: this.handleError.bind(this),
      onClose: this.handleClose.bind(this),
      debug: false
    };
    this.ws = new WebSocketWrapper(params);
  }

  ipAddress() {
    if (!this.resource())
      return null;
    const ip = this.resource().attributes['ip-address'];
    if (!ip)
      return null;
    if (/^((?:[0-9A-Fa-f]{1,4}))((?::[0-9A-Fa-f]{1,4}))*::((?:[0-9A-Fa-f]{1,4}))((?::[0-9A-Fa-f]{1,4}))*|((?:[0-9A-Fa-f]{1,4}))((?::[0-9A-Fa-f]{1,4})){7}$/g.test(ip)){
      //IPV6
      return '[' + ip + ']';
    } else {
      return ip;
    }
  }

  handleError(error) {
    console.log(error);
    this.props.webSocketError();
  }

  handleClose(evt){
    if (evt.code > 1000){
      console.log("Websocket closed with code: " + evt.code);
      console.log(evt);
      this.props.webSocketError();
    }
  }

  handleData(data) {
  }

  releases(){
    if (this.resource() && this.resource().included && this.resource().included['remote-platform-status'])
      return this.resource().included['remote-platform-status'].attributes.releases || [];
    else
      return [];
  }

  onChangeRelease(ev){
    this.setState({release: ev.target.value});
  }

  submitRelease(){
    this.ws.sendData({ip: this.ipAddress(), data: {changeRelease: this.state.release}});
    this.setState({releaseSubmitted: true});
  }

  currentVersion(){
    if (this.resource() &&
      this.resource().included &&
      this.resource().included['remote-platform-status'] &&
      this.resource().included['remote-platform-status'].attributes['version'])
      return this.resource().included['remote-platform-status'].attributes['version'].split('/').slice(-1)[0];
    else
      return '';
  }

  render() {
    return (
      <div className="platform-admin-component">
        <Panel className="software-version-controls">
          <Panel.Heading>
            Platform software version
          </Panel.Heading>
          <Panel.Body>
            <FormControl componentClass="select" name="release" value={this.state.release || this.currentVersion()} onChange={this.onChangeRelease.bind(this)}>
              <option value="">- select -</option>
              <option key={"master"} value="master">master</option>
              {this.releases().map(release => {
                return <option key={"release-" + release} value={release}>{release}</option>
              })}
            </FormControl>
            <Button bsStyle="primary" onClick={this.submitRelease.bind(this)} disabled={!this.state.release || this.state.releaseSubmitted}>Change version</Button>
            {this.state.releaseSubmitted &&
            <p className="version-submit-info">
              Version change request submitted to the platform. It will take few minutes to complete this request as platform reboots.
            </p>}
          </Panel.Body>
        </Panel>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    webSocketError: () => {
      dispatch(webSocketError("Monitoring disabled due to WebSocket error! Reload the page to try to reconnect."))
    }
  };
};

export default withRouter(ResourceDetails.connect(null, mapDispatchToProps)(PlatformAdmin));