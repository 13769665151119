import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert } from 'react-bootstrap';
import { dismissFlash } from './actions';

export const FLASH_TYPE_NOTICE = 'success';
export const FLASH_TYPE_ALERT = 'danger';

class Flash extends Component {
  handleDismiss(){
    this.props.dismissFlash();
  }

  render(){
    const { flashMessage, flashType } = this.props;
    if (!flashMessage || !flashType)
      return null;
    return (
      <div className="flash-component">
        <Alert bsStyle={flashType} onDismiss={this.handleDismiss.bind(this)}>
          {flashMessage}
        </Alert>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    flashMessage: state.flash.flashMessage,
    flashType: state.flash.flashType
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dismissFlash: () => {
      dispatch(dismissFlash());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Flash);