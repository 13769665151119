import React from 'react';
import ResourceForm from '../../resource_form';
import ResourceFormHeader from "../../resource_form_header";
import ResourceFormFooter from "../../resource_form_footer";
import ResourceFormInput from "../../resource_form_input";
import ResourceFormTypeahead from "../../resource_form_typeahead";

class DataPlatformForm extends ResourceForm {
  static get apiType(){
    return 'data-platforms';
  }

  componentWillMount() {
    super.componentWillMount();
    this.props.loadResources('data-platforms?page[size]=1000&sort=name');
  }

  organizationNames(){
    const result = this.resources('data-platforms').map(obj => obj.attributes['organization-name']);
    return result.filter((elem, pos, arr) => elem !== null && arr.indexOf(elem) === pos);
  }

  render() {
    return (
      <div className="data-platform-form-component">
        <form>
          <ResourceFormHeader resourceForm={this}/>
          <ResourceFormInput name="name" resourceForm={this} disabled={true}/>
          <ResourceFormTypeahead name="organization-name" options={this.organizationNames()} label="Organization" resourceForm={this}/>
          <ResourceFormFooter resourceForm={this}/>
        </form>
      </div>
    );
  }
}

export default ResourceForm.connect()(DataPlatformForm);