import React from 'react';
import { connect } from 'react-redux';
import { readEndpoint } from 'redux-json-api';
import Resource from '../../lib/resource';
import PropTypes from "prop-types";
import {Button, Col, FormControl, Glyphicon, Row} from "react-bootstrap";
import _ from 'lodash';
import {Typeahead} from "react-bootstrap-typeahead";

class RuleFormConditions extends React.Component {
  constructor(props){
    super(props);
    this.addCondition = this.addCondition.bind(this);
  }

  form(){
    return this.props.resourceForm;
  }

  conditionTypes(){
    return {
      'duration': 'Duration',
      'instrument_value': 'Instrument value',
      'counter_value': 'Counter value'
    };
  }

  operators(){
    return {
      'eq': '==',
      'not_eq': '<>',
      'lt': '<',
      'gt': '>',
      'lteq': '<=',
      'gteq': '>='
    };
  }

  addCondition(ev){
    const id = '_' + this.form().state.conditions.length;
    const condition = {
      id: id,
      attributes: {'condition-type': 'duration', 'operator': 'gteq'},
    };
    const conditions = this.form().state.conditions.concat([condition]);
    this.form().setState({conditions});
  }

  removeCondition(id){
    // Not temp id? Set to be removed from db
    if (id[0] !== '_' && this.form().state.removedConditions.indexOf(id) ===  -1){
      const removedConditions = this.form().state.removedConditions.concat([id]);
      this.form().setState({removedConditions});
    }
    const conditions = this.form().state.conditions.filter(obj => obj.id.toString() !== id.toString());
    this.form().setState({conditions});
  }

  onChangeCondition(ev, id){
    const name = ev.target.name;
    const value = ev.target.value;
    let conditions = _.cloneDeep(this.props.conditions);
    let condition = conditions.find(c => c.id.toString() === id.toString());
    if (condition){
      if (name === 'instrument_id')
        condition.relationships = {instrument: {data: {id: value}}};
      else if (name === 'counter_name')
        condition.attributes.characteristics = {counter_name: value};
      else
        condition.attributes[name] = value;
    }
    this.form().setState({conditions});
  }

  handleCounterNameTypeaheadChange(values, condition){
    if (Array.isArray(values)){
      values = values.map(v => {
        if (v && v.customOption)
          return v.label;
        else
          return v;
      });
      if (values.length > 0)
        values = values[0];
      else
        return;
    }
    this.onChangeCondition({target: {name: 'counter_name', value: values}}, condition.id);
  }

  selectedCounterName(condition){
    if (!condition.attributes.characteristics || !condition.attributes.characteristics.counter_name)
      return [];
    return [condition.attributes.characteristics.counter_name];
  }

  render() {
    const condition = (condition) => {
      return (
        <Row key={"condition" + condition.id} className="condition">
          <Col md={3}>
            <FormControl componentClass="select" name="condition-type" value={condition.attributes['condition-type']} onChange={ev => this.onChangeCondition(ev, condition.id)}>
              {Object.keys(this.conditionTypes()).map(type => (
                <option key={type} value={type}>{this.conditionTypes()[type] || type}</option>
              ))}
            </FormControl>
          </Col>
          {condition.attributes['condition-type'] === 'duration' &&
          <React.Fragment>
            <Col md={2}>
              <FormControl componentClass="select" name="operator" value={condition.attributes['operator']} onChange={ev => this.onChangeCondition(ev, condition.id)}>
                <option value={null}>- select -</option>
                {['gteq'].map(type => (
                  <option key={type} value={type}>{this.operators()[type] || type}</option>
                ))}
              </FormControl>
            </Col>
            <Col md={4}>
              <FormControl name="value" value={condition.attributes['value']} onChange={ev => this.onChangeCondition(ev, condition.id)} placeholder="seconds"/>
            </Col>
            <Col md={3}>
              <Button onClick={() => this.removeCondition(condition.id)}>
                <Glyphicon glyph="trash"/>
              </Button>
            </Col>
          </React.Fragment>}
          {condition.attributes['condition-type'] === 'instrument_value' &&
          <React.Fragment>
            <Col md={3}>
              <FormControl componentClass="select" name="instrument_id" value={condition.relationships && condition.relationships.instrument && condition.relationships.instrument.data && condition.relationships.instrument.data.id} onChange={ev => this.onChangeCondition(ev, condition.id)}>
                <option value={null}>- select instrument -</option>
                {this.props.instruments.map(instrument => (
                  <option key={"instrument" + instrument.id} value={instrument.id}>{instrument.attributes.name}</option>
                ))}
              </FormControl>
            </Col>
            <Col md={2}>
              <FormControl name="formula" value={condition.attributes['formula'] || ''} onChange={ev => this.onChangeCondition(ev, condition.id)} placeholder="optional formula"/>
            </Col>
            <Col md={1}>
              <FormControl componentClass="select" name="operator" value={condition.attributes['operator']} onChange={ev => this.onChangeCondition(ev, condition.id)}>
                <option value={null}>- select -</option>
                {Object.keys(this.operators()).map(type => (
                  <option key={type} value={type}>{this.operators()[type] || type}</option>
                ))}
              </FormControl>
            </Col>
            <Col md={2}>
              <FormControl name="value" value={condition.attributes['value']} onChange={ev => this.onChangeCondition(ev, condition.id)} placeholder="value"/>
            </Col>
            <Col md={1}>
              <Button onClick={() => this.removeCondition(condition.id)}>
                <Glyphicon glyph="trash"/>
              </Button>
            </Col>
          </React.Fragment>}
          {condition.attributes['condition-type'] === 'counter_value' &&
          <React.Fragment>
            <Col md={3}>
              <Typeahead onInputChange={(values) => this.handleCounterNameTypeaheadChange(values, condition)}
                         onChange={(values) => this.handleCounterNameTypeaheadChange(values, condition)}
                         selected={this.selectedCounterName(condition)}
                         id={condition.id + 'ahead'}
                         allowNew={true}
                         placeholder="counter name"
                         options={this.props.counters}/>
            </Col>
            <Col md={1}>
              <FormControl componentClass="select" name="operator" value={condition.attributes['operator']} onChange={ev => this.onChangeCondition(ev, condition.id)}>
                <option value={null}>- select -</option>
                {Object.keys(this.operators()).map(type => (
                  <option key={type} value={type}>{this.operators()[type] || type}</option>
                ))}
              </FormControl>
            </Col>
            <Col md={2}>
              <FormControl name="value" value={condition.attributes['value']} onChange={ev => this.onChangeCondition(ev, condition.id)} placeholder="value"/>
            </Col>
            <Col md={3}>
              <Button onClick={() => this.removeCondition(condition.id)}>
                <Glyphicon glyph="trash"/>
              </Button>
            </Col>
          </React.Fragment>}
        </Row>
      );
    };
    return (
      <div className="rule-form-conditions-component">
        {this.props.conditions.map(con => (
          condition(con)
        ))}
        <Button onClick={this.addCondition}>
          <Glyphicon glyph="plus"/>
          Add condition
        </Button>
      </div>
    );
  }
}

RuleFormConditions.propTypes = {
  resourceForm: PropTypes.object,
  conditions: PropTypes.array,
  instruments: PropTypes.array,
  counters: PropTypes.array
};

const mapStateToProps = (state) => {
  return Resource.mapStateToResources(state, 'platforms');
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadResources: (url) => {
      return dispatch(readEndpoint(url))
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RuleFormConditions);