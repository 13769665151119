import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Table, Button } from 'react-bootstrap';
import ResourceList from '../../resource_list';
import Confirm from 'react-confirm-bootstrap';
import ResourceListPagination from "../../resource_list_pagination";
import ResourceRow from "../../resource_row";
import NewResourceLink from "../../lib/new_resource_link";
import { withRouter } from 'react-router-dom';

const apiType = 'instruments';

class InstrumentList extends ResourceList {

  static get apiType(){
    return apiType;
  }

  defaultQueryParams() {
    return {
      filter: {
        'platform.id': this.platformId(),
      },
      include: ['instrument-alarm'],
      sort: ['name']
    }
  }
  
  platformId(){
    return this.props.match.params.id;
  }

  widgetLabel(key){
    if (!key)
      return '';
    return (key.charAt(0).toUpperCase() + key.slice(1)).replace('-',' ').replace('_',' ');
  }

  render() {
    return (
      <div className="instrument-list-component">
        <NewResourceLink to={`/platforms/${this.platformId()}/${apiType}/new`} disabled={!this.props.platform || !this.props.platform.canCreate('instruments')}>
          New instrument
        </NewResourceLink>
        <Table responsive hover>
          <thead>
          <tr>
            <th>Name</th>
            <th>Type</th>
            <th>Device ID</th>
            <th>Inst grp</th>
            <th>Monit grps</th>
            <th>Value formula</th>
            <th>Widget</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          {this.resources().map(resource => (
            <ResourceRow key={resource.id} redirectTo={resource.canShow() && `/platforms/${this.platformId()}/${apiType}/${resource.id}`}>
              <td>{resource.attributes['name']}</td>
              <td>{resource.attributes['instrument-type']}</td>
              <td>{resource.attributes['pins']}</td>
              <td>{resource.attributes['instrument-group']}</td>
              <td>{resource.attributes['monitor-groups'] ? resource.attributes['monitor-groups'].join(', ') : ''}</td>
              <td>{resource.attributes['value-formula']}</td>
              <td>{this.widgetLabel(resource.attributes['characteristics'] && resource.attributes['characteristics'].type)}</td>
              <td className="actions">
                {resource.included && resource.included['instrument-alarm'] && resource.included['instrument-alarm'].canUpdate() &&
                  <Link to={`/platforms/${this.platformId()}/${apiType}/${resource.id}/alarms/${resource.included['instrument-alarm'].id}/edit`}>
                    <i className="action-icon glyphicon glyphicon-bell instrument-alarm-on"></i>
                  </Link>
                }
                {(!resource.included || !resource.included['instrument-alarm']) && resource.canCreate('instrument-alarms') &&
                  <Link to={`/platforms/${this.platformId()}/${apiType}/${resource.id}/alarms/new`}>
                    <i className="action-icon glyphicon glyphicon-bell instrument-alarm-off"></i>
                  </Link>
                }
                {resource.canUpdate() &&
                <Link to={`/platforms/${this.platformId()}/${apiType}/${resource.id}/edit`}>
                  <i className="action-icon glyphicon glyphicon-pencil"></i>
                </Link>}
                {resource.canDestroy() &&
                <Confirm
                  onConfirm={() => {this.handleResourceDelete(resource.id)}}
                  body={`Are you sure you want to delete ${resource.attributes['name']}?`}
                  confirmText="Confirm delete"
                  title="Delete instrument">
                  <Button className="btn-link">
                    <i className="action-icon glyphicon glyphicon-trash"></i>
                  </Button>
                </Confirm>}
                {resource.canShow() &&
                <i className="action-icon glyphicon glyphicon-chevron-right"></i>}
              </td>
            </ResourceRow>
          ))}
          </tbody>
        </Table>
        <ResourceListPagination resourceList={this}/>
      </div>
    );
  }
}

InstrumentList.propTypes = {
  platform: PropTypes.object
};

export default withRouter(ResourceList.connect()(InstrumentList));