import React from 'react';
import { connect } from 'react-redux';
import {FormControl, Checkbox} from 'react-bootstrap';
import { readEndpoint } from 'redux-json-api';
import Resource from '../../lib/resource';
import _ from 'lodash';
import PropTypes from "prop-types";
import {Typeahead} from "react-bootstrap-typeahead";

class StepFormInstruments extends React.Component {
  constructor(props){
    super(props);
    this.handleStepInstrumentChange = this.handleStepInstrumentChange.bind(this);
    this.state = {sequenceIds: []};
  }

  componentWillMount() {
    this.props.loadResources('platforms/'+this.props.platformId+'?include=instruments');
    this.props.loadResources(`sequences?filter[platform.id]=${this.props.platformId}&include=sequence-steps.rules`).then(response => {
      if (response && response.body && response.body.data)
        this.setState({sequenceIds: response.body.data.map(entity => entity.id)});
    });
  }

  sequences(){
    return (this.props.resources.sequences &&
      this.props.resources.sequences.filter(s => this.state.sequenceIds.indexOf(s.id) !== -1)) || [];
  }

  rules(){
    const rules =  this.sequences().map(s =>
      s.included['sequence-steps'].filter(ss => ss.included && ss.included.rules).map(ss => ss.included.rules)
    );
    return [].concat(...[].concat(...rules));
  }

  counters(){
    const consequences = [].concat(...this.rules().filter(r => r.attributes).map(r => r.attributes.consequences));
    return consequences.filter(c => c.type === 'set_counter' ||  c.type === 'add_counter').map(c => c.name).filter((el, i, a) => i === a.indexOf(el)).map(c => '#'+c);
  }

  platform(){
    if (this.props.platformId && this.props.resources && this.props.resources.platforms)
      return(this.props.resources.platforms.find(res => res.id === this.props.platformId));
    else
      return null;
  }

  instruments() {
    return (this.platform() && this.platform().included && this.platform().included.instruments) || [];
  }

  form(){
    return this.props.resourceForm;
  }
  
  stepInstruments(){
    let result = [];
    let i = 0;
    this.instruments().forEach((instrument) => {
      let stepInstrument = this.props.stepInstruments.find(
        si => si.included && si.included.instrument && si.included.instrument.id === instrument.id
      );

      if (!stepInstrument)
        stepInstrument = {id: '_' + i, attributes: {control: false, state: 0}};

      result.push({
        id: stepInstrument.id,
        instrumentId: instrument.id,
        instrumentName: instrument.attributes.name,
        state: stepInstrument.attributes['state'],
        'store-data-mode': stepInstrument.attributes['store-data-mode'],
        'control-group': stepInstrument.attributes['control-group'],
        control: stepInstrument.attributes['control'] === undefined || stepInstrument.attributes['control']
      });
      i ++;
    });
    return result;
  }

  handleStepInstrumentChange = (event) => {
    const stepInstruments = _.cloneDeep(this.form().state.stepInstruments);
    const instrumentId = event.target.dataset.instrumentId;
    const stepInstrumentId = event.target.dataset.id;
    const name = event.target.name;

    let stateStepInstrument = stepInstruments.find(si => si.id === stepInstrumentId);
    let stepInstrument = this.props.stepInstruments.find(si => si.id === stepInstrumentId);

    if (!stateStepInstrument){
      stateStepInstrument = {
        id: stepInstrumentId,
        included: {instrument: {id: instrumentId}},
        attributes: (stepInstrument && stepInstrument.attributes) || {}
      };
      stepInstruments.push(stateStepInstrument);
    }
    if (name === 'control')
      stateStepInstrument.attributes[name] = event.target.checked;
    else
      stateStepInstrument.attributes[name] = event.target.value;
    this.form().setState({stepInstruments: stepInstruments});
  };

  handleStateTypeaheadChange(values, si){
    if (Array.isArray(values)){
      values = values.map(v => {
        if (v && v.customOption)
          return v.label;
        else
          return v;
      });
      if (values.length > 0)
        values = values[0];
      else
        return;
    }
    this.handleStepInstrumentChange({target: {name: 'state', value: values, dataset: {instrumentId: si.instrumentId, id: si.id}}});
  }

  selectedState(si){
    return si.state ? [si.state] : [];
  }

  render() {
    const stepInstrumentRow = (si) => {
      return (
        <tr key={si.id}>
          <td>
            <Checkbox data-id={si.id} data-instrument-id={si.instrumentId} name="control" onChange={this.handleStepInstrumentChange} defaultChecked={si.control}/>
          </td>
          <td>{si.instrumentName}</td>
          <td>
            {this.counters().length === 0 &&
            <FormControl data-id={si.id} data-instrument-id={si.instrumentId} name="state" onChange={this.handleStepInstrumentChange} value={si.state} disabled={!si.control}/>}
            {this.counters().length !== 0 &&
            <Typeahead onInputChange={(values) => this.handleStateTypeaheadChange(values, si)} selected={this.selectedState(si)} id={si.id + 'ahead'} allowNew={true} onChange={(values) => this.handleStateTypeaheadChange(values, si)} disabled={!si.control} options={this.counters()}/>}
          </td>
          <td>
            <FormControl data-id={si.id} data-instrument-id={si.instrumentId} componentClass="select" name="store-data-mode" value={si['store-data-mode']} onChange={this.handleStepInstrumentChange} disabled={!si.control}>
              <option key="storedatamode1" value="disabled">Disabled</option>
              <option key="storedatamode2" value="enabled">Enabled</option>
              <option key="storedatamode3" value="on_change">On change</option>
            </FormControl>
          </td>
          <td>
            <FormControl data-id={si.id} data-instrument-id={si.instrumentId} componentClass="select" name="control-group" onChange={this.handleStepInstrumentChange} value={si['control-group'] || ''} disabled={!si.control}>
              <option value={null}></option>
              {['A','B','C','D','E','F','G','H'].map(group => (
                <option key={group} value={group}>{group}</option>
              ))}
            </FormControl>
          </td>
        </tr>
      )
    };

    return (
      <div className="step-form-instruments-component">
        <h3>Instruments</h3>
          <table className="step-instruments">
            <tbody>
            <tr>
              <th></th><th></th><th>State</th><th>Store</th><th>Ctrl grp</th>
            </tr>
            { this.stepInstruments().map((si) => stepInstrumentRow(si)) }
            </tbody>
          </table>
      </div>
    );
  }
};

StepFormInstruments.propTypes = {
  stepForm: PropTypes.object,
  platformId: PropTypes.string,
  stepInstruments: PropTypes.array
};

const mapStateToProps = (state) => {
  return Resource.mapStateToResources(state, 'platforms', 'sequences');
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadResources: (url) => {
      return dispatch(readEndpoint(url))
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StepFormInstruments);