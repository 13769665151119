import React from 'react';
import PropTypes from 'prop-types';
import {Tabs, Tab, Panel, Button} from 'react-bootstrap';
import Breadcrumbs from '../../lib/breadcrumbs';
import InstrumentWidget from "./instrument_widget";
import { webSocketError } from "../../actions";
import { Link, withRouter } from 'react-router-dom';
import ResourceDetails from '../../resource_details';
import ChangeLog from "../../change_log";
import Diary from "../../diary";
import Time from '../../lib/time';
import PlatformConnection from "../../lib/platform_connection";

const apiType = 'instruments';

class InstrumentDetails extends ResourceDetails {
  static get apiType(){
    return apiType;
  }

  constructor(props){
    super(props);
    this.state = { instrument: {} };
    this.platformConnection = null;
  }

  platformId(){
    return this.props.match && this.props.match.params.platformId;
  }

  componentWillMount() {
    this.props.loadResources('platforms/' + this.platformId());
    this.props.loadResources(apiType + '/' + this.resourceId() + '?include=remote-instrument-status,instrument-alarm');
  }

  componentDidUpdate() {
    if (!this.platformConnection && this.ipAddress()){
      this.platformConnection = new PlatformConnection(
        this.platformId(),
        this.ipAddress(),
        (params) => {
          if (params.instrumentData){
            const instrument = params.instrumentData.find(d => parseInt(d.id, 10) === parseInt(this.resourceId(), 10));
            if (instrument)
              this.setState({instrument});
          }
        },
        this.props.webSocketError
      );
      this.platformConnection.connect();
    }
  }

  componentWillUnmount() {
    if (this.platformConnection)
      this.platformConnection.disconnect();
  }

  remoteAttribute(name) {
    const resource = this.resource();
    if (resource && resource.included && resource.included['remote-instrument-status'])
      return resource.included['remote-instrument-status'].attributes[name];
    else
      return null;
  }

  platform(){
    if (this.platformId() && this.props.resources && this.props.resources.platforms)
      return(this.props.resources.platforms.find((res) => { return res.id === this.platformId() }));
    else
      return null;
  }

  ipAddress() {
    if (!this.platform())
      return null;
    let ip = this.platform().attributes['ip-address'];
    if (!ip)
      return null;
    if (/^((?:[0-9A-Fa-f]{1,4}))((?::[0-9A-Fa-f]{1,4}))*::((?:[0-9A-Fa-f]{1,4}))((?::[0-9A-Fa-f]{1,4}))*|((?:[0-9A-Fa-f]{1,4}))((?::[0-9A-Fa-f]{1,4})){7}$/g.test(ip)){
      //IPV6
      return '[' + ip + ']';
    } else {
      return ip;
    }
  }

  instrumentValue(){
    if (this.state.instrument.value !== undefined && this.state.instrument.value !== null)
      return this.state.instrument.value;
    else
      return this.remoteAttribute('value');
  }

  instrumentDataTime(){
    if (this.state.instrument.dataTime !== undefined && this.state.instrument.dataTime !== null)
      return this.state.instrument.dataTime;
    else
      return this.remoteAttribute('data-time');
  }

  instrumentAvgDataInterval(){
    if (this.state.instrument.avgDataInterval !== undefined && this.state.instrument.avgDataInterval !== null)
      return this.state.instrument.avgDataInterval;
    else
      return this.remoteAttribute('avg-data-interval');
  }

  render() {
    return (
      <div className="instrument-details-component">
        <Breadcrumbs>
          <Link to="/platforms">Instrument platforms</Link>
          <Link to={"/platforms/" + this.platformId()}>{this.platform() && this.platform().attributes.name}</Link>
          <Link to={"/platforms/" + this.platformId() + "#instruments"}>Instruments</Link>
          {this.resource() && this.resource().attributes.name}
        </Breadcrumbs>
        <Tabs defaultActiveKey={this.resolveTab("monitoring")} id="instrument-tabs" onSelect={this.handleTabSelect} mountOnEnter={true} unmountOnExit={true}>
          <Tab eventKey='monitoring' title="Monitoring">
            <InstrumentWidget instrument={this.resource()}
                              value={this.instrumentValue()}
                              dataTime={this.instrumentDataTime()}
                              avgDataInterval={this.instrumentAvgDataInterval()}
                              analogGaugeSize={256}
                              platformConnection={this.platformConnection}
                              verbose={true}
                              platformIpAddress={this.ipAddress()}/>
            <div className="instrument-details">
              <Button className="details-toggle" onClick={() => this.setState({ showDetails: !this.state.showDetails })}>
                {!this.state.showDetails &&
                <span>Show details</span>}
                {this.state.showDetails &&
                <span>Hide details</span>}
              </Button>
              <Panel onToggle={()=>(null)} expanded={this.state.showDetails} className={this.state.showDetails ? "" : "non-expanded"}>
                <Panel.Collapse>
                  <Panel.Body>
                    <dl className="dl-horizontal">
                      <dt>Last data received at:</dt>
                      <dd><Time format="withTz">{this.instrumentDataTime()}</Time></dd>
                      <dt>Average data interval:</dt>
                      <dd><Time.Duration>{this.instrumentAvgDataInterval()}</Time.Duration></dd>
                    </dl>
                  </Panel.Body>
                </Panel.Collapse>
              </Panel>
            </div>
          </Tab>
          <Tab eventKey='diary' title="Diary">
            <Diary itemType="Instrument"
                   itemId={this.resourceId()}
                   baseUrl={`/platforms/${this.platformId()}/instruments/${this.resourceId()}/diary`}/>
          </Tab>
          <Tab eventKey='change-log' title="Change log">
            <ChangeLog platformId={this.platformId()}
                       itemType="Instrument"
                       itemId={this.resourceId()}/>
          </Tab>
        </Tabs>
      </div>
    );
  }
}

InstrumentDetails.propTypes = {
  resources: PropTypes.object
};

const mapDispatchToProps = (dispatch) => {
  return {
    webSocketError: () => {
      dispatch(webSocketError("Monitoring disabled due to WebSocket error! Reload the page to try to reconnect."))
    }
  }
};

export default withRouter(ResourceDetails.connect(null, mapDispatchToProps)(InstrumentDetails));