import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ResourceList from '../../resource_list';
import Breadcrumbs from "../../lib/breadcrumbs";
import Confirm from 'react-confirm-bootstrap';
import Time from '../../lib/time';
import OrderableTiles from "../../lib/orderable_tiles";

class SequenceStepList extends ResourceList {

  static get apiType(){
    return 'sequence-steps';
  }

  defaultQueryParams() {
    return {
      filter: {
        'sequence.id': this.sequenceId()
      },
      include: ['step', 'rules'],
      sort: ['index-number'],
      pageSize: 1000
    }
  }

  platformId(){
    return this.props.match.params.platformId;
  }

  sequenceId(){
    return this.props.match.params.sequenceId;
  }

  platform(){
    return this.resource('platforms', this.platformId());
  }

  sequence(){
    return this.resource('sequences', this.sequenceId());
  }

  componentWillMount() {
    super.componentWillMount();
    this.props.loadResources('platforms/' + this.platformId());
    this.props.loadResources('sequences/' + this.sequenceId());
  }

  step(resource){
    return resource.included && resource.included.step;
  }

  confirmReorder(id, newIndex){
    const data = {
      type: 'sequence-steps',
      id: id,
      attributes: {
        'index-number': newIndex
      }
    };
    this.props.updateResource(data).then(() => this.reloadResources());
  }

  render() {
    return (
      <div className="sequence-step-list-component">
        <Breadcrumbs>
          <Link to="/platforms">Instrument platforms</Link>
          <Link to={"/platforms/" + this.platformId()}>{this.platform() && this.platform().attributes.name}</Link>
          <Link to={"/platforms/" + this.platformId() + "#sequences"}>Sequences</Link>
          {this.sequence() && this.sequence().attributes.name}
          Steps
        </Breadcrumbs>
        {this.resources().length > 1 && this.resources()[0].canUpdate() &&
        <span className="tile-drag-to-order-hint">Drag tiles to reorder</span>}
        <OrderableTiles onConfirmReorder={this.confirmReorder.bind(this)}>
          {this.resources().map(resource => (
            <div data-index-number={resource.attributes['index-number']} data-id={resource.id} key={"resource-"+resource.id} className={"tile " + (resource.attributes.active ? '' : 'inactive')}>
              <span className="index-number">{resource.attributes['order-number']}</span>
              <span className="step-name">
                {this.step(resource) && this.step(resource).attributes.name}
              </span>
              <span className="rule">
                {resource.included.rules && resource.included.rules.length === 1 &&
                <span>One rule</span>}
                {resource.included.rules && resource.included.rules.length > 1 &&
                <span>{resource.included.rules.length} rules</span>}
                {(!resource.included.rules || resource.included.rules.length === 0) &&
                <span>Duration: {this.step(resource) && <Time.Duration>{this.step(resource).attributes.duration * 1000}</Time.Duration>}</span>}
              </span>
              <div className="actions">
                {resource.canUpdate() &&
                <Link to={`/platforms/${this.platformId()}/sequences/${this.sequenceId()}/steps/${resource.id}/edit`}>
                  <i className="action-icon glyphicon glyphicon-pencil" title="Edit step"></i>
                </Link>}
                <Link to={`/platforms/${this.platformId()}/sequences/${this.sequenceId()}/steps/${resource.id}/rules`}>
                  <i className="action-icon glyphicon glyphicon-list" title="Show rules"></i>
                </Link>
                {resource.canDestroy() &&
                <Confirm
                  onConfirm={() => {this.handleResourceDelete(resource.id)}}
                  body={`Are you sure you want to remove ${this.step(resource).attributes.name}?`}
                  confirmText="Confirm delete"
                  title="Remove step from sequence">
                  <Button className="btn-link">
                    <i className="action-icon glyphicon glyphicon-trash"></i>
                  </Button>
                </Confirm>}
              </div>
            </div>
          ))}
        </OrderableTiles>
        <Link to={`/platforms/${this.platformId()}/sequences/${this.sequenceId()}/steps/new`} className="new-resource-tile-button">
          Add a step
        </Link>
      </div>
    );
  }
}

export default ResourceList.connect()(SequenceStepList);