import React from 'react';
import { Link } from "react-router-dom";
import { Jumbotron } from 'react-bootstrap';

class Entry extends React.Component{
  render() {
    return (
      <div className="entry-component">
        <div className="container-fluid">
          <Jumbotron>
              <h1>Welcome!</h1>
              <p>EnviCal Manager is a modern IOT ecosystem for device interaction, monitoring and data collection.</p>
              <p>
                <Link to="/public/data">
                  Browse public data
                  <i className="action-icon glyphicon glyphicon-chevron-right"></i>
                </Link><br/>
                <Link to="/public/showroom">
                  Video showroom
                  <i className="action-icon glyphicon glyphicon-chevron-right"></i>
                </Link><br/>
                <Link to="/public/videos">
                  Video archive
                  <i className="action-icon glyphicon glyphicon-chevron-right"></i>
                </Link><br/>
                <a title="AutoMUKit" href={`https://www.ecm-syke.fi/automukit`} target='_blank'>
                  AutoMUKit web UI
                  <i className="action-icon glyphicon glyphicon-chevron-right"></i>
                </a><br/>
                {!localStorage.getItem('jwt') &&
                <Link to="/login" style={{lineHeight: '60px'}}>
                  Login
                  <i className="action-icon glyphicon glyphicon-chevron-right"></i>
                </Link>}
              </p>
          </Jumbotron>
        </div>
      </div>
    );
  }
}

export default Entry;
