import React from 'react';
import PropTypes from 'prop-types';

class Slider extends React.Component{

  constructor(props){
    super(props);
    this.range = Math.PI * 1.45;
    this._frameId = null;
    this.lastAnimationTime = null;
    this.loop = this.loop.bind(this);
    this.handle = null;
    this.dragging = false;
    this.startClientY = 0;
    this.startCurrentPos = 0;
    this.state = {currentPosition: 0};
    this.dragEndedTimer = null;
  }

  componentWillUnmount() {
    this.stopLoop();
    this.handle.removeEventListener('mousedown', this.onMouseDown, false);
    window.document.removeEventListener('mousemove', this.onMouseMove);
    window.document.removeEventListener('mouseup', this.onMouseUp);
    this.handle.removeEventListener('touchstart', this.onMouseDown, false);
    window.document.removeEventListener('touchmove', this.onMouseUp);
    window.document.removeEventListener('touchend', this.onMouseMove);
  }

  componentWillMount(){
    this.startLoop();
  }

  componentWillReceiveProps(nextProps, nextContext){
    this.startLoop();
  }

  startLoop() {
    if( this._frameId === null ) {
      this._frameId = window.requestAnimationFrame( this.loop );
    }
  }

  loop() {
    if (this.dragging || this.dragEndedTimer !== null){
      this.stopLoop();
      return;
    }
    const now = Date.now();
    const target = this.positionForValue(this.props.value);

    if (this.lastAnimationTime === null)
      this.lastAnimationTime = now;

    const speed = Math.max(Math.abs(this.state.currentPosition - target), 0.05);
    const d = (now - this.lastAnimationTime) * 0.0025 * speed;
    this.lastAnimationTime = now;

    if (this.state.currentPosition < target)
      this.setState({currentPosition: Math.min(target, this.state.currentPosition + d)});
    else if (this.state.currentPosition > target)
      this.setState({currentPosition: Math.max(target, this.state.currentPosition - d)});

    // Set up next iteration of the loop
    if (this.state.currentPosition === target)
      this.stopLoop();
    else
      this._frameId = window.requestAnimationFrame( this.loop );
  }

  stopLoop() {
    if (this._frameId !== null){
      window.cancelAnimationFrame( this._frameId );
      this._frameId = null;
      this.lastAnimationTime = null;
    }
    // Note: no need to worry if the loop has already been cancelled
    // cancelAnimationFrame() won't throw an error
  }

  minValue(){
    return this.props.markerValues[0];
  }

  maxValue(){
    return this.props.markerValues[this.props.markerValues.length - 1];
  }

  positionForValue(value){
    let scale = (value - this.minValue()) / (this.maxValue()  - this.minValue());
    scale = Math.max(scale, 0);
    scale = Math.min(scale, 1);
    return scale;
  }

  handlePoints(position){
    const height = this.sliderHeight();
    const handleWidth = 30;
    const handleHeight = 40;
    const baseX = this.sliderOffset().x;
    const baseY = this.sliderOffset().y + height;
    const x1 = baseX - handleWidth * 0.5;
    const y1 = baseY - position * height - handleHeight * 0.5;
    const x2 = baseX - handleWidth * 0.5;
    const y2 = baseY - position * height + handleHeight * 0.5;
    const x3 = baseX + handleWidth * 0.5;
    const y3 = baseY - position * height + handleHeight * 0.5;
    const x4 = baseX + handleWidth * 0.5;
    const y4 = baseY - position * height - handleHeight * 0.5;
    return [Math.round(x1), Math.round(y1), Math.round(x2), Math.round(y2), Math.round(x3), Math.round(y3), Math.round(x4), Math.round(y4)];
  }

  handleLinePoints(position){
    const height = this.sliderHeight();
    const handleWidth = 30;
    const baseX = this.sliderOffset().x;
    const baseY = this.sliderOffset().y + height;
    const x1 = baseX - handleWidth * 0.5;
    const y1 = baseY - position * height;
    const x2 = baseX + handleWidth * 0.5;
    const y2 = baseY - position * height;
    return {x1: Math.round(x1), y1: Math.round(y1), x2: Math.round(x2), y2: Math.round(y2)};
  }

  markerLine(value, length){
    const y = (1 - this.positionForValue(value)) * this.sliderHeight() + this.sliderOffset().y;
    const x = this.sliderOffset().x + 13;
    const x1 = x;
    const y1 = y;
    const x2 = x + length * 20;
    const y2 = y;
    return {x1: x1, y1: y1, x2: x2, y2: y2};
  }

  markerTextPosition(value){
    const y = (1 - this.positionForValue(value)) * this.sliderHeight() + this.sliderOffset().y + 5;
    const x = this.sliderOffset().x + 38;
    return {x: x, y: y};
  }

  subValues(){
    let values = [];
    let count = this.props.markerValues[1] - this.props.markerValues[0];
    if (count < 30){
      while(count * 2 < 30) {
        count *= 2;
      }
    } else {
      while(count * 0.5 > 30) {
        count *= 0.5;
      }
    }
    const step = (this.maxValue() - this.minValue()) / count;
    for(let i = this.minValue(); i < this.maxValue(); i += step){
      values.push(i);
    }
    return values;
  }

  roundValue(v){
    if (this.props.scale === null)
      return v;
    if (this.props.scale === 0)
      return Math.round(v);
    else {
      let multip = 1;
      for(let i = 0; i < this.props.scale; i++)
        multip *= 10;
      return Math.round(v * multip) / multip;
    }
  }

  handleValue(){
    const range = this.maxValue() - this.minValue();
    return this.roundValue(this.state.currentPosition * range + this.minValue());
  }

  displayValue(){
    if (this.dragging)
      return this.handleValue();
    const value = this.props.value;
    if (value === null || isNaN(parseFloat(value)))
      return <i>&nbsp;</i>;
    return this.roundValue(value);
  }

  valueIsOffRange(){
    if (this.props.rangeMin && this.props.value < this.props.rangeMin)
      return true;
    else if (this.props.rangeMax && this.props.value > this.props.rangeMax)
      return true;
    return false;
  }

  width(){
    return this.props.size * 0.6;
  }

  height(){
    return this.props.size;
  }

  sliderHeight(){
    return this.props.size - 105;
  }

  sliderOffset(){
    return {x: 50, y: 26};
  }

  onMouseDown(event){
    this.dragging = true;
    this.startClientY = event.clientY || event.targetTouches[0].pageY;
    this.startCurrentPos = this.state.currentPosition;
    if (this.dragEndedTimer){
      clearTimeout(this.dragEndedTimer);
      this.dragEndedTimer = null;
    }
  }

  onMouseMove(event){
    if (!this.dragging)
      return;
    const y = event.clientY || event.targetTouches[0].pageY;
    let pos = this.startCurrentPos - (y - this.startClientY) / this.sliderHeight();
    pos = Math.min(Math.max(0, pos), 1);
    this.setState({currentPosition: pos});
    if (this.props.onChange)
      this.props.onChange(this.handleValue());
  }

  onMouseUp(event){
    this.dragging = false;
    this.dragEndedTimer = setTimeout(() => {
      this.dragEndedTimer = null;
      this.startLoop();
    }, 2000);
  }

  initHandle(component){
    if (this.handle || !component)
      return;
    this.handle = component;
    this.handle.addEventListener('mousedown', this.onMouseDown.bind(this));
    window.document.addEventListener('mousemove', this.onMouseMove.bind(this));
    window.document.addEventListener('mouseup', this.onMouseUp.bind(this));
    this.handle.addEventListener('touchstart', this.onMouseDown.bind(this));
    window.document.addEventListener('touchmove', this.onMouseMove.bind(this));
    window.document.addEventListener('touchend', this.onMouseUp.bind(this));
  }

  render(){
    return (
      <svg height={this.height()} width={this.width()} className="slider-component">
        <rect width={8} height={this.sliderHeight()} x={this.sliderOffset().x - 5} y={this.sliderOffset().y} fill="black" strokeWidth="1" stroke="#999"/>
        {this.props.markerValues.map(v => (
          <line key={'marker' + v} {...this.markerLine(v, 1)} stroke="#999" strokeWidth="1"/>
        ))}
        {this.subValues().map(v => (
          <line key={'subMarker' + v} {...this.markerLine(v, 0.5)} stroke="#999" strokeWidth="1"/>
        ))}
        {this.props.markerValues.map(v => (
          <text key={'label' + v} textAnchor="start" x={this.markerTextPosition(v).x} y={this.markerTextPosition(v).y} fontSize={13} fill="#666">{v}</text>
        ))}
        <rect width={this.width() - 40} height={24} x={20} y={this.height() - 54} fill="black" strokeWidth="1" stroke="#999"/>
        <text fontSize={20} fill={this.valueIsOffRange() ? "#dd0000" : "#00dd00"} style={{fontWeight: 'bold'}} textAnchor="middle" x={this.width() * 0.5} y={this.height() - 35}>
          {this.displayValue()}
        </text>
        <text textAnchor="middle" x={this.width() * 0.5} y={this.height() - 15} fill="#666" fontSize={12}>{this.props.parameter} {this.props.unit}</text>
        <polygon ref={this.initHandle.bind(this)} points={this.handlePoints(this.state.currentPosition)} fill="#999" stroke="#666" strokeWidth={2} className="handle"/>
        <line {...this.handleLinePoints(this.state.currentPosition)} stroke="#00dd00" strokeWidth="3" className="handle-line"/>
      </svg>
    )
  }
}

Slider.propTypes = {
  size: PropTypes.number,
  value: PropTypes.any,
  markerValues: PropTypes.array,
  unit: PropTypes.string,
  rangeMin: PropTypes.number,
  rangeMax: PropTypes.number,
  parameter: PropTypes.string,
  onChange: PropTypes.func,
  scale: PropTypes.number
};

export default Slider;