import React from 'react';
import ResourceForm from '../../resource_form';
import ResourceFormSelect from "../../resource_form_select";
import ResourceFormFooter from "../../resource_form_footer";
import ResourceFormHeader from "../../resource_form_header";
import ResourceFormCheckbox from "../../resource_form_checkbox";

class SequenceStepForm extends ResourceForm {
  static get apiType(){
    return 'sequence-steps';
  }

  redirectAfterCreate() {
    return `/platforms/${this.platformId()}/sequences/${this.sequenceId()}/steps`;
  }

  createRelationships() {
    const seq = {data: {type: 'sequences', id: this.sequenceId()}};
    let rel = super.createRelationships();
    rel.sequence = seq;
    return rel;
  }

  createAttributes() {
    let attr = super.createAttributes();
    let index = 1;
    if (this.sequence().included && this.sequence().included['sequence-steps']) {
      const ss = this.sequence().included['sequence-steps'];
      index = Math.max.apply(Math, ss.map((o) => parseInt(o.attributes['index-number'], 10))) + 1;
    }
    if (attr['step'])
      delete attr['step'];
    attr['index-number'] = index;
    return attr;
  }

  updateAttributes(){
    let attr = super.updateAttributes();
    if (attr['step'])
      delete attr['step'];
    return attr;
  }

  platformId(){
    return this.props.match.params.platformId;
  }

  sequenceId(){
    return this.props.match.params.sequenceId;
  }

  platform(){
    return this.resource('platforms', this.platformId());
  }

  sequence(){
    return this.resource('sequences', this.sequenceId());
  }

  componentWillMount() {
    super.componentWillMount();
    this.props.loadResources('platforms/' + this.platformId() + '?include=steps');
    this.props.loadResources('sequences/' + this.sequenceId() + '?include=sequence-steps');
  }

  steps(){
    if (!this.platform() || !this.platform().included || !this.platform().included.steps)
      return [];
    else
      return this.platform().included.steps;
  }

  render() {
    return (
      <div className="sequence-step-form-component">
        <form>
          <ResourceFormHeader resourceForm={this}/>
          <ResourceFormSelect name="step" collection={this.steps()} resourceForm={this} relationship={true}/>
          <ResourceFormCheckbox name="active" resourceForm={this}/>
          <ResourceFormFooter resourceForm={this}/>
        </form>
      </div>
    );
  }
}

export default ResourceForm.connect()(SequenceStepForm);